
<template>
  <el-aside class="aside" v-if="visible">
    <div class="header">
      <img class="logo" src="../assets/logo.png">
    </div>
    <div class="aside-menu" >
<!--      class="aside-menu-ul"-->
      <el-menu :default-active="$route.path" @select="handleSelect">
        <template :key="item.path" v-for="item in routes">
          <el-sub-menu v-if="item.children !== undefined && item.children.length > 0" :index="item.path">
            <template #title>
              <el-icon v-if="item.icon" >
                <component :is = "item.icon"></component>
              </el-icon>
              <span class="name">{{ item.name }}</span>
            </template>
            <el-menu-item-group :key="child.path" v-for="child in item.children" >
              <el-menu-item  :index="child.path">
                {{ child.name }}
              </el-menu-item>
            </el-menu-item-group>
          </el-sub-menu>
          <el-menu-item v-else v-bind:key="item.path" :index="item.path">
            <el-icon v-if="item.icon" >
              <component :is = "item.icon"></component>
            </el-icon>
            <span class="name">{{ item.name }}</span>
          </el-menu-item>
        </template>
      </el-menu>
    </div>
  </el-aside>
</template>



<script>

import Routers from "../router/"
export default {
  name: "aside-menu",


  data: function () {
    return {
      timer: false,
      height: "0px",
      visible: true,
      routes: [
        {
          name: "首页",
          path: "/dashboard",
          icon: "House",
        },
        {
          name: "媒体管理",
          icon: "monitor",
          path: "/dashboard/media",
          children: [
            {
              name: "应用管理",
              path: "/dashboard/media/app",
            }
          ]
        },
        {
          name: "财务管理",
          icon: "GoldMedal",
          path: "/dashboard/financial",
          children: [
            {
              name: "平台收益报表",
              path: "/dashboard/financial/platform",
            },
            {
              name:"用户收益报表",
              path: "/dashboard/financial/user",
            },
            {
              name: "用户财务账户",
              path: "/dashboard/financial/blank"
            }
          ]
        },
        {
          name: "结算管理",
          icon: "money",
          path: "/dashboard/settlement",
          children: [
            {
              name: "当月结算",
              path: "/dashboard/settlement/current",
            },
            {
              name: "上游核算历史",
              path: "/dashboard/settlement/platform/history"
            },
            {
              name: "下游结算历史",
              path: "/dashboard/settlement/company/history"
            },
            {
              name: "用户结算申请",
              path: "/dashboard/settlement/query",
            },
          ]
        },
        {
          name: "用户管理",
          icon: "user",
          path: "/dashboard/user",
          children: [
            {
              name: "用户列表",
              path: "/dashboard/user/manage"
            },
            {
              name: "用户注册",
              path: "/dashboard/user/signup"
            },
            {
              name: "联系我们",
              path: "/dashboard/user/contact"
            }
          ]
        },
        {
          name: "发布管理",
          icon: "document",
          path: "/dashboard/docs",
          children: [
            {
              name: "接入文档",
              path: "/dashboard/docs/document",
            },
            {
              name: "文件管理",
              path: "/dashboard/docs/sdk"
            }
          ]
        },
        {
          name: "系统设置",
          icon: "setting",
          path: "/dashboard/preference/config"
        }
      ],
    }
  },

  mounted() {
    // 处理菜单展开指示 图标颜色无法修改的问题
    const elements  = document.querySelectorAll(".el-submenu__icon-arrow")
    for (let element of  elements) {
      element.style.color = "#333"
      element.style.fontSize = "14px"
    }

    this.height =  this.navHeight()
    window.addEventListener("resize", this.resize);


    this.$emit("select", this.$route.path, this.matchPath(this.$route.path))
  },

  methods: {
    handleSelect: function (index) {
      Routers.push(index)
      this.$emit("select", index, this.matchPath(index))
    },


    matchPath: function (index) {
      let names = [];
      for(const item of this.routes) {
        if (item.children !== undefined) {
          names.push(item.name)
          for (const child of item.children) {
            if (child.path === index) {
              names.push(child.name);
              return names;
            }
          }
          names = [];
        } else if (item.path === index) {
          names.push(item.name)
          return names
        }
      }
    },

    navHeight: function () {
      const page = document.querySelector(".page")
      return page.clientHeight - 82;
    },

    resize: function () {
      this.height = this.navHeight();
    },

    watch: {
      height: function (val) {
        if (!this.timer) {
          this.height = val;
          this.timer = true;
          let that = this;
          setTimeout(function () {
            that.timer = false
          }, 400)
        }
      }
    }
  }
}
</script>

<style scoped>

        .aside {
            max-height: 100%;
            min-height: 100%;
            width: 260px !important;
            border-right: solid 1px #e6e6e6;
            position: relative;
        }

        .aside > .header {
            display: flex;
            justify-content: center;
            height: 82px;
            border-bottom: #ebeef5 2px solid;
        }

        .aside > .header > .logo {
            width: 200px;
            margin: 10px auto;
        }

        .aside .aside-menu {
            border: 0;
            //overflow-y: overlay;
            overflow-x: hidden;
        }

        .aside > .aside-menu-ul {
        }

        .aside > .aside-menu .aside-menu-ul .el-menu-item {
            font-size: 14px !important;
            margin: 10px 20px;
            color: #333 !important;
            display: flex !important;
            align-items: center !important;
        }

        .aside > .aside-menu  .aside-menu-ul  .el-menu-item.is-active{
            background-color: #ffcece !important;
        }

        .aside > .aside-menu .aside-menu-ul .el-menu-item > i{
            margin-right: 10px !important;
            color: #333 !important;
        }

        .aside > .aside-menu .aside-menu-ul > .el-submenu {
            margin: 10px 20px !important;
        }

        .aside > .aside-menu-ul > .el-submenu > .el-submenu__title > span.name {
            font-size: 14px !important;
            color: #333 !important;
        }

        .aside > .aside-menu .aside-menu-ul > .el-submenu > .el-submenu__title > i {
            color: #333 !important;
            margin-right: 10px !important;
        }

        .aside > .aside-menu .aside-menu-ul > .el-submenu > .el-submenu__title:last-child::before {
            color: #333 !important;
            margin-right: 20px !important;
        }

        /* 自定义子级菜单 */
        .aside > .aside-menu ul > li.el-submenu .el-menu-item {
            margin: 0 0 4px 0;
            padding: 5px 40px !important;
            font-size: 14px !important;
        }

        .aside > .aside-menu ul > li.el-submenu.is-active.is-opened .el-menu-item.is-active {
            background-color: #ffcece !important;
        }

        .aside > .aside-menu >>> .el-menu-item-group__title {
            padding: 2px;
        }

        </style>