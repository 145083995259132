<template>
  <el-dialog title="编辑广告位" v-model="dialogVisible" width="600px" :close-on-click-modal="false" @closed="clear" center>
    <el-form label-width="80px" label-position="left">

      <el-form-item label="名称">
        <el-input v-model="name" placeholder="请输入名称，可选" size="small"/>
      </el-form-item>

      <el-form-item label="广告位ID">
        <el-input v-model="placementId" placeholder="请输入广告位ID" size="small"/>
      </el-form-item>

      <el-form-item label="场景">
        <el-select v-model="scene" size="small" style="width: 200px">
          <el-option :value="1" label="开屏"/>
          <el-option :value="2" label="信息流"/>
          <el-option :value="3" label="激励视频"/>
          <el-option :value="4" label="横幅"/>
          <el-option :value="5" label="插屏"/>
        </el-select>
      </el-form-item>

      <el-form-item label="应用方向">
        <el-select v-model="orientation" size="small" style="width: 200px">
          <el-option :value="0" label="不明确"/>
          <el-option :value="1" label="竖屏"/>
          <el-option :value="2" label="横屏"/>
        </el-select>
      </el-form-item>

      <el-form-item label="EPCM">
        <el-input-number v-model="ecpm" controls-position="right" :min="0" :max="1000000"
                         size="small"></el-input-number>&nbsp;人民币
      </el-form-item>

    </el-form>
    <el-divider />
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleCancel">取 消</el-button>
        <el-button type="primary" @click="handleOk">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {ref} from 'vue'

export default {
  // name: "NetPosEditDialog",
  setup() {

    let dialogVisible = ref(false)

    return {
      dialogVisible
    }
  },
  data: function () {
    return {
      index: -1,
      name: "",
      placementId: "",
      scene: 1,
      orientation: 0,
      ecpm: 0,
    }
  },

  methods: {
    show: function (index,record) {
      this.index = index;
      this.dialogVisible = true;
      this.placementId = record.placementId;
      this.name = record.name;
      this.scene = record.scene;
      this.orientation = record.orientation;
      this.ecpm = record.ecpm/100;
    },

    clear: function () {
      this.placementId = "";
      this.name = "";
      this.scene = 1;
      this.orientation = 0;
      this.ecpm = 0;
    },

    handleOk: function () {
      const {placementId, name, orientation, scene, ecpm} = this;
      const record = {placementId: placementId, name: name, orientation: orientation, scene: scene, ecpm: ecpm * 100};
      this.$emit("ok", this.index,record);
      this.dialogVisible = false;
    },

    handleCancel: function () {
      this.dialogVisible = false;
      this.$emit("cancel")
    }
  }
}
</script>

<style scoped>

</style>