<template>
    <div>
        <el-card class="card filter">
            <el-form class="row" inline>
                <el-form-item>
                    <el-select v-model="filter.unit" size="small" style="width: 150px">
                        <el-option :value="1" label="按天"/>
                        <el-option :value="7" label="按周"/>
                        <el-option :value="30" label="按月"/>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-date-picker
                        v-model="filter.dateRange"
                        size="small"
                        type="daterange"
                        align="right"
                        unlink-panels
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <el-form class="row" inline>
                <el-form-item>
                    <el-input prefix-icon="el-icon-search" placeholder="输入用户名称/ID" v-model="filter.search" size="small"/>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="update" size="small">搜索</el-button>
                </el-form-item>
            </el-form>
        </el-card>

        <el-card class="card">
            <div>
                数据明细
                <span class="small">(涉及金额单位¥)</span>
            </div>
            <el-divider />

            <div class="category">
                <span>收益纬度&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <el-checkbox-group class="checkbox-group" v-model="filter.scenes">
                    <el-checkbox :label="3">激励视频</el-checkbox>
                    <el-checkbox :label="4">横幅</el-checkbox>
                    <el-checkbox :label="2">信息流</el-checkbox>
                    <el-checkbox :label="1">开屏</el-checkbox>
                    <el-checkbox :label="5">插屏</el-checkbox>
                </el-checkbox-group>
            </div>
            <el-table :data="data">
                <el-table-column prop="date" label="日期" align="center" width="120">
                    <template v-slot:default="scope">
                        <template v-if="scope.row.start_date === scope.row.end_date">
                            <span>{{unixFormatter(scope.row.start_date, 'YYYY-MM-DD')}}</span>
                        </template>
                        <template v-else>
                            <span>
                                {{unixFormatter(scope.row.start_date, 'YYYY-MM-DD')}} <br/>
                                {{unixFormatter(scope.row.end_date, 'YYYY-MM-DD')}}
                            </span>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="账户" align="center" width="160"/>
                <el-table-column prop="revenue" label="预估收益(总)" align="center">
                    <template v-slot:default="scope">
                        <span v-if="scope.row.revenue > 0" style="color: #f56c6c">{{(scope.row.revenue / 100).toFixed(2)}}</span>
                        <span v-else>{{(scope.row.revenue / 100).toFixed(2)}}</span>
                    </template>
                </el-table-column>

                <el-table-column prop="reward_revenue" label="收益(激励)" align="center" v-if="filterColumn(3)">
                    <template v-slot:default="scope">
                        <span>{{(scope.row.reward_revenue / 100).toFixed(2)}}</span>
                    </template>
                </el-table-column>

                <el-table-column prop="banner_revenue" label="收益(横幅)" align="center" v-if="filterColumn(4)">
                    <template v-slot:default="scope">
                        <span>{{(scope.row.banner_revenue / 100).toFixed(2)}}</span>
                    </template>
                </el-table-column>

                <el-table-column prop="native_revenue" label="收益(信息流)" align="center" v-if="filterColumn(2)">
                    <template v-slot:default="scope">
                        <span>{{(scope.row.native_revenue / 100).toFixed(2)}}</span>
                    </template>
                </el-table-column>

                <el-table-column prop="interstitial_revenue" label="收益(插屏)" align="center" v-if="filterColumn(2)">
                    <template v-slot:default="scope">
                        <span>{{(scope.row.interstitial_revenue / 100).toFixed(2)}}</span>
                    </template>
                </el-table-column>

                <el-table-column prop="splash_revenue" label="收益(开屏)" align="center" v-if="filterColumn( 1)">
                    <template v-slot:default="scope">
                        <span>{{(scope.row.splash_revenue / 100).toFixed(2)}}</span>
                    </template>
                </el-table-column>

                <el-table-column prop="app_count" label="应用" align="center"/>

                <el-table-column prop="placement_count" label="广告位" align="center"/>

                <el-table-column label="操作" width="120px" align="center">
                    <template v-slot:default="scope">
                        <el-button type="text" @click="handleClickData(scope.row.user_id, scope.row.name,
                        scope.row.start_date, scope.row.end_date)">数据</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="footer">
                <el-pagination
                    class="pagination"
                    background
                    layout="prev, pager, next"
                    @current-change="handleChangeCurrentPage"
                    :page-size="pageSize"
                    :total="pageCount">
                </el-pagination>
            </div>
        </el-card>
    </div>
</template>

<script>
import * as api from "../../api/api";
import {unixFormatter} from "../../util/format";
import moment from "moment";

export default {
    name: "earnings-user",

    mounted() {
        this.initView();
    },

    data: function (){
        return {
            filter: {
                unit: 1,
                search: "",
                dateRange: [new Date(), new Date()],
                scenes: [1, 2, 3, 4],
            },
            data:[],
            currentPage: 1,
            pageSize: 10,
            pageCount: 0,
        }
    },

    methods: {
        unixFormatter,

        initView: function () {
            const now = new Date();
            const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
            const start = moment(end).clone().subtract(1, "day").toDate()
            this.filter.dateRange = [start, end]

            this.update();
        },

        update: function () {
            const {currentPage, pageSize, filter} = this;
            const {search, unit, dateRange} = filter;
            const start = parseInt(dateRange[0].getTime() / 1000);
            const end = parseInt(dateRange[1].getTime() / 1000);

            api.getEarningsUserReport(currentPage, pageSize, search, unit, start, end).then(result => {
                if (parseInt(result.code) !== 200) {
                    this.$message.error("请求失败， Err: " + result.msg)
                    return
                }

                this.data = result.data.list;
                this.pageCount = result.data.count;
                this.currentPage = 1;
            }, err => {
                this.$message.error("请求失败， Err: " + err);
            })
        },

        handleClickData: function (userId, username, dateStart, dateEnd) {
            Routers.push({
                path: "/dashboard/financial/user/pri",
                query: {
                    user_id: userId,
                    username: username,
                    date_start: dateStart,
                    date_end: dateEnd,
                },
            })
        },

        handleChangeCurrentPage: function (page) {
            this.currentPage = page
            this.update()
        },

        filterColumn: function (column) {
            for (let item of this.filter.scenes) {
                if (item === column) {
                    return true;
                }
            }
            return false;
        }
    }
}
</script>

<style scoped>

.card:not(:first-child) {
    margin-top: 20px;
}

.card.filter .row:nth-child(2) .el-form-item {
    margin-bottom: 0 !important;
}

.card .category {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    font-size: 14px;
    color: #606266;
    font-weight: 500;
}

.footer {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}


</style>