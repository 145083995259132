import { createApp } from 'vue';
import App from './App.vue';
import router from './router/';
import 'element-plus/dist/index.css';
import ElementPlus from "element-plus";
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import * as Icons from '@element-plus/icons-vue';

// 定义特性标志
window.__VUE_PROD_DEVTOOLS__ = false;
window.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = false;
const app = createApp(App);
Object.keys(Icons).forEach(key => {
  app.component(key, Icons[key]);
});
app.use(ElementPlus, {
  locale: zhCn
});
app.use(router);
//app.use(ElementUI)
app.mount('#app');