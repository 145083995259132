<template>
    <div class="breadcrumb" v-if="visible">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item v-for="(item, i) in routes" :key="i" >{{item}}</el-breadcrumb-item>
        </el-breadcrumb>

        <div class="user">
            <el-avatar class="avatar" src="favicon.ico"/>

            <el-dropdown  @command="handleCommand">

               <span class="el-dropdown-link">{{username}}
                    <el-icon class="el-icon--right">
                        <arrow-down />
                    </el-icon>
                  </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item class="logout" command="logout">退 出</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
        </div>
    </div>
</template>

<script>

import * as api from "../api/request"
import Routers from "../router/"

export default {
  // eslint-disable-next-line vue/multi-word-component-names
    name: "breadcrumb",

    props: {
        routes: {
            type: Array,
            required: true,
        }
    },

    data: function () {
        return {
            username:"",
            visible: true,
        }
    },
    created() {
        this.initView()

        this.visible = this.$route.query["breadcrumb"] !== "hidden";
    },

    methods: {
        initView: function () {
            this.username = api.getAuthorize(api.USERNAME_KEY)
        },

        handleCommand: function (command) {
            switch (command) {
                case "logout": {
                    this.$confirm('确定退出登陆？', "提示", {center: true}).then(() => {
                        api.authorizeClear()
                        Routers.push("/login")
                    }).catch(() => {});
                    break;
                }
            }
        },
    },

    watch: {
        $route: function (to) {
            // 处理隐藏菜单栏
            this.visible = to.query["breadcrumb"] !== "hidden";
        }
    }
}
</script>

<style scoped>

.breadcrumb {
    background-color: #FFFFFF;
    min-height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    border-bottom: 1px solid #ebeef5;
    padding: 0 30px 0 30px;
}

.breadcrumb >>> .el-breadcrumb {
    font-size:  16px;
}

.breadcrumb >>> .el-breadcrumb__item .el-breadcrumb__inner {
    font-weight: 400 !important;
}

.breadcrumb > .user {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.breadcrumb > .user > .avatar {
    margin-right:  16px;
    width: 36px;
    height: 36px;
}

.breadcrumb > .user >>> .logout {
    color: #F56C6C;
}

</style>