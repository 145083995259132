<template>
    <div>
        <template v-if="isEnd">
            <el-empty>
                <span style="color: #909399"><slot name="description">
                    已结束 <br> 每个月第10个工作日开放结算
                </slot></span>
            </el-empty>
        </template>
        <template v-else-if="!isStart">
            <el-empty>
                <span style="color: #909399"><slot name="description">
                    未开放 <br> 每个月第10个工作日开放结算
                </slot></span>
            </el-empty>
        </template>
        <template v-else>
            <div class="progress">
                <div class="date">
                    <span class="year">{{year}}&nbsp;年</span>
                    <span class="month">{{month}}</span>
                </div>
                <div :class="['part', '_1', {'selected': selected === 1}]" >
                    <span class="idx">1</span>
                    <section class="section">
                        <p class="header">【 上游收益核算 】</p>
                        <p class="content">收益核算是针对渠道方进行的金额核算，确保平台收益<br/>以及渠道方收益能对应上。</p>
                    </section>
                </div>
                <div :class="['part', '_2', {'selected': selected === 2}]">
                    <span class="idx">2</span>
                    <section class="section">
                        <p class="header">【 下游收益结算 】</p>
                        <p class="content">平台收益核算完成后才会生成对应的账户收益结算单。<br/>财务人员需对账户收益进行结算审核。确保无误后，<br/>在规定日期后可生成账户结算单下发账户。</p>
                    </section>
                </div>
            </div>
            <div>
                <part1 v-if="this.selected === 1" @complete="handleComplete" :date="date"/>
                <part2 v-else-if="this.selected === 2" @complete="handleComplete" :date="date"/>
            </div>
        </template>
    </div>
</template>

<script>
import moment from 'moment'
import * as api from "../../api/api"
import Part1 from "./CenterPart1.vue";
import Part2 from "./CenterPart2.vue";
import {CODE_OK} from "../../api/request";
export default {
    name: "CenterController",
    components: {Part1, Part2},
    data: function () {
        return {
            id: 0,
            date: 0,
            year: 0,
            month: 0,
            isStart: false,
            isEnd: false,
            selected: 1,
        }
    },

    mounted() {
        this.initView();
    },

    methods: {
        initView: function () {
            this.update();
        },

        update: function () {
            api.getSettlementConfig().then(result => {
                if (parseInt(result.code) !== CODE_OK) {
                    this.$message.error("获取结算配置失败， Err: " + result.msg)
                    return
                }
                this.id = result.data.id;
                this.date = result.data.date * 1000;
                const mdate = moment(this.date);
                this.year = mdate.year();
                this.month = mdate.month() + 1;
                this.isStart = result.data.is_start;
                this.isEnd = result.data.platform_check && result.data.user_check;
                this.selected = result.data.platform_check && !result.data.user_check ? 2 : this.selected;
            }, err => {
                this.$message.error("获取结算配置失败， Err: " + err)
            })
        },

        clear: function () {
            this.id = 0;
            this.date = 0;
            this.year = 0;
            this.month = 0,
            this.isStart = false;
            this.isEnd = false;
            this.selected = 1;
        },

        handleComplete: function (progress) {
            api.goNextSettlementCheck(this.id, progress).then(result => {
                if (parseInt(result.code) !== CODE_OK) {
                    this.$message.error("更新结算进度失败， Err: " + result.msg);
                    return
                }
                this.isEnd = result.data.platform_check && result.data.user_check;
                this.selected = result.data.platform_check && !result.data.user_check ? 2 : this.selected;

                if (this.isEnd) {
                    this.clear();
                    this.update();
                }
            }, err => {
                this.$message.error("更新结算进度失败， Err: " + err);
            })
        }
    }
}
</script>

<style scoped>

.progress {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.progress .date {
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 160px;
    height: 158px;
    background-color: #f56c6c;
    border-top: 1px solid #ebeef5;
    border-bottom: 1px solid #ebeef5;
    border-left: 1px solid #ebeef5;
}

.progress .date .month {
    font-size: 80px;
    font-weight: 400;
    color: #FFFFFF;
}

.progress .date .year {
    color: #f5f7fa;
    font-size:  16px;
    font-weight: 400;
}

.progress .part {
    height: 160px;
    flex-grow: 1;
    background-image: url("../../assets/ic_step_arrow.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #d5d5d5;
}

.progress .part._1 {
    margin-right: -7%;
    z-index: 100;
    width: 45%;
}

.progress .part._2 {
    width: 55%;
}

.progress .part.selected {
    background-image: url("../../assets/ic_step_arrow_selected.svg");
    color: #FFFFFF;
}

.progress .part .idx {
    font-weight: 400;
    font-style: normal;
    font-size: 36px;
    text-align: center;
}

.progress .part .section {
    margin-left: 20px;
}

.progress .part .section .header {
    font-size: 16px;
    font-weight: 400;
}

.progress .part .section .table {
    font-size: 13px;
    font-weight: 400;
}

</style>