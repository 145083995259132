<template>
    <div>
        <el-card class="card header">
            <el-page-header title="返回" @back="handleGoBack" :content="username"/>
        </el-card>

        <el-card class="card content">
            <el-table :data="data">
                <el-table-column label="结算时间" align="center">
                    <template v-slot:default="scope">
                        <span>{{unixFormatter(scope.row.date, 'YYYY-MM')}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="app_id" label="应用ID" align="center"></el-table-column>
                <el-table-column prop="app_name" label="应用名" align="center"></el-table-column>
                <el-table-column prop="est_revenue" label="预估收益" align="center"></el-table-column>
                <el-table-column prop="kd_est_revenue"  label="快手" align="center"></el-table-column>
                <el-table-column prop="tx_est_revenue"  label="优量汇" align="center"></el-table-column>
                <el-table-column prop="gm_est_revenue"  label="Gromore" align="center"></el-table-column>
                <el-table-column label="操作" align="center">
                    <template v-slot:default="scope">
                        <el-button type="text" @click="handleClickDetail(scope.row.id, scope.row.app_name, scope.row.date)">明细</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="footer">
                <el-pagination
                    class="pagination"
                    background
                    layout="prev, pager, next"
                    @current-change="update"
                    v-model:current-page="currentPage"
                    :page-size="pageSize"
                    :total="pageCount">
                </el-pagination>
            </div>
        </el-card>
    </div>
</template>

<script>

import * as api from "../../api/api";
import {CODE_OK} from "../../api/request";
import {unixFormatter} from "../../util/format";
import Routers from "../../router/"

export default {
    name: "AppFormHistory",

    data: function () {
        return {
            id: 0,
            username: "",
            filter: {},
            data: [],
            pageSize: 8,
            pageCount: 0,
            currentPage: 1,
        };
    },

    mounted() {
        this.initView();
    },

    methods: {
        unixFormatter,

        initView: function () {

            const {id, username} = this.$route.query
            if (id === undefined || id === "") {
                this.$message.error("缺少参数 userFormId");
                Routers.back();
                return
            }

            this.username = username;
            this.id = parseInt(id);

            this.update();
        },

        update: function () {
            const {currentPage, pageSize, id} = this;
            api.getSettlementAppReport(currentPage, pageSize, id, 0, 0, 0, 2).then(result => {
                if (parseInt(result.code) !== CODE_OK) {
                    this.$message.error("获取上游核算列表失败， Err: " + result.msg)
                    return
                }
                this.data = result.data.list.map((item)=> {
                    item.est_revenue = (item.est_revenue/100).toFixed(2);
                    item.bd_est_revenue = (item.bd_est_revenue/100).toFixed(2);
                    item.tx_est_revenue = (item.tx_est_revenue/100).toFixed(2);
                    item.kd_est_revenue = (item.kd_est_revenue/100).toFixed(2);
                    item.gm_est_revenue = (item.gm_est_revenue/100).toFixed(2);
                    return item;
                })
                this.pageCount = result.data.count;
            }, err => {
                this.$message.error("获取应用结算列表失败, Err: " + err)
            })
        },

        handleGoBack: function () {
            Routers.back();
        },

        handleClickDetail: function (id, name, date) {
            Routers.push({
                path: "/dashboard/settlement/user/app/d/history",
                query: {
                    id: id,
                    name: name,
                    date: date,
                }
            })
        }
    }

}
</script>

<style scoped>

.card:not(:first-child) {
    margin-top: 20px;
}

.card .footer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

</style>