<template>
    <div class="page">
        <el-card class="card summary">
            <div><slot name="header">
                <el-page-header title="返回" @back="handleGoBack" :content="username"/>
            </slot>
            </div>

            <div class="form">
                <div class="form-item">
                    <div class="name">昨日收益（元）</div>
                    <div class="revenue">{{(yesterRevenue / 100).toFixed(2)}}</div>
                </div>
                <el-divider class="divider" direction="vertical"/>
                <div class="form-item">
                    <div class="name">近七日收益（元）</div>
                    <div class="revenue">{{(weekRevenue / 100).toFixed(2)}}</div>
                </div>
                <el-divider class="divider" direction="vertical"/>
                <div class="form-item">
                    <div class="name">本月收益（元）</div>
                    <div class="revenue">{{(monthRevenue/100).toFixed(2)}}</div>
                </div>
                <el-divider class="divider" direction="vertical"/>
                <div class="form-item">
                    <div class="name">上月收益（元）</div>
                    <div class="revenue">{{(preMonthRevenue/100).toFixed(2)}}</div>
                </div>
            </div>
        </el-card>

        <el-card class="card filter">
            <el-form inline>
                <el-form-item>
                    <el-select v-model="filter.unit" size="mini" style="width: 150px">
                        <el-option :value="1" label="按天"/>
                        <el-option :value="7" label="按周"/>
                        <el-option :value="30" label="按月"/>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-date-picker
                        size="mini"
                        v-model="filter.dateRange"
                        type="daterange"
                        align="right"
                        unlink-panels
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-checkbox v-model="filter.isCompared" @change="handleChangeCompared">对比</el-checkbox>
                </el-form-item>
                <el-form-item v-if="filter.isCompared">
                    <el-date-picker
                        v-model="filter.comparedDateRange"
                        size="mini"
                        type="daterange"
                        align="right"
                        unlink-panels
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <el-form class="form-foot" inline>
                <el-form-item>
                    <el-select v-model="filter.appId" size="mini" style="width: 150px">
                        <el-option :value="0" label="全部应用"/>
                        <template :key="item.app_id" v-for="item in app">
                            <el-option :value="item.app_id" :label="item.app_name"/>
                        </template>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-select v-model="filter.channel" size="mini" style="width: 150px">
                        <el-option :value="0" label="全部渠道"/>
                        <el-option :value="1" label="优量汇"/>
                        <el-option :value="4" label="快手"/>
                        <el-option :value="5" label="Gromore"/>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-select v-model="filter.scene" size="mini" style="width: 150px">
                        <el-option :value="0" label="全部广告场景"/>
                        <el-option :value="1" label="开屏"/>
                        <el-option :value="2" label="信息流"/>
                        <el-option :value="3" label="激励视频"/>
                        <el-option :value="4" label="横幅"/>
                        <el-option :value="5" label="插屏"/>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="update" @keyup.enter="update" size="mini" round>查找</el-button>
                </el-form-item>
            </el-form>
        </el-card>

        <el-card class="card graph">
          <div><slot name="header">图表数据</slot></div>
            <div class="filter">
                <el-form inline>
                    <el-form-item label="指标">
                        <el-select v-model="graph.kpi" size="mini" @change="drawGraph">
                            <template :key="item.value" v-for="item in graph.kpiItems">
                                <el-option :value="item.value" :label="item.name"/>
                            </template>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-checkbox v-model="graph.isComparedKpi" @change="drawGraph" :disabled="graph.disableComparedKpi">对比</el-checkbox>
                    </el-form-item>
                    <el-form-item v-if="graph.isComparedKpi">
                        <el-select v-model="graph.comparedKpi" size="mini" @change="drawGraph">
                            <template :key="item.value" v-for="item in graph.kpiItems">
                                <el-option :value="item.value" :label="item.name"/>
                            </template>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div id="graph-container" :style="{width: 'auto', height: '500px'}"/>
        </el-card>

        <el-card class="card table">
            <div class="header">数据表格
                <span class="small">(涉及金额单位¥)</span>
            </div>

            <div class="filter">
                <span>数据纬度: &nbsp;</span>
                <el-checkbox-group class="checkbox-group" v-model="table.filter.dataFields">
                    <el-checkbox v-for="field in table.filter.dataFieldOptions" :key="field.value" :label="field.value">{{field.name}}</el-checkbox>
                </el-checkbox-group>
            </div>

            <div class="filter">
                <span>指标纬度: &nbsp;</span>
                <el-checkbox-group v-model="table.filter.kpiFields" @change="handleChangeKpiFiled">
                    <el-checkbox v-for="field in table.filter.kpiFieldOptions" :key="field.value" :label="field.value">{{field.name}}</el-checkbox>
                </el-checkbox-group>
            </div>

            <div class="content">
                <el-table :data="table.items.slice((currentPage - 1) * pageSize, (currentPage - 1) * pageSize + pageSize)"
                          show-summary
                          :summary-method="getTableSummary">
                    <el-table-column fixed align="center" width="120" label="日期" v-if="inKpiField('date')">
                        <template v-slot:default="scope">
                            <template v-if="scope.row.start_date === scope.row.end_date">
                                <span>{{unixFormatter(scope.row.start_date, 'YYYY-MM-DD')}}</span>
                            </template>
                            <template v-else>
                                <span>{{unixFormatter(scope.row.start_date, 'YYYY-MM-DD')}}</span>
                                <br/>
                                <span>{{unixFormatter(scope.row.end_date, 'YYYY-MM-DD')}}</span>
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column fixed width="150" align="center" label="应用名称/ID" v-if="inKpiField('app')">
                        <template v-slot:default="scope">
                            <div>
                                <span>{{scope.row.app_name}}</span>
                                <span style="font-size: 12px;">({{scope.row.app_id}})</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column fixed prop="scene" align="center" width="150" label="广告位类型" v-if="inKpiField('scene')">
                        <template v-slot:default="scope">
                            <tag v-if="scope.row.scene === 1" effect="plain">开屏</tag>
                            <tag v-if="scope.row.scene === 2" effect="plain">信息流</tag>
                            <tag v-if="scope.row.scene === 3" effect="plain">激励视频</tag>
                            <tag v-if="scope.row.scene === 4" effect="plain">横幅</tag>
                            <tag v-if="scope.row.scene === 5" effect="plain">插屏</tag>
                        </template>
                    </el-table-column>
                    <el-table-column fixed prop="channel" align="center" width="150" label="渠道" v-if="inKpiField('channel')">
                        <template  v-slot:default="scope">
                            <tag v-if="scope.row.channel === 1" effect="plain">优量汇</tag>
                            <tag v-else-if="scope.row.channel === 2" effect="plain">穿山甲</tag>
                            <tag v-else-if="scope.row.channel === 4" effect="plain">快手</tag>
                            <tag v-else-if="scope.row.channel === 5" effect="plain">Gromore</tag>
                            <tag v-else effect="plain">未知</tag>
                        </template>
                    </el-table-column>
                    <el-table-column fixed prop="revenue" width="100" align="center" label="预估收益" v-if="inKpiField('revenue')">
                        <template v-slot:default="scope">
                            <span>{{(scope.row.revenue/100).toFixed(2)}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="pv_count" align="center" width="100" label="展示量" v-if="inDataField('pv_count')"/>
                    <el-table-column align="center" width="100" label="曝光率" v-if="inDataField('pv_rate')">
                        <template v-slot:default="scope">
                            <template v-if="scope.row.return_count > 0">
                                {{(scope.row.pv_count/scope.row.return_count*100).toFixed(2)}}%
                            </template>
                            <template v-else>-</template>
                        </template>
                    </el-table-column>
                    <el-table-column prop="click_count" align="center" width="100" label="点击量" v-if="inDataField('click_count')"/>
                    <el-table-column prop="click_rate" align="center" width="100" label="点击率" v-if="inDataField('click_rate')">
                        <template v-slot:default="scope">
                            <template v-if="scope.row.pv_count > 0">
                                {{(scope.row.click_count/scope.row.pv_count*100).toFixed(2)}}%
                            </template>
                            <template v-else>
                                -
                            </template>
                        </template>
                    </el-table-column>

                    <el-table-column prop="flow_request_count" align="center" width="120" label="请求量(瀑布流)" v-if="inDataField('flow_request_count')"/>
                    <el-table-column prop="flow_return_count" align="center" width="120" label="返回量(瀑布流)" v-if="inDataField('flow_return_count')"/>

                    <el-table-column prop="request_count" align="center" width="100" label="请求量" v-if="inDataField('request_count')"/>
                    <el-table-column prop="return_count" align="center" width="100" label="返回量" v-if="inDataField('return_count')"/>
                    <el-table-column prop="return_rate" align="center" width="100" label="填充率" v-if="inDataField('return_rate')">
                        <template v-slot:default="scope">
                            <template v-if="scope.row.request_count > 0">
                                {{(scope.row.return_count/scope.row.request_count*100).toFixed(2)}}%
                            </template>
                            <template v-else>
                                -
                            </template>
                        </template>
                    </el-table-column>
                </el-table>

                <div class="footer">
                    <el-pagination
                        class="pagination"
                        background
                        layout="prev, pager, next"
                        v-model:current-page="currentPage"
                        :page-size="pageSize"
                        :total="pageCount">
                    </el-pagination>
                </div>
            </div>
        </el-card>
    </div>
</template>

<script>
import moment from "moment";
import * as echarts from "echarts";
import * as api from "../../api/api";
import {unixFormatter} from "../../util/format";
import Tag from "../../component/Tag.vue";

export default {
    name: "UserEarning",
    components: {Tag},
    data: function () {
        return {
            userId: 0,
            username: "",
            filter: {
                dateRange: [new Date(), new Date()],
                comparedDateRange: [new Date(), new Date()],
                unit: 1,
                isCompared: false,
                channel: 0,
                scene: 0,
                appId: 0,
            },
            yesterRevenue: 0,
            weekRevenue: 0,
            monthRevenue: 0,
            preMonthRevenue: 0,

            app:[],

            graph: {
                disableComparedKpi: false,
                isComparedKpi: false,
                kpi: "revenue",
                comparedKpi: "return_count",
                items: [],
                comparedItems: [],
                kpiItems: [
                    {value: "revenue", name: "预估收益"},
                    {value: "flow_request_count", name: "请求量(瀑布流)"},
                    {value: "flow_return_count", name: "返回量(瀑布流)"},
                    {value: "request_count", name: "请求量"},
                    {value: "return_count", name: "返回量"},
                    {value: "return_rate", name: "填充率"},
                    {value: "ecpm", name: "ECPM"},
                    {value: "pv_count", name: "曝光量"},
                    {value: "pv_rate", name: "曝光率"},
                    {value: "click_count", name: "点击量"},
                    {value: "click_rate", name: "点击率"}
                ]
            },

            table: {
                filter: {
                    dataFields: ["pv_count", "pv_rate", "click_count", "click_rate", "flow_request_count", "flow_return_count",
                        "request_count", "return_count", "return_rate"],
                    dataFieldOptions:[
                        {value: "pv_count", name: "曝光量"},
                        {value: "pv_rate", name: "曝光率"},
                        {value: "click_count", name: "点击量"},
                        {value: "click_rate", name: "点击率"},
                        {value: "flow_request_count", name: "请求量(瀑布流)"},
                        {value: "flow_return_count", name: "返回量(瀑布流)"},
                        {value: "request_count", name: "请求量"},
                        {value: "return_count", name: "返回量"},
                        {value: "return_rate", name: "填充率"}],

                    kpiFields: ["date", "app", "scene", "channel", "revenue"],

                    kpiFieldOptions: [
                        {value: "date", name: "日期"},
                        {value: "app", name: "应用"},
                        {value: "scene", name:"广告位类型"},
                        {value: "channel", name:"渠道"},
                        {value: "revenue", name: "预估收益"}
                    ],
                },
                origin:[],
                items: [],

                pvCount: 0,
                flowRequestCount: 0,
                flowReturnCount: 0,
                requestCount: 0,
                returnCount: 0,
                clickCount: 0,
                revenue: 0,
            },
            pageSize: 8,
            pageCount: 0,
            currentPage: 1,
        }
    },

    mounted() {
        this.initView();
    },

    methods: {
        unixFormatter,

        initView: function () {
            const {user_id: userId, username, date_start: dateStart, date_end: dateEnd} = this.$route.query
            if (userId === undefined) {
                this.$message.error("用户信息不存在")
                Routers.back()
                return;
            }

            this.userId = userId;
            this.username = username;

            let start, end;

            if (dateStart !== undefined && dateEnd !== undefined) {
                this.filter.dateRange = [new Date(parseInt(dateStart) * 1000), new Date(parseInt(dateEnd) * 1000)]
            } else  {
                const now = new Date();
                const nowUint = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
                end = moment(nowUint).clone().subtract(1, "day").toDate()
                start = moment(nowUint).clone().subtract(8, "day").toDate()
                this.filter.dateRange = [start, end]
            }


            const diffDays = moment(end).diff(moment(start), "days");

            const comparedEnd = moment(start).clone().subtract(1, "day").toDate();
            const comparedStart = moment(start).clone().subtract(diffDays + 1, "day").toDate();

            this.filter.comparedDateRange = [comparedStart, comparedEnd]

            this.graphElement = echarts.init(document.getElementById("graph-container"))

            this.update();
        },

        update: function () {
            const {userId, currentPage, pageSize, filter} = this;
            const {dateRange, comparedDateRange, isCompared, unit, appId, scene, channel} = filter;
            const start = parseInt(dateRange[0].getTime() / 1000);
            const end = parseInt(dateRange[1].getTime() / 1000);

            const comparedStart = parseInt(comparedDateRange[0].getTime() / 1000);
            const comparedEnd = parseInt(comparedDateRange[1].getTime() / 1000);

            api.getEarningsUserPriReport(userId, currentPage, pageSize, unit, start, end, appId, scene, channel,
                isCompared, comparedStart, comparedEnd).then(result => {
                if (parseInt(result.code) !== 200) {
                    this.$message.error("请求失败， Err: " + result.msg)
                    return
                }

                this.yesterRevenue = result.data.yester_revenue;
                this.weekRevenue = result.data.week_revenue;
                this.monthRevenue = result.data.month_revenue;
                this.preMonthRevenue = result.data.pre_month_revenue;

                this.app = result.data.app;

                this.graph.items = this.buildGraph(result.data.graph.items);
                this.graph.comparedItems = this.buildGraph(result.data.graph.compared_items);
                this.drawGraph();

                const {list, pv_count, flow_request_count, flow_return_count, request_count, return_count, click_count,
                    revenue} = result.data.report;

                this.table.origin = list;
                this.table.items = this.mergeTableItems(list);
                this.pageCount = list.length;

                this.table.pvCount = pv_count;
                this.table.flowRequestCount = flow_request_count;
                this.table.flowReturnCount = flow_return_count;
                this.table.requestCount = request_count;
                this.table.returnCount = return_count;
                this.table.clickCount = click_count;
                this.table.revenue = revenue;

                this.currentPage = 1;
            }, err => {
                this.$message.error("请求失败， Err: " + err);
            })
        },

        handleGoBack: function () {
            Routers.back();
        },

        handleChangeCompared: function (value) {
            this.graph.disableComparedKpi = value;
            this.graph.isComparedKpi = false;
            this.update()
        },

        buildGraph: function (items) {

            const graphItems = {}

            let fields = ["revenue", "request_count", "return_count", "return_rate", "flow_request_count", "flow_return_count",
                "pv_count", "pv_rate", "click_count", "click_rate"];
            for (let i = 0; i < fields.length; i++) {
                let field = fields[i]
                const graphX = [];
                const graphY = []
                let graph = [];
                let untreated = []
                let suffix = "";
                let percentage = false;

                if (field === "return_rate") {
                    // 填充率
                    for (let i = 0; i < graphItems["request_count"].y.length; i++) {
                        graphY.push((graphItems["return_count"].y[i] / graphItems["request_count"].y[i]).toFixed(4))
                        graphX.push(graphItems["request_count"].x[i]);
                    }
                    percentage = true;
                } else if (field === "pv_rate") {
                    // 曝光率
                    for (let i = 0; i < graphItems["pv_count"].y.length; i++) {
                        graphY.push((graphItems["pv_count"].y[i] / graphItems["return_count"].y[i]).toFixed(4))
                        graphX.push(graphItems["return_count"].x[i]);
                    }
                    percentage = true;
                } else if (field === "click_rate") {
                    // 点击率
                    for (let i = 0; i < graphItems["click_count"].y.length; i++) {
                        graphY.push((graphItems["click_count"].y[i] / graphItems["pv_count"].y[i]).toFixed(4))
                        graphX.push(graphItems["pv_count"].x[i]);
                    }
                    percentage = true;
                } else {
                    for (let item of items) {
                        let name = unixFormatter(item["start_date"], 'YYYY-MM-DD')

                        if (this.filter.unit !== 1) {
                            name = name + "/" + unixFormatter(item["end_date"], 'YYYY-MM-DD')
                        }

                        if (this.filter.unit !== 1 && item["start_date"] === item["end_date"]) {
                            untreated.push(item)
                        } else {
                            if (graph[name] !== undefined) {
                                graph[name].count += item[field]
                            } else {
                                graph[name] = {
                                    count: item[field],
                                    name: name,
                                    start: item["start_date"],
                                    end: item["end_date"]
                                }
                            }
                        }
                    }

                    // 如果按照 '周' 或者 '月' 为单位，可能存在只有单独一天的数据，需特殊处理，单独插入
                    if (Object.keys(untreated).length > 0) {
                        for (let untreatedItem of untreated) {
                            for (let name of Object.keys(graph)) {
                                let graphItem = graph[name]
                                if (graphItem.start <= untreatedItem["start_date"] && graphItem.end >= untreatedItem["end_date"]) {
                                    graphItem.count += untreatedItem[field]
                                }
                            }
                        }
                    }

                    let sortGraph = [];
                    while (Object.keys(graph).length > 0) {
                        let keys = Object.keys(graph);
                        let minKey = keys[0];
                        for (let key of keys) {
                            let minTsc = graph[minKey].start + graph[minKey].end;
                            let tsc = graph[key].start + graph[key].end;
                            if (minTsc > tsc) {
                                minKey = key
                            }
                        }
                        sortGraph.push(graph[minKey]);
                        delete graph[minKey]
                    }
                    for (let item of sortGraph) {
                        graphX.push(item.name);
                        graphY.push(item.count);
                    }
                }

                graphItems[field] = {x: graphX, y: graphY, suffix: suffix, percentage: percentage};
            }
            return graphItems;
        },

        drawGraph: function () {
            const {isCompared} = this.filter;
            const {isComparedKpi, kpi, comparedKpi, items, comparedItems} = this.graph;
            const fieldItems = items[kpi];
            let option = {};

            const findName = (key) => {
                let name = "";
                for (let opt of this.graph.kpiItems) {
                    if (opt.value === key) {
                        name = opt.name;
                    }
                }
                return name;
            };

            const name = findName(kpi)

            if (isCompared) {
                const fieldComparedItems = comparedItems[kpi];
                option = {
                    color: ["#f56c6c", "#c0f1a5"],
                    tooltip: {
                        trigger: 'axis'
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: fieldItems.x
                        },
                        {
                            type: 'category',
                            position: 'bottom',
                            axisTick: {
                                show: false
                            },
                            axisLine: {
                                show: false
                            },
                            offset: 25,
                            data: fieldComparedItems.x
                        }
                    ],
                    yAxis: {
                        type: 'value',
                        name: name,
                        axisLine: {
                            show: true,
                        },
                        axisLabel: {
                            formatter: function (value) {
                                if (fieldItems.percentage) {
                                    return value * 100 + '%';
                                }
                                return value + fieldItems.suffix
                            }
                        }
                    },
                    series: [
                        {
                            data: fieldItems.y,
                            type: 'line',
                            smooth: true,
                            emphasis: {
                                focus: 'series'
                            },
                        },
                        {
                            data: fieldComparedItems.y,
                            type: 'line',
                            smooth: true,
                            emphasis: {
                                focus: 'series'
                            },
                        }
                    ]
                }
            } else {
                if (isComparedKpi) {
                    const comparedName = findName(comparedKpi);
                    const fieldComparedItems = items[comparedKpi];
                    option = {
                        color: ["#f56c6c", "#c0f1a5"],
                        legend: {
                            data: [name, comparedName],
                            bottom: 10,
                        },
                        tooltip: {
                            trigger: 'axis'
                        },
                        xAxis: [
                            {
                                type: 'category',
                                data: fieldItems.x
                            }
                        ],
                        yAxis: [
                            {
                                name: name,
                                type: 'value',
                                axisLine: {
                                    show: true,
                                },
                                axisLabel: {
                                    formatter: function (value) {
                                        if (fieldItems.percentage) {
                                            return value * 100 + '%';
                                        }
                                        return value + fieldItems.suffix
                                    }
                                }
                            },
                            {
                                type: 'value',
                                name: comparedName,
                                position: 'right',
                                axisLine: {
                                    show: true,
                                },
                                axisLabel: {
                                    formatter: function (value) {
                                        if (fieldComparedItems.percentage) {
                                            return value * 100 + '%';
                                        }
                                        return value + fieldComparedItems.suffix
                                    }
                                }
                            }
                        ],
                        series: [
                            {
                                name: name,
                                data: fieldItems.y,
                                type: 'line',
                                smooth: true,
                                emphasis: {
                                    focus: 'series'
                                },
                            }, {
                                name: comparedName,
                                data: fieldComparedItems.y,
                                yAxisIndex: 1,
                                type: 'line',
                                smooth: true,
                                emphasis: {
                                    focus: 'series'
                                },
                            }
                        ]
                    }
                } else {
                    option = {
                        color: ["#f56c6c"],
                        tooltip: {
                            trigger: 'axis'
                        },
                        xAxis: [
                            {
                                type: 'category',
                                data: fieldItems.x
                            }
                        ],
                        yAxis: {
                            type: 'value',
                            name: name,
                            axisLine: {
                                show: true,
                            },
                            axisLabel: {
                                formatter: function (value) {
                                    if (fieldItems.percentage) {
                                        return value * 100 + '%';
                                    }
                                    return value + fieldItems.suffix
                                }
                            }
                        },
                        series: [
                            {
                                data: fieldItems.y,
                                type: 'line',
                                smooth: true,
                                emphasis: {
                                    focus: 'series'
                                },
                            }
                        ]
                    }
                }
            }
            this.graphElement.setOption(option, {notMerge: true})
        },

        inDataField: function(value) {
            for (let field of this.table.filter.dataFields) {
                if (value === field) {
                    return true
                }
            }
            return false;
        },

        inKpiField: function (value) {
            for (let field of this.table.filter.kpiFields) {
                if (value === field) {
                    return true
                }
            }
            return false;
        },

        handleChangeKpiFiled: function () {
            this.table.items = this.mergeTableItems(this.table.origin);
            this.pageCount = this.table.items.length;
            this.currentPage = 1;
        },

        mergeTableItems: function (origin) {
            const {kpiFields} = this.table.filter;
            const merge = {};

            const items = origin.sort((a, b) => ((b.start_date + b.end_date) - (a.start_date + a.end_date)))
            for (let i=0; i < items.length; i++) {
                const item = items[i];

                let key = ""
                if (kpiFields.includes("date")) {
                    key += "_" + item.start_date + "_" + item.end_date;
                }
                if (kpiFields.includes("scene")) {
                    key += "_" + item.scene
                }
                if (kpiFields.includes("channel")) {
                    key += "_" + item.channel
                }
                if (kpiFields.includes("app")) {
                    key += "_" + item.app_id;
                }

                if (merge[key] !== undefined) {
                    const oldItem = merge[key];
                    const newItem = {
                        revenue: oldItem.revenue + item.revenue,
                        pv_count: oldItem.pv_count + item.pv_count,
                        click_count: oldItem.click_count + item.click_count,
                        request_count: oldItem.request_count + item.request_count,
                        return_count: oldItem.return_count + item.return_count,
                        flow_request_count: oldItem.flow_request_count + item.flow_request_count,
                        flow_return_count: oldItem.flow_return_count + item.flow_return_count,
                    }

                    if (kpiFields.includes("date")) {
                        newItem.start_date = oldItem.start_date;
                        newItem.end_date = oldItem.end_date;
                    }
                    if (kpiFields.includes("scene")) {
                        newItem.scene = oldItem.scene;
                    }
                    if (kpiFields.includes("channel")) {
                        newItem.channel = oldItem.channel;
                    }

                    if (kpiFields.includes("app")) {
                        newItem.app_id = oldItem.app_id;
                        newItem.app_name = oldItem.app_name;
                    }
                    merge[key] = newItem;
                } else {
                    merge[key] = {
                        revenue: item.revenue,
                        request_count: item.request_count,
                        return_count: item.return_count,
                        flow_request_count: item.flow_request_count,
                        flow_return_count: item.flow_return_count,
                        pv_count: item.pv_count,
                        click_count: item.click_count,
                    }

                    if (kpiFields.includes("date")) {
                        merge[key].start_date = item.start_date;
                        merge[key].end_date = item.end_date;
                    }
                    if (kpiFields.includes("scene")) {
                        merge[key].scene = item.scene;
                    }
                    if (kpiFields.includes("channel")) {
                        merge[key].channel = item.channel;
                    }

                    if (kpiFields.includes("app")) {
                        merge[key].app_id = item.app_id;
                        merge[key].app_name = item.app_name;
                    }
                }
            }

            const newItems = [];
            for (let key in merge) {
                newItems.push(merge[key])
            }

            return newItems;
        },

        getTableSummary: function () {

            const summary = [];
            const {revenue, flowRequestCount, flowReturnCount, requestCount, returnCount, pvCount, clickCount} = this.table;

            if (this.inKpiField("date")) {
                summary.push("总计")
            }

            if (this.inKpiField("app")) {
                summary.push("")
            }

            if (this.inKpiField("scene")) {
                summary.push("");
            }

            if (this.inKpiField("channel")) {
                summary.push("");
            }

            if (this.inKpiField("revenue")) {
                summary.push((revenue/100).toFixed(2));
            }

            if (this.inDataField("pv_count")) {
                summary.push(pvCount);
            }

            if (this.inDataField("pv_rate")) {
                const pvRate = (pvCount / returnCount * 100).toFixed(2) + "%";
                summary.push(pvRate);
            }

            if (this.inDataField("click_count")) {
                summary.push(clickCount);
            }

            if (this.inDataField("click_rate")) {
                const clickRate = (clickCount / pvCount * 100).toFixed(2) + "%";
                summary.push(clickRate);
            }

            if (this.inDataField("flow_request_count")) {
                summary.push(flowRequestCount);
            }

            if (this.inDataField("flow_return_count")) {
                summary.push(flowReturnCount);
            }

            if (this.inDataField("request_count")) {
                summary.push(requestCount);
            }

            if (this.inDataField("return_count")) {
                summary.push(returnCount);
            }

            if (this.inDataField("return_rate")) {
                const returnRate = (returnCount / requestCount * 100).toFixed(2) + "%";
                summary.push(returnRate);
            }

            return summary;
        }
    }
}
</script>

<style scoped>

.card .header .small {
    font-size: 12px;
}

.card:not(:first-child) {
    margin-top: 20px;
}

.card.summary .form {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.card.summary .form .form-item {
    height: 120px;
    flex-grow: 1;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.card.summary .form .form-item .revenue {
    font-size: 28px;
    color: #1f1f1f;
    line-height: 28px;
    font-weight: 700;
    margin-top: 12px;
    margin-bottom: 10px;
    text-align: center;
}

.card.summary .form .divider {
    height: 100px;
}

.card.summary .form .form-item .name {
    font-size: 14px;
    color: #434343;
    font-weight: 400;
}

/** 处理表单Margin **/
.card.filter .form-foot .el-form-item {
    margin-bottom: 0;
}

.card.graph .filter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.card.table .filter {
    display: flex;
    margin-bottom: 16px;
    align-items: center;
}

.card.table .footer .pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}


.page >>> .el-table .el-table__body-wrapper {
    order: 1 !important;
}
.page >>> .el-table__fixed-body-wrapper{
    top:96px !important;
}

.page >>> .el-table__fixed-footer-wrapper{
    z-index: 0;
    top:47px;
}

</style>