<template>
    <div>
        <el-card class="card filter">
            <el-form :model="filter" inline>
                <el-form-item label="账户名称">
                    <el-input v-model="filter.name" placeholder="请输入账户名称" size="small"/>
                </el-form-item>

                <el-form-item label="账户收入">
                    <el-select v-model="filter.amountGreater" size="small" style="width: 150px">
                        <el-option :value="0" label="大于等于 0 元"/>
                        <el-option :value="1000" label="大于等于 10 元"/>
                        <el-option :value="10000" label="大于等于 100 元"/>
                        <el-option :value="100000" label="大于等于 1,000 元"/>
                        <el-option :value="1000000" label="大于等于 10,000 元"/>
                        <el-option :value="10000000" label="大于等于 100,000 元"/>
                    </el-select>
                </el-form-item>

                <el-form-item label="状态">
                    <el-select v-model="filter.status" size="small" style="width: 150px">
                        <el-option :value="0" label="全部"/>
                        <el-option :value="1" label="正常"/>
                        <el-option :value="2" label="封禁"/>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="handleClickSearch" size="small">查找</el-button>
                </el-form-item>

                <el-form-item>
                    <el-button class="filter-clear" type="text" @click="handleClickClear" size="small">清空搜索条件</el-button>
                </el-form-item>

            </el-form>
        </el-card>

        <el-card class="card">
            <el-empty v-if="this.data.length <= 0" description="无账户信息"></el-empty>
            <el-table v-if="this.data.length > 0"
                      :data="data"
                      stripe
                      highlight-current-row>
                <el-table-column prop="id" label="ID" width="100"/>

                <el-table-column label="更新时间" width="180" align="center">
                     <template #default="scope">
                        <span>{{unixFormatter(scope.row.updated_at, 'YYYY-MM-DD hh:mm:ss') }}</span>
                    </template>
                </el-table-column>

                <el-table-column prop="username" label="用户名" align="center"/>

                <el-table-column label="账户总收入 (元)" align="center">
                     <template #default="scope">
                        <span>{{ (scope.row.amount / 100).toFixed(2) }}</span>
                    </template>
                </el-table-column>

                <el-table-column label="账户余额 (元)" align="center">
                     <template #default="scope">
                        <span>{{ (scope.row.balance / 100).toFixed(2) }}</span>
                    </template>
                </el-table-column>

                <el-table-column prop="payee_name" label="收款人" align="center"/>

                <el-table-column prop="bank_card_number" label="银行卡卡号" width="260px" align="center"/>

                <el-table-column prop="bank_address" label="开户地址" align="center"/>

                <el-table-column label="状态" align="center">
                     <template #default="scope">
                        <template v-if="scope.row.status === 1">
                            <el-tag type="info" size="small">正常</el-tag>
                        </template>
                        <template v-if="scope.row.status === 2">
                            <el-tag type="danger" size="small">封禁</el-tag>
                        </template>
                    </template>
                </el-table-column>

                <el-table-column
                    label="操作">
                     <template #default="scope">
                        <el-button v-if="scope.row.status === 1" type="danger" @click="handleBlocked(scope.row.id)"
                                   size="mini" plain>封禁
                        </el-button>
                        <div v-if="scope.row.status === 2">
                            <el-button @click="handleUnblocked(scope.row.id)" size="mini" plain>解封</el-button>
                            &nbsp;
                            <el-tooltip>
                              <div><slot name="content">{{ scope.row.blocked_msg }}</slot></div>
                                <el-icon class="el-icon-question"/>
                            </el-tooltip>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="footer">
                <el-pagination
                    class="pagination"
                    background
                    layout="prev, pager, next"
                    @current-change="handleChangeCurrentPage"
                    :page-size="this.pageSize"
                    :total="this.pageCount">
                </el-pagination>
            </div>
        </el-card>

        <confirm ref="block_msg_dialog" title="原因" min-rows="6" max-rows="6"/>
    </div>
</template>

<script>
import * as api from "../../api/api";
import {unixFormatter} from "../../util/format";
import Confirm from "../../component/Confirm.vue";

export default {
    name: "AccountEarnings",
    components: {Confirm},
    data: function () {
        return {
            filter: {
                contact: "",
                amountGreater: 0,
                status: 0,
            },
            data: [],
            currentPage: 1,
            pageSize: 10,
            pageCount: 0,
        }
    },

    created() {
        this.update();
    },

    methods: {
        unixFormatter,

        update: function () {
            const {currentPage, pageSize, filter} = this;
            const {name, amountGreater, status} = filter
            api.getEarningAccounts(currentPage, pageSize, name, amountGreater, status).then(result => {
                if (parseInt(result.code) !== 200) {
                    this.$message.error("获取账户列表失败， Err: " + result.msg)
                    return
                }
                this.data = result.data.list;
                this.pageCount = result.data.count
            }, err => {
                this.$message.error("获取账户列表失败, Err: " + err)
            })
        },

        handleClickSearch: function () {
            this.update();
        },

        handleClickClear: function () {
            this.filter.name = "";
            this.filter.amount = 0;
            this.update();
        },

        handleBlocked: function (id) {
            this.$refs.block_msg_dialog.show().then(msg => {
                api.blockAccount(id, true, msg).then(result => {
                    if (parseInt(result.code) !== 200) {
                        this.$message.error("解封失败， Err: " + result.msg)
                        return
                    }
                    this.$message.success("解封成功")
                    this.update()
                }, err => {
                    this.$message.error("解封失败， Err: " + err)
                })
            }, () => {console.log("取消")});
        },

        handleUnblocked: function (id) {
            api.blockAccount(id, false).then(result => {
                if (parseInt(result.code) !== 200) {
                    this.$message.error("解禁失败， Err: " + result.msg)
                    return
                }
                this.$message.success("解禁成功")
                this.update()
            }, err => {
                this.$message.error("解禁失败， Err: " + err)
            })
        },

        handleChangeCurrentPage: function (page) {
            this.currentPage = page
            this.update()
        },
    },
}
</script>

<style scoped>

.card:not(:first-child) {
    margin-top: 20px;
}

.el-form .el-form-item {
    margin-bottom: 0 !important;
}

.filter-form .el-form-item {
    display: flex;
    margin-bottom: 0 !important;
}

.filter .filter-clear {
    padding: 0 0 0 0 !important;
}

.card .footer {
    display: flex;
    justify-content: flex-end;
}

.card .footer .pagination {
    margin-top: 20px;
}

</style>