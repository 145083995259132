<template>
    <el-tag class="tag" :style="{width: size, borderColor: color, color: color}" effect="plain" disable-transitions	>
        <slot></slot>
    </el-tag>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
    name: "tag",

    props: {
        color: {
            type: String,
            required: false,
            default: "#f56c6c"
        },
        size: {
            type: String,
            required: false,
            default: "80px"
        }
    }
}
</script>

<style scoped>

.tag {
    text-align: center;
}

</style>