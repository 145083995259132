<template>
  <div>
    <el-table ref="nettable"
              :data="data"
              :row-style="{cursor: 'pointer'}"
              row-key="id"
              @ondrag="handleDrag">
      <el-table-column label="ID" prop="id" width="80px" align="center"/>
      <el-table-column label="名称" prop="name" width="100px" align="center"/>
      <el-table-column label="渠道" width="100px" align="center">
        <template #default="scope">
          <tag v-if="scope.row.channel===1">优量汇</tag>
          <tag v-if="scope.row.channel===2">穿山甲</tag>
          <tag v-if="scope.row.channel===4">快手</tag>
          <tag v-if="scope.row.channel===5">Gromore</tag>
        </template>
      </el-table-column>
      <el-table-column label="应用ID" prop="app_id" align="center"/>
      <el-table-column label="包名" prop="package_name" align="center"/>
      <el-table-column label="操作" width="180px" align="center">
        <template #default="scope">
          <el-button type="text" size="mini" @click="handleCopyVerifyCbUrl(scope.row.verify_cb_url)">回调地址
          </el-button>
          <el-button type="text" size="mini" @click="handleEdit(scope.row.id)">编辑</el-button>
          <el-button class="btn-delete" type="text" size="mini" @click="handleDelete(scope.row.id)">删除</el-button>
          <el-icon>
            <DCaret/>
          </el-icon>
        </template>
      </el-table-column>
    </el-table>
    <br>
    <el-table :data="priorityData">
      <el-table-column label="时间段" width="140px" show-overflow-tooltip align="center">
        <template #default="scope">
                                <span>
                                    <span>{{ scope.row.timeStart.format("HH:mm") }}</span>
                                    &nbsp;-&nbsp;
                                    <span>{{ scope.row.timeEnd.format("HH:mm") }}</span>
                                </span>
        </template>
      </el-table-column>
      <el-table-column label="网络排序" align="center">
        <template #default="scope">
          <template :key="i" v-for="(item, i) in scope.row.items">
                        <span>
                            <span>{{ item.network_name }}_{{ item.network_id }}</span>
                            <span v-if="i != scope.row.items.length - 1">&nbsp;>>> &nbsp;</span>
                        </span>
          </template>
        </template>
      </el-table-column>

      <el-table-column label="操作" align="center" width="100px">
        <template #default="scope">
          <el-button type="text" size="mini" @click="handleEditPriority(scope.row.id, scope.row)">编辑</el-button>
          <el-button type="text" size="mini" @click="handleDeletePriority(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <br>
    <el-button type="primary" size="mini" icon="Plus" @click="handleAddPriority">添加</el-button>

    <net-priority-create-dialog ref="priorityCreateDialog" @update="handleUpdatePriority"/>
    <net-priority-edit-dialog ref="priorityEditDialog" @update="handleUpdatePriority"/>
  </div>
</template>

<script>
import {unixFormatter} from "../../util/format";
import * as api from "../../api/api";
import {CODE_OK} from "../../api/request";
import Sortable from "sortablejs";

import Tag from "../../component/Tag.vue";
import NetPriorityCreateDialog from "./NetPriorityCreateDialog.vue";
import NetPriorityEditDialog from "./NetPriorityEditDialog.vue";
import moment from "moment/moment";
import Routers from "../../router/"
import {Plus} from "@element-plus/icons";
import {copyTextToClipboard} from "@/util/object";

export default {
  name: "NetTable",
  computed: {
    Plus() {
      return Plus
    }
  },

  components: {Tag, NetPriorityCreateDialog, NetPriorityEditDialog},

  props: {
    data: {
      type: Array,
      required: false,
      default: () => [],
    },
    priorityData: {
      type: Array,
      required: false,
      default: () => [],
    },
    id: {
      required: true,
    },
    type: {
      type: String,
      required: false,
    }
  },

  mounted() {
    this.initView();
  },

  methods: {
    unixFormatter,

    initView: function () {
      this.initDrop();
    },

    initDrop: function () {
      const tbody = this.$el.querySelector('.el-table__body-wrapper tbody')
      const that = this.data
      Sortable.create(tbody, {
        animation: 200,
        onEnd({newIndex, oldIndex}) {
          if (newIndex === oldIndex) return
          that.data.splice(
              newIndex,
              0,
              that.data.splice(oldIndex, 1)[0]
          )
          const newArray = that.data.slice(0)
          that.data = []
          that.$nextTick(function () {
            that.data = newArray;
            this.sort();
          })
        }
      })
    },

    sort: function () {
      const orders = [];
      for (let i = 0; i < this.data.length; i++) {
        orders.push(this.data[i].id);
      }
      api.updateNetworkOrders(parseInt(this.id), orders, this.type).then(result => {
        if (parseInt(result.code) !== CODE_OK) {
          this.$message.error("网络排序更新失败, Err=" + result.msg);
          this.$emit("update")
          return;
        }
      }, err => {
        this.$message.error("网络排序更新失败, Err=" + err);
        this.$emit("update")
      });
    },

    handleUpdatePriority: function () {
      this.$emit("update");
    },

    handleDrag: function (event) {
      event.preventDefault();
      event.stopPropagation();
    },

    handleCopyVerifyCbUrl: function (url) {
      // this.$message.info("已拷贝到剪切板");
      copyTextToClipboard(url).then(() => {
        this.$message.info("已拷贝到剪切板");
      }, () => {
        this.$message.error("拷贝到剪切板失败");
      })
    },

    handleEdit: function (id) {
      Routers.push({
        path: "network/edit",
        query: {
          id: id,
        }
      })
    },

    handleDelete: function (id) {
      this.$confirm('删除网络无法恢复, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        api.deleteNetwork(parseInt(id)).then(result => {
          if (parseInt(result.code) !== CODE_OK) {
            this.$message.error("删除失败, Err: " + result.msg);
            return
          }
          this.$message.success("删除成功");
          this.$emit("update")
        }, err => {
          this.$message.error("删除失败, Err: " + err);
        })

      }).catch(() => {
      });
    },

    handleAddPriority: function () {
      const newNetworks = [];
      for (let i = 0; i < this.data.length; i++) {
        const item = this.data[i];
        newNetworks.push({name: item.name, id: item.id});
      }

      if (newNetworks.length <= 0) {
        this.$message.warning("网络数量为空，不可添加网络优先级");
        return;
      }

      // 计算可用时间段
      const selectableRange = this.prioritySelectableRange(this.priorityData);
      if (selectableRange.length == 0) {
        this.$message.warning("不存在可用时段");
        return;
      }

      this.$refs.priorityCreateDialog.show(parseInt(this.id), newNetworks, selectableRange, this.type);
    },


    prioritySelectableRange: function (priorities) {
      const selectableRange = [];
      if (priorities.length === 0) {
        selectableRange.push([moment({hour: 0, minute: 0, second: 0}).toDate(),
          moment({hour: 23, minute: 59, second: 59}).toDate()]);
        return selectableRange;
      }

      priorities.sort((a, b) => a.timeStart.unix() - b.timeEnd.unix());

      let start = moment({hour: 0, minute: 0, second: 0});
      let end = moment({hour: 23, minute: 59, second: 59});
      for (let i = 0; i < priorities.length; i++) {
        const priority = priorities[i];
        const pStart = priority.timeStart;
        const pEnd = priority.timeEnd;
        if (start.isBefore(pStart)) {
          selectableRange.push([start.clone().toDate(), pStart.clone().toDate()]);
        }
        start = pEnd;

        if (i === priorities.length - 1 && !(pEnd.hour() === end.hour() && pEnd.minute() === end.minute())) {
          selectableRange.push([pEnd.clone().toDate(), end.clone().toDate()]);
        }
      }
      return selectableRange;
    },

    handleEditPriority: function (id, row) {
      const {id: appId, type} = this;
      const start = new Date();
      const end = new Date();
      start.setHours(row.start_hour);
      start.setMinutes(row.start_minute);
      end.setHours(row.end_hour);
      end.setMinutes(row.end_minute);
      const items = [];
      for (let i = 0; i < row.items.length; i++) {
        const item = row.items[i];
        items.push({id: item.network_id, name: item.network_name})
      }

      // 计算可用时间段
      const priorities = []
      for (let i = 0; i < this.priorityData.length; i++) {
        const priority = this.priorityData[i];
        if (priority.id === id) {
          continue;
        }
        priorities.push(priority);
      }

      const selectableRange = this.prioritySelectableRange(priorities);
      this.$refs.priorityEditDialog.show(id, appId, start, end, items, selectableRange, type);
    },

    handleDeletePriority: function (id) {
      this.$confirm('确定删除网络优先级？', "警告", {center: true}).then(() => {
        api.deleteNetworkPriority(id).then(result => {
          if (parseInt(result.code) !== CODE_OK) {
            this.$message.error("删除网络优先级失败， Err: " + result.msg)
            return
          }
          this.$emit("update")
        }, err => {
          this.$message.error("删除网络优先级失败, Err: " + err)
        })
      }).catch(() => {
      });
    },
  }
}
</script>

<style scoped>

</style>