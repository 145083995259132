<template>
    <div>
        <el-card class="card filter">
            <div class="content">
                <el-form inline>
                    <el-form-item label="账户名称">
                        <el-input v-model="filter.name" size="small"/>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="update" size="small">筛 选</el-button>
                    </el-form-item>
                </el-form>

                <el-button type="primary" size="small" @click="handleSignup">注册申请</el-button>
            </div>
        </el-card>

        <el-card class="card table">
            <el-table :data="data">
                <el-table-column label="创建日期" align="center" width="100px" :formatter="(row) => unixFormatter(row.created_at, 'YYYY-MM-DD')"/>
                <el-table-column prop="company" label="公司名称" align="center"/>
                <el-table-column prop="tel" label="电话" align="center"/>
                <el-table-column prop="email" label="邮箱" align="center"/>
                <el-table-column prop="message" label="备注" align="center" width="240px" show-overflow-tooltip	/>
                <el-table-column label="操作" align="center">
                    <template #default="scope">
                        <el-button type="text" size="mini" @click="handleSignup(scope.row)">注册</el-button>
                        <el-button type="text" size="mini" @click="handleDelete(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <div class="footer">
                <el-pagination
                    class="pagination"
                    background
                    layout="prev, pager, next"
                    @current-change="update"
                    v-model:current-page="currentPage"
                    :page-size="pageSize"
                    :total="pageCount">
                </el-pagination>
            </div>
        </el-card>
        <signup-dialog ref="dialog" @update="id => deleteOne(id)"/>
    </div>
    </template>






<script>
import  * as api from "../../api/api"
import {CODE_OK} from "../../api/request";
import {unixFormatter} from "../../util/format";
import SignupDialog from "./SignupDialog.vue";
import { ref } from 'vue';

export default {

  setup() {
    const dialog = ref(null);

    return {
      dialog
    };
  },
  // eslint-disable-next-line vue/multi-word-component-names
    name: "signup",
     components: {SignupDialog},

    data: function () {
        return {
            filter: {
                name: "",
            },
            data: [],
            currentPage: 1,
            pageSize: 5,
            pageCount: 0,
        };
    },

    mounted() {
        this.initView();
    },

    methods: {

        unixFormatter,

        initView: function () {
            this.update();
        },

        update: function () {
            const {filter, currentPage, pageSize} = this;
            const {name: search} = filter;
            api.getUserSignupList(currentPage, pageSize, search).then(result => {
                if (parseInt(result.code) !== CODE_OK) {
                    this.$message.error("获取注册申请列表失败， Err: " + result.msg)
                    return
                }

                this.data = result.data.list;
                this.pageCount = result.data.count
            }, err => {
                this.$message.error("获取注册申请列表失败， Err: " + err)
            })
        },

        handleSignup: function (form) {
            this.dialog.show(form)
        },

        handleDelete: function (id) {
            this.$confirm('确定删除申请信息 ？', "警告", {center: true}).then(() => {
                this.deleteOne(id);
            }).catch(() => {});
        },

        deleteOne: function (id) {
            api.deleteUserSignup(id).then(result => {
                if (parseInt(result.code) !== CODE_OK) {
                    this.$message.error("删除注册申请失败， Err: " + result.msg)
                    return
                }
                this.update();
            }, err => {
                this.$message.error("删除注册申请失败， Err: " + err)
            })
        }
    },
}
</script>

<style scoped>

.dialog-footer button:first-child {
  margin-right: 10px;
}
.card:not(:first-child) {
    margin-top: 20px;
}

.card.filter .content .el-form-item {
    margin-bottom: 0px;
}

.card.filter .content {
    display: flex;
    justify-content: space-between;
}

.card.table .footer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

</style>