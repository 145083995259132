// 请求地址

const debug = process.env.NODE_ENV === "development";

export const DOMAIN = debug ? "http://127.0.0.1:8082" : "https://openapi.dongligu.com";

export const URI_LOGIN = DOMAIN + "/admin-api/v1/login/pwd";

export const URI_ADMIN_DASHBOARD = DOMAIN + "/admin-api/v1/dashboard";

export const URI_ADMIN_DASHBOARD_CLIENT = DOMAIN + "/admin-api/v1/dashboard/client";

export const URI_ADMIN_DASHBOARD_CLIENT_REAL = DOMAIN + "/admin-api/v1/dashboard/client/real";

export const URI_ADMIN_DASHBOARD_GRAPH = DOMAIN + "/admin-api/v1/dashboard/graph";

export const URI_ADMIN_EARNING_CHANNEL_LIST = DOMAIN + "/admin-api/v1/earning/channel/list";

export const URI_ADMIN_EARNING_CHANNEL_REDOWNLOAD = DOMAIN + "/admin-api/v1/earning/channel/redownload";

export const URI_ADMIN_EARNING_BEHAVIOR_LIST = DOMAIN + "/admin-api/v1/earning/behavior/list";

export const URI_ADMIN_EARNING_RECALCULATE = DOMAIN + "/admin-api/v1/earning/recalculate";

export const URI_ADMIN_EARNING_LIST = DOMAIN + "/admin-api/v1/earning/list";

export const URI_ADMIN_EARNING_PLATFORM = DOMAIN + "/admin-api/v1/earning/platform";

export const URI_ADMIN_EARNING_PLATFORM_REPORT = DOMAIN + "/admin-api/v1/earning/platform/report";

export const URI_ADMIN_EARNING_USER_REPORT = DOMAIN + "/admin-api/v1/earning/user/report";

export const URI_ADMIN_EARNING_USER_PRI_REPORT = DOMAIN + "/admin-api/v1/earning/user/pri/report";

export const URI_ADMIN_SETTLEMENT_CONFIG = DOMAIN + "/admin-api/v1/settlement/config";

export const URI_ADMIN_SETTLEMENT_CONFIG_CHECK_NEXT = DOMAIN + "/admin-api/v1/settlement/config/progress";

export const URI_ADMIN_SETTLEMENT_PLATFORM_REPORT = DOMAIN + "/admin-api/v1/settlement/platform/report";

export const URI_ADMIN_SETTLEMENT_PLATFORM_CHECK = DOMAIN + "/admin-api/v1/settlement/platform/check";

export const URI_ADMIN_SETTLEMENT_PLATFORM_RECHECK = DOMAIN + "/admin-api/v1/settlement/platform/recheck";

export const URI_ADMIN_SETTLEMENT_USER_REPORT = DOMAIN + "/admin-api/v1/settlement/user/report";

export const URI_ADMIN_SETTLEMENT_APP_REPORT = DOMAIN + "/admin-api/v1/settlement/app/report";

export const URI_ADMIN_SETTLEMENT_APP_CHECK = DOMAIN + "/admin-api/v1/settlement/app/check";

export const URI_ADMIN_SETTLEMENT_APPD_REPORT = DOMAIN + "/admin-api/v1/settlement/app/d/report";

export const URI_ADMIN_SETTLEMENT_APPD_UDT = DOMAIN + "/admin-api/v1/settlement/app/d/update";

export const URI_ADMIN_SETTLEMENT_FINAL_PREVIEW = DOMAIN + "/admin-api/v1/settlement/final/preview";

export const URI_ADMIN_SETTLEMENT_FINAL_GEN = DOMAIN + "/admin-api/v1/settlement/final/gen";

export const URI_ADMIN_SETTLEMENT_FINAL = DOMAIN + "/admin-api/v1/settlement/final";

export const URI_ADMIN_SETTLEMENT_QUERY = DOMAIN + "/admin-api/v1/settlement/query";

export const URI_ADMIN_SETTLEMENT_QUERY_FAPIAO_CHECK = DOMAIN + "/admin-api/v1/settlement/query/fapiao/check";

export const URI_ADMIN_SETTLEMENT_QUERY_PAY = DOMAIN + "/admin-api/v1/settlement/query/pay";

export const URI_ADMIN_SYSTEM_LOG_LIST = DOMAIN + "/admin-api/v1/setting/log/list";

export const URI_ADMIN_SYSTEM_CONFIG = DOMAIN + "/admin-api/v1/system/config";

export const URI_ADMIN_SYSTEM_CONFIG_UPDATE = DOMAIN + "/admin-api/v1/system/config/update";

export const URI_ADMIN_SYSTEM_TASK_EXECUTE = DOMAIN + "/admin-api/v1/system/task/execute";

export const URI_ADMIN_NETWORK = DOMAIN + "/admin-api/v1/network";

export const URI_ADMIN_NETWORK_LIST = DOMAIN + "/admin-api/v1/network/list";

export const URI_ADMIN_NETWORK_DELETE = DOMAIN + "/admin-api/v1/network/delete";

export const URI_ADMIN_NETWORK_CREATE = DOMAIN + "/admin-api/v1/network/create";

export const URI_ADMIN_NETWORK_UPDATE = DOMAIN + "/admin-api/v1/network/update";

export const URI_ADMIN_NETWORK_ORDERS_UPDATE = DOMAIN + "/admin-api/v1/network/orders/update";

export const URI_ADMIN_NETWORK_PRIORITY_CREATE = DOMAIN + "/admin-api/v1/network/priority/create";

export const URI_ADMIN_NETWORK_PRIORITY_UPDATE = DOMAIN + "/admin-api/v1/network/priority/update";

export const URI_ADMIN_NETWORK_PRIORITY_DELETE = DOMAIN + "/admin-api/v1/network/priority/delete";

export const URI_ADMIN_NETWORK_STATIC_RECORD_LIST = DOMAIN + "/admin-api/v1/network/static_record/list";

export const URI_ADMIN_NETWORK_STATIC_RECORD_CREATE = DOMAIN + "/admin-api/v1/network/static_record/create";

export const URI_ADMIN_NETWORK_STATIC_RECORD_UPDATE = DOMAIN + "/admin-api/v1/network/static_record/update";

export const URI_ADMIN_NETWORK_STATIC_RECORD_DELETE = DOMAIN + "/admin-api/v1/network/static_record/delete";

export const URI_ADMIN_MEDIA_APP_LIST = DOMAIN + "/admin-api/v1/media/app/list";

export const URI_ADMIN_MEDIA_APP = DOMAIN + "/admin-api/v1/media/app";

export const URI_ADMIN_MEDIA_APP_STATUS_UPDATE = DOMAIN + "/admin-api/v1/media/app/status/update";

export const URI_ADMIN_ACCOUNT_LIST = DOMAIN + "/admin-api/v1/account/list";

export const URI_ADMIN_ACCOUNT_PASS  = DOMAIN + "/admin-api/v1/account/pass";

export const URI_ADMIN_ACCOUNT_BLOCK = DOMAIN + "/admin-api/v1/account/block";

export const URI_ADMIN_ACCOUNT_USER = DOMAIN + "/admin-api/v1/account/user";

export const URI_ADMIN_ACCOUNT_WITHDRAW_RESULT = DOMAIN + "/admin-api/v1/account/withdraw/result";

export const URI_ADMIN_USER_LIST = DOMAIN + "/admin-api/v1/user/list";

export const URI_ADMIN_USER = DOMAIN + "/admin-api/v1/user";

export const URI_ADMIN_USER_CREATE = DOMAIN + "/admin-api/v1/user/create";

export const URI_ADMIN_USER_EDIT = DOMAIN + "/admin-api/v1/user/edit";

export const URI_ADMIN_USER_FORBID = DOMAIN + "/admin-api/v1/user/forbid";

export const URI_ADMIN_USER_ENABLE = DOMAIN + "/admin-api/v1/user/enable";

export const URI_ADMIN_USER_SIGNUP_LIST = DOMAIN + "/admin-api/v1/user/signup/list";

export const URI_ADMIN_USER_SIGNUP_DELETE = DOMAIN + "/admin-api/v1/user/signup/delete";

export const URI_ADMIN_USER_CONTACT_LIST = DOMAIN + "/admin-api/v1/user/contact/list";

export const URI_ADMIN_USER_CONTACT_DELETE = DOMAIN + "/admin-api/v1/user/contact/delete";

export const URI_ADMIN_USER_ROLES_UPDATE = DOMAIN + "/admin-api/v1/user/roles/update";

export const URI_ADMIN_ISSUE_JOINSDKDOCUMENT_UPDATE = DOMAIN + "/admin-api/v1/issue/joinsdkdocument/update";

export const URI_ADMIN_ISSUE_JOINDOCUMENT = DOMAIN + "/admin-api/v1/issue/joinsdkdocument";

export const URI_ADMIN_ISSUE_JOINSDKFILE_LIST= DOMAIN + "/admin-api/v1/issue/joinsdkfile/list";

export const URI_ADMIN_ISSUE_JOINSDKFIL_UPDATE = DOMAIN + "/admin-api/v1/issue/joinsdkfile/update";

export const URI_ADMIN_MESSAGE_GLOBAL_LIST = DOMAIN + "/admin-api/v1/msg/global/list";

export const URI_ADMIN_MESSAGE_GLOBAL_DELETE = DOMAIN + "/admin-api/v1/msg/global/delete";

export const URI_ADMIN_MESSAGE_GLOBAL_CREATE = DOMAIN + "/admin-api/v1/msg/global/create";

export const URI_ZIMG_UPLOAD ="http://img.7w7ko.com/upload";

export const URI_ZIMG_PREFIX = "http://img.7w7ko.com";