import { createRouter, createWebHashHistory } from 'vue-router'

import UserLogin from "../views/login/Login.vue"
import E404 from "../views/error/E404.vue"
import Dashboard from '../views/Framework.vue'
import Home from '../views/Home.vue'
import Advanced from '../views/preference/Advanced.vue'
import Media from  '../views/media/Meidia.vue'
import AppEdit from '../views/media/AppEdit.vue'
import NetCreate from '../views/media/NetCreate.vue'
import NetEdit from '../views/media/NetEdit.vue'
import PlatformEarnings from '../views/financial/PlatformEarnings.vue'
import UserEarnings from  '../views/financial/UserEarnings.vue'
import UserEarning from '../views/financial/UserEarning.vue'
import AccountEarnings  from   '../views/financial/AccountEarnings.vue'
import CenterController from  '../views/settlement/CenterController.vue'
import AppForm from  '../views/settlement/AppForm.vue'
import PlatformHistory from '../views/settlement/PlatformHistory.vue'
import CompanyFormHistory from  '../views/settlement/CompanyFormHistory.vue'
import AppFormHistory from  '../views/settlement/AppFormHistory.vue'
import AppFormEverydayHistory from '../views/settlement/AppFormEverydayHistory.vue'
import PayQuery from '../views/settlement/PayQuery.vue'
import Users from '../views/user/Users.vue'
import Signup from '../views/user/Signup.vue'
import Contact from '../views/user/Contact.vue'
import JoinDoc from '../views/docs/JoinDoc.vue'
import JoinFile from '../views/docs/JoinFile.vue'


export const AdminHomeRouter = [
    {
        path: '/dashboard',
        redirect: "/dashboard/home",
        component: Dashboard,
        children: [
            {
                path: "home",
                name: "首页",
                component: Home,
            },
        ]
    },
];

export const AdminSystemRouter = [
    {
        path: '/dashboard/preference',
        component: Dashboard,
        children: [
            {
                path: "config",
                component: Advanced
            }
        ]
    }
]

export const AdminMediaRouter = [
    {
        path: '/dashboard/media',
        redirect: "/dashboard/media/app",
        component: Dashboard,
        children: [
            {
                path: "app",
                component: Media,
            }, {
                path: "app/edit",
                component: AppEdit
            }, {
                path: "app/network/create",
                component: NetCreate
            }, {
                path: "app/network/edit",
                component: NetEdit
            }
        ]
    }
];

export const AdminFinancialRouter = [
    {
        path: '/dashboard/financial',
        component: Dashboard,
        children: [
            {
                path: "platform",
                component: PlatformEarnings,
            },
            {
                path: "user",
                component: UserEarnings,
            },
            {
                path: "user/pri",
                component: UserEarning,
            },
            {
                path: "blank",
                component: AccountEarnings
            },
        ]
    }
];


export const AdminSettlementRouter = [
    {
        path: '/dashboard/settlement',
        redirect: "/dashboard/settlement/current",
        component: Dashboard,
        children: [
            {
                path: "current",
                component: CenterController,
            },
            {
                path: "current/user/app/form",
                component: AppForm,
            },
            {
                path: "platform/history",
                component: PlatformHistory,
            },
            {
                path: "company/history",
                component: CompanyFormHistory,
            },
            {
                path: "user/app/history",
                component: AppFormHistory,
            },
            {
                path: "user/app/d/history",
                component: AppFormEverydayHistory,
            },
            {
                path: "query",
                component: PayQuery,
            },
        ]
    }
];



export const AdminUserRouter = [
    {
        path: '/dashboard/user',
        redirect: "/dashboard/user/manage",
        component: Dashboard,
        children: [{
            path: "manage",
            component: Users,
        }, {
            path: "signup",
            component: Signup,
        }, {
            path: "contact",
            component: Contact,
        }]
    },
];


export const AdminIssueRouter = [
    {
        path: '/dashboard/docs',
        redirect: "/dashboard/docs/document",
        component: Dashboard,
        children: [{
            path: "document",
            component: JoinDoc,
        }, {
            path: "sdk",
            component: JoinFile,
        }]
    }
];

const routes = [
    {
        path:"/",
        redirect:"/login",
    },
    {
        // 登陆页面
        path: '/login',
        component: UserLogin
    },
    {
        // 404
        path: '/:pathMatch(.*)',
        component: E404,
    },
    ...AdminHomeRouter,
    ...AdminSystemRouter,
    ...AdminMediaRouter,
    ...AdminFinancialRouter,
    ...AdminSettlementRouter,
    ...AdminUserRouter,
    ...AdminIssueRouter

]


const router = createRouter({
    history: createWebHashHistory(),
    routes
})
export default router