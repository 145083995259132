<template>
    <el-card class="doc">
        <div class="op">
            <el-select v-model="sdkSystem" placeholder="请选择系统" @change="update">
                <el-option label="Android" value="android"/>
                <el-option label="IOS" value="ios"/>
            </el-select>
            <div>
                <el-input class="title" placeholder="请输入标题" v-model="title"/> &nbsp;
                <el-button class="handleClickIssue" type="primary" @click="handleClickIssue" size="small">发布</el-button>
            </div>
        </div>
        <div class="edit">
            <el-input class="input"
                      type="textarea"
                      placeholder="请输入内容"
                      resize="none"
                      rows="30"
                      v-model="content"/>
            <VueShowdown class="preview"
                         :markdown="content"
                         flavor="github"
                         :options="{ emoji: true, simpleLineBreaks: true}"
                         :extensions="[showdownHighlight]"/>
        </div>
    </el-card>
</template>

<script>

import { VueShowdown } from 'vue-showdown'
import showdownHighlight from "showdown-highlight";
import 'highlight.js/styles/atom-one-dark.css';
import * as api from "../../api/api"
import {CODE_OK} from "../../api/request"

export default {
    name: "JoinDoc",

    components: {VueShowdown},

    data: function () {
        return {
            showdownHighlight: showdownHighlight({pre: true}),
            sdkSystem: "android",
            title: "",
            content: "",
        }
    },

    mounted() {
        this.update();
    },

    methods: {
        update: function () {
            const {sdkSystem} = this;

            this.title = "";
            this.content = "";

            api.getIssueJoinSdkDocument(sdkSystem).then(result => {
                if (result.code !== CODE_OK) {
                    this.$message.error("提交SDK文档失败, Err: " + result.msg)
                    return
                }
                this.content = result.data.content;
                this.title = result.data.title;
            }, err => {
                this.$message.error("获取文档失败, Err: " + err)
            })
        },


        handleClickIssue: function () {
            const { sdkSystem, title, content} = this;
            if (title.length <= 0 ) {
                this.$message.warning("请输入标题")
                return
            }

            if (content.length <= 0 ) {
                this.$message.warning("请输入内容")
                return
            }

            if (sdkSystem.length <= 0 ) {
                this.$message.warning("请输入SDK所属系统")
                return
            }


            api.postIssueJoinSdkDocument(title, content, sdkSystem).then(result => {
                if (result.code !== CODE_OK) {
                    this.$message.error("提交SDK文档失败, Err: " + result.msg)
                    return
                }
                this.$message.success("提交成功")
            }, err => {
                this.$message.error("提交SDK文档失败, Err: " + err)
            })
        }
    }
}
</script>

<style scoped>

.doc {
    min-height: 100%;
}

.doc .edit {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 20px;
}

.doc .edit .input {
    width: 50%;
}

.doc .edit .preview {
    width: 50%;
    margin: 0 0 0 20px;
    background-color: #fafbfc;
    border-radius: 5px;
    border: 1px solid #eaecef;
    padding: 10px 15px;
    word-break: break-word;
    height: 620px;
    overflow-y: auto;
    overflow-x: hidden;
}

.doc .op > * {
    margin-bottom: 20px;
}

.doc .op > div:last-child {
    display: flex;
}
</style>