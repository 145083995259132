<template>
    <div class="home">
        <el-card class="card_group">
          <template #header>预估收益</template>
            <div class="content">
                <card class="card">
                    <div> 昨日收益（元） </div>
                    <div class="content">
                        <div class="income" v-if="yesterOk">{{yesterRevenueCount}}</div>
                        <div class="income hidden" v-else>{{yesterRevenueCount}}</div>

                        <div class="trend" v-if="yesterOk">环比&nbsp;<span>{{yesterRevenueCircle}}</span></div>
                        <div class="trend hidden" v-else>环比&nbsp;<span>{{yesterRevenueCircle}}</span></div>
                    </div>
                </card>
                <card class="card">
                  <div>
                  <span>近七日收益（元）</span>
                  <el-tooltip popper-class="custom-tooltip" placement="bottom" content="从昨日算起，往前推七天。环比指的是，上一个七天周期。<br/>
                                即以昨日为起点，往前七天为第一周期，再往前七天为第二周期。进行环比。<br/>
                                环比 = （第一七日周期 - 第二七日周期）/ 第二七日周期 * 100%">
                    <el-icon><QuestionFilled /></el-icon>
                  </el-tooltip>
                    </div>
                    <div class="content">
                        <div class="income">{{weekRevenueCount}}</div>
                        <div class="trend">环比&nbsp;<span>{{weekRevenueCircle}}</span></div>
                    </div>
                </card>
                <card class="card">
                    <div>
                        <span>本月收益（元）</span>
                      <el-tooltip popper-class="custom-tooltip" placement="bottom" content=" 从本月一号起算，算到昨日起，假若是当月1号，则本月收益是上个月一整月份的收益。<br/>
                          环比（按天数对比，如目前十五号，则取上个月的前十五天。假若遇到大小月份，则取上个月整月数据。<br/>
                          如 本月当前为3月30号，则取二月份28天为整个月的收益作为对比对象。）<br>
                          环比率 = （本月收益 - 上月收益）/ 上月收益 * 100%">
                        <el-icon><QuestionFilled /></el-icon>
                      </el-tooltip>

                    </div>
                    <div class="content">
                        <div class="income">{{monthRevenueCount}}</div>
                        <div class="trend">环比&nbsp;<span>{{monthRevenueCircle}}</span></div>
                    </div>
                </card>

                <card class="card">
                    <div>
                        <span>昨日ECPM（元）</span>
                        <el-tooltip content="ecpm = 预估收益 * 1000/曝光量">
                            <el-icon class="el-icon-question"/>
                        </el-tooltip>

                    </div>
                    <div class="content">
                        <div class="income" v-if="yesterOk">{{yesterEcpmAvg}}</div>
                        <div class="income hidden" v-else>{{yesterEcpmAvg}}</div>

                        <div class="trend" v-if="yesterOk">环比&nbsp;<span>{{yesterEcpmCircle}}</span></div>
                        <div class="trend hidden" v-else>环比&nbsp;<span>{{yesterEcpmCircle}}</span></div>
                    </div>
                </card>
            </div>
        </el-card>

        <el-card class="card_group">
            <template #header>
              <span>数据预览(上游)</span>
              <div class="options">

                <el-select v-model="realClientScene" size="mini" style="width: 200px">
                  <el-option :value="0" label="全部" />
                  <el-option :value="3" label="激励视频" />
                  <el-option :value="4" label="横幅" />
                  <el-option :value="2" label="信息流" />
                  <el-option :value="5" label="插屏" />
                </el-select>&nbsp;
                <el-select class="op" size="mini" v-model="realClientTimeScope" @change="handleChangeClientReal">
                  <el-option :value="1" label="昨天"/>
                  <el-option :value="7" label="近7天"/>
                  <el-option :value="30" label="近30天"/>
                </el-select>
              </div>
            </template>
            <div class="content">
                <card class="card" color="#d3fcf2">
                    <div> 曝光量 </div>
                    <div class="content">
                        <div class="quantity">{{ realClient[realClientScene].pvCount }}</div>
                        <div class="trend">环比&nbsp;<span>{{ realClient[realClientScene].pvCountCircle }}</span></div>
                    </div>
                </card>
                <card class="card" color="#d3fcf2">
                    <div>
                        <span>曝光率</span>
                    </div>
                    <div class="content">
                        <div class="quantity">{{ realClient[realClientScene].pvRate }}</div>
                        <div class="trend">环比&nbsp;<span>{{ realClient[realClientScene].pvRateCircle }}</span></div>
                    </div>
                </card>

                <card class="card" color="#d3fcf2">
                    <div> EPCM </div>
                    <div class="content">
                        <div class="quantity">{{ (realClient[realClientScene].ecpm / 100).toFixed(2) }}</div>
                        <div class="trend">环比&nbsp;<span>{{ realClient[realClientScene].ecpmCircle }}</span></div>
                    </div>
                </card>
            </div>
        </el-card>

        <el-card class="card_group">
          <template #header>
            <span>数据预览</span>
            <div class="options">
              <el-select v-model="clientScene" size="mini" @change="handleChangeClient" style="width: 200px">
                <el-option :value="0" label="全部" />
                <el-option :value="2" label="信息流" />
                <el-option :value="3" label="激励视频" />
                <el-option :value="4" label="横幅" />
                <el-option :value="5" label="插屏" />
              </el-select>&nbsp;
              <el-select class="op" size="mini" v-model="clientTimeScope" @change="handleChangeClient">
                <el-option :value="1" label="昨天"/>
                <el-option :value="7" label="近7天"/>
                <el-option :value="30" label="近30天"/>
              </el-select>
            </div>
          </template>
            <div class="content">
                <card class="card" color="#d1f9af">
                  <div> SDK初始化 </div>
                    <div class="content">
                        <div class="quantity">{{ client[clientScene].initCount }}</div>
                        <div class="trend">环比&nbsp;<span>{{client[clientScene].initCountCircle}}</span></div>
                    </div>
                </card>
                <card class="card" color="#d1f9af">
                    <div> 曝光量 </div>
                    <div class="content">
                        <div class="quantity">{{ client[clientScene].pvCount }}</div>
                        <div class="trend">环比&nbsp;<span>{{client[clientScene].pvCountCircle}}</span></div>
                    </div>
                </card>
                <card class="card" color="#d1f9af">
                    <div>

                        <span>曝光率</span>

                    </div>
                    <div class="content">
                        <div class="quantity">{{ client[clientScene].pvRate }}</div>
                        <div class="trend">环比&nbsp;<span>{{client[clientScene].pvRateCircle}}</span></div>
                    </div>
                </card>

                <card class="card" color="#d1f9af">
                    <div> EPCM </div>
                    <div class="content">
                        <div class="quantity">{{(client[clientScene].ecpm / 100).toFixed(2)}}</div>
                        <div class="trend">环比&nbsp;<span>{{client[clientScene].ecpmCircle}}</span></div>
                    </div>
                </card>
            </div>
        </el-card>

        <el-card class="card_group">
          <template #header>收益统计</template>
          <div class="content">
            <card class="card" color="#9cedfa">
              <div>季度收益（元）</div>
              <div class="content">
                <div class="income">{{quarterlyRevenue}}</div>
              </div>
            </card>

            <card class="card" color="#9cedfa">
              <div>激励季度收益（元）</div>
              <div class="content">
                <div class="income">{{quarterlyRewardRevenue}}</div>
              </div>
            </card>

                <card class="card" color="#9cedfa">
                    <div>横幅季度收益 (元)</div>
                    <div class="content">
                        <div class="income">{{quarterlyBannerRevenue}}</div>
                    </div>
                </card>
            </div>
        </el-card>

        <el-card class="card_group graph">
          <template #header>数据趋势</template>
            <div class="filter">
                <el-form class="filter-form" inline>
                    <el-form-item class="form-item" label="日期">
                        <el-date-picker
                            v-model="graph.dateRange"
                            @change="handleChangeGraphDateRange"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item class="form-item" label="场景" >
                        <el-select v-model="graph.scene" @change="drawGraph" style="width: 150px">
                            <template v-for="(item, i) of graph.sceneItems" :key="i">
                                <el-option  :value="item.value" :label="item.name"/>
                            </template>
                        </el-select>
                    </el-form-item>

                    <el-form-item class="form-item" label="渠道">
                        <el-select v-model="graph.ch" @change="drawGraph" style="width: 150px">
                            <template v-for="(item, i) of graph.chItems" :key="i">
                                <el-option  :value="item.value" :label="item.name"/>
                            </template>
                        </el-select>
                    </el-form-item>

                    <el-form-item class="form-item">
                        <el-checkbox v-model="graph.isCompared" @change="drawGraph">对比</el-checkbox>
                    </el-form-item>

                    <el-form-item class="form-item" v-if="graph.isCompared">
                        <el-select v-model="graph.comparedCh" @change="drawGraph" style="width: 150px">
                            <template v-for="(item, i) of graph.chItems" :key="i">
                                <el-option  :value="item.value" :label="item.name"/>
                            </template>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div id="graph-container" :style="{width: 'auto', height: '500px'}"/>
            <div class="footer">
                <refer href="/#/dashboard/financial/platform">查看更多数据</refer>
            </div>
        </el-card>
    </div>
</template>

<script>
import * as echarts from "echarts"
import moment from 'moment'
import * as api from "../api/api";
import {CODE_OK} from "../api/request";
import {unixFormatter} from "../util/format";
import Card from "../component/Card.vue";
import refer from "../component/Refer.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
    name: "home",

    components: {Card, refer},

    data: function () {
        return {
            yesterOk: false,
            monthRevenueCircle: "0.00%",
            monthRevenueCount: 0,
            weekRevenueCircle: "0.00%",
            weekRevenueCount: 0,
            yesterEcpmAvg: 0,
            yesterEcpmCircle: "0.00%",
            yesterRevenueCircle: "0.00%",
            yesterRevenueCount: 0,
            clientScene: 3,
            clientTimeScope: 1,
            client: {
                0: {
                    initCount: 0,
                    initCountCircle: "0%",
                    pvCount: 0,
                    pvCountCircle: "0%",
                    pvRate: "0%",
                    pvRateCircle: "0%",
                    ecpm: 0,
                    ecpmCircle: "0%"
                },
                2: {
                    initCount: 0,
                    initCountCircle: "0%",
                    pvCount: 0,
                    pvCountCircle: "0%",
                    pvRate: "0%",
                    pvRateCircle: "0%",
                    ecpm: 0,
                    ecpmCircle: "0%"
                },
                3: {
                    initCount: 0,
                    initCountCircle: "0%",
                    pvCount: 0,
                    pvCountCircle: "0%",
                    pvRate: "0%",
                    pvRateCircle: "0%",
                    ecpm: 0,
                    ecpmCircle: "0%"
                },
                4: {
                    initCount: 0,
                    initCountCircle: "0%",
                    pvCount: 0,
                    pvCountCircle: "0%",
                    pvRate: "0%",
                    pvRateCircle: "0%",
                    ecpm: 0,
                    ecpmCircle: "0%"
                }
            },
            realClientScene: 3,
            realClientTimeScope: 1,
            realClient: {
                0: {
                    pvCount: 0,
                    pvCountCircle: "0%",
                    pvRate: "0%",
                    pvRateCircle: "0%",
                    ecpm: 0,
                    ecpmCircle: "0%"
                },
                2:{
                    pvCount: 0,
                    pvCountCircle: "0%",
                    pvRate: "0%",
                    pvRateCircle: "0%",
                    ecpm: 0,
                    ecpmCircle: "0%"
                },
                3: {
                    pvCount: 0,
                    pvCountCircle: "0%",
                    pvRate: "0%",
                    pvRateCircle: "0%",
                    ecpm: 0,
                    ecpmCircle: "0%"
                },
                4: {
                    pvCount: 0,
                    pvCountCircle: "0%",
                    pvRate: "0%",
                    pvRateCircle: "0%",
                    ecpm: 0,
                    ecpmCircle: "0%"
                }
            },
            quarterlyRevenue: 0,
            quarterlyRewardRevenue: 0,
            quarterlyBannerRevenue: 0,

            graph: {
                dateRange: [],
                scene: 0,
                sceneItems: [
                    {name: "全部类型", value: 0},
                    {name: "信息流", value: 2},
                    {name: "开屏", value: 1},
                    {name: "激励视频", value: 3},
                    {name: "横幅", value: 4},
                    {name: "插屏", value: 5},
                ],

                ch: 0,
                chItems: [
                    {name: "全部渠道", value: 0},
                    {name: "优量汇", value: 1},
                    {name: "快手", value: 4},
                    {name: "Gromore", value: 5}
                ],

                isCompared: false,
                comparedCh: 0,
                items: {},
            }
        }
    },

    mounted() {
        this.update()

        const now = new Date();
        const nowUint = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
        const end = moment(nowUint).clone().subtract(1, "day").toDate()
        const start = moment(nowUint).clone().subtract(8, "day").toDate()
        this.graph.dateRange = [start, end]

        this.graphElement = echarts.init(document.getElementById("graph-container"))
    },

    methods: {
        update: function () {
            api.getDashboard().then(result => {
                if (parseInt(result.code) !== CODE_OK) {
                    this.$message.error("请求失败， Err: " + result.msg)
                    return
                }
                const {data} = result;
                this.yesterOk = data.yester_ok;

                this.client[0].initCount = data.init_count;
                this.client[0].initCountCircle =  data.init_count_circle;
                this.client[0].pvCount = data.pv_count;
                this.client[0].pvCountCircle = data.pv_count_circle;
                this.client[0].pvRate = data.pv_rate;
                this.client[0].pvRateCircle = data.pv_rate_circle;
                this.client[0].ecpm = data.ecpm;
                this.client[0].ecpmCircle = data.ecpm_circle;

                this.client[2].initCount = data.init_count;
                this.client[2].initCountCircle =  data.init_count_circle;
                this.client[2].pvCount = data.native_pv_count;
                this.client[2].pvCountCircle = data.native_pv_count_circle;
                this.client[2].pvRate = data.native_pv_rate;
                this.client[2].pvRateCircle = data.native_pv_rate_circle;
                this.client[2].ecpm = data.native_ecpm;
                this.client[2].ecpmCircle = data.native_ecpm_circle;

                this.client[3].initCount = data.init_count;
                this.client[3].initCountCircle =  data.init_count_circle;
                this.client[3].pvCount = data.reward_pv_count;
                this.client[3].pvCountCircle = data.reward_pv_count_circle;
                this.client[3].pvRate = data.reward_pv_rate;
                this.client[3].pvRateCircle = data.reward_pv_rate_circle;
                this.client[3].ecpm = data.reward_ecpm;
                this.client[3].ecpmCircle = data.reward_ecpm_circle;

                this.client[4].initCount = data.init_count;
                this.client[4].initCountCircle =  data.init_count_circle;
                this.client[4].pvCount = data.banner_pv_count;
                this.client[4].pvCountCircle = data.banner_pv_count_circle;
                this.client[4].pvRate = data.banner_pv_rate;
                this.client[4].pvRateCircle = data.banner_pv_rate_circle;
                this.client[4].ecpm = data.banner_ecpm;
                this.client[4].ecpmCircle = data.banner_ecpm_circle;

                this.realClient[0].pvCount = data.real_pv_count;
                this.realClient[0].pvCountCircle = data.real_pv_count_circle;
                this.realClient[0].pvRate = data.real_pv_rate;
                this.realClient[0].pvRateCircle = data.real_pv_rate_circle;
                this.realClient[0].ecpm = data.real_ecpm;
                this.realClient[0].ecpmCircle = data.real_ecpm_circle;

                this.realClient[2].pvCount = data.real_native_pv_count;
                this.realClient[2].pvCountCircle = data.real_native_pv_count_circle;
                this.realClient[2].pvRate = data.real_native_pv_rate;
                this.realClient[2].pvRateCircle = data.real_native_pv_rate_circle;
                this.realClient[2].ecpm = data.real_native_ecpm;
                this.realClient[2].ecpmCircle = data.real_native_ecpm_circle;

                this.realClient[3].pvCount = data.real_reward_pv_count;
                this.realClient[3].pvCountCircle = data.real_reward_pv_count_circle;
                this.realClient[3].pvRate = data.real_reward_pv_rate;
                this.realClient[3].pvRateCircle = data.real_reward_pv_rate_circle;
                this.realClient[3].ecpm = data.real_reward_ecpm;
                this.realClient[3].ecpmCircle = data.real_reward_ecpm_circle;

                this.realClient[4].pvCount = data.real_banner_pv_count;
                this.realClient[4].pvCountCircle = data.real_banner_pv_count_circle;
                this.realClient[4].pvRate = data.real_banner_pv_rate;
                this.realClient[4].pvRateCircle = data.real_banner_pv_rate_circle;
                this.realClient[4].ecpm = data.real_banner_ecpm;
                this.realClient[4].ecpmCircle = data.real_banner_ecpm_circle;

                this.monthRevenueCircle = data.month_revenue_circle;
                this.monthRevenueCount = data.month_revenue_count / 100;
                this.weekRevenueCircle = data.week_revenue_circle;
                this.weekRevenueCount = data.week_revenue_count / 100;
                this.yesterEcpmAvg = data.yester_ecpm_avg / 100;
                this.yesterEcpmCircle =  data.yester_ecpm_circle;
                this.yesterRevenueCircle = data.yester_revenue_circle;
                this.yesterRevenueCount = data.yester_revenue_count / 100;
                this.quarterlyRevenue = data.quarterly_revenue / 100;
                this.quarterlyRewardRevenue = data.quarterly_reward_revenue / 100;
                this.quarterlyBannerRevenue = data.quarterly_banner_revenue / 100;

                // 构建曲线数据
                this.graph.items = this.buildGraph(data.earnings_graph.items)
                this.drawGraph();
            }, err => {
                this.$message.error("请求失败, Err: " + err)
            })
        },

        handleChangeClient: function () {
            const {clientTimeScope, clientScene} = this;
            api.getDashboardClient(clientTimeScope, clientScene).then(result => {
                if (parseInt(result.code) !== CODE_OK) {
                    this.$message.error("请求失败， Err: " + result.msg)
                    return
                }
                const {data} = result;
                this.client[0].initCount = data.init_count;
                this.client[0].initCountCircle =  data.init_count_circle;
                this.client[0].pvCount = data.pv_count;
                this.client[0].pvCountCircle = data.pv_count_circle;
                this.client[0].pvRate = data.pv_rate;
                this.client[0].pvRateCircle = data.pv_rate_circle;
                this.client[0].ecpm = data.ecpm;
                this.client[0].ecpmCircle = data.ecpm_circle;

                this.client[2].initCount = data.init_count;
                this.client[2].initCountCircle =  data.init_count_circle;
                this.client[2].pvCount = data.native_pv_count;
                this.client[2].pvCountCircle = data.native_pv_count_circle;
                this.client[2].pvRate = data.native_pv_rate;
                this.client[2].pvRateCircle = data.native_pv_rate_circle;
                this.client[2].ecpm = data.native_ecpm;
                this.client[2].ecpmCircle = data.native_ecpm_circle;

                this.client[3].initCount = data.init_count;
                this.client[3].initCountCircle =  data.init_count_circle;
                this.client[3].pvCount = data.reward_pv_count;
                this.client[3].pvCountCircle = data.reward_pv_count_circle;
                this.client[3].pvRate = data.reward_pv_rate;
                this.client[3].pvRateCircle = data.reward_pv_rate_circle;
                this.client[3].ecpm = data.reward_ecpm;
                this.client[3].ecpmCircle = data.reward_ecpm_circle;

                this.client[4].initCount = data.init_count;
                this.client[4].initCountCircle =  data.init_count_circle;
                this.client[4].pvCount = data.banner_pv_count;
                this.client[4].pvCountCircle = data.banner_pv_count_circle;
                this.client[4].pvRate = data.banner_pv_rate;
                this.client[4].pvRateCircle = data.banner_pv_rate_circle;
                this.client[4].ecpm = data.banner_ecpm;
                this.client[4].ecpmCircle = data.banner_ecpm_circle;
            }, err => {
                this.$message.error("请求失败, Err: " + err)
            })
        },

        handleChangeClientReal: function () {
            const {realClientTimeScope, realClientScene} = this;
            api.getDashboardClientReal(realClientTimeScope, realClientScene).then(result => {
                if (parseInt(result.code) !== CODE_OK) {
                    this.$message.error("请求失败， Err: " + result.msg)
                    return
                }
                const {data} = result;
                this.realClient[0].pvCount = data.pv_count;
                this.realClient[0].pvCountCircle = data.pv_count_circle;
                this.realClient[0].pvRate = data.pv_rate;
                this.realClient[0].pvRateCircle = data.pv_rate_circle;
                this.realClient[0].ecpm = data.ecpm;
                this.realClient[0].ecpmCircle = data.ecpm_circle;

                this.realClient[3].pvCount = data.reward_pv_count;
                this.realClient[3].pvCountCircle = data.reward_pv_count_circle;
                this.realClient[3].pvRate = data.reward_pv_rate;
                this.realClient[3].pvRateCircle = data.reward_pv_rate_circle;
                this.realClient[3].ecpm = data.reward_ecpm;
                this.realClient[3].ecpmCircle = data.reward_ecpm_circle;

                this.realClient[4].pvCount = data.banner_pv_count;
                this.realClient[4].pvCountCircle = data.banner_pv_count_circle;
                this.realClient[4].pvRate = data.banner_pv_rate;
                this.realClient[4].pvRateCircle = data.banner_pv_rate_circle;
                this.realClient[4].ecpm = data.banner_ecpm;
                this.realClient[4].ecpmCircle = data.banner_ecpm_circle;
            }, err => {
                this.$message.error("请求失败, Err: " + err)
            })
        },


        updateGraph: function () {
            const {dateRange} = this.graph
            api.getDashboardGraph(parseInt(dateRange[0].getTime() / 1000), parseInt(dateRange[1].getTime() / 1000)).then(result => {
                if (parseInt(result.code) !== CODE_OK) {
                    this.$message.error("请求失败， Err: " + result.msg)
                    return
                }
                this.graph.items = this.buildGraph(result.data)
                this.drawGraph();
            }, err => {
                this.$message.error("请求失败, Err: " + err)
            });
        },

        drawGraph: function () {
            const {isCompared, items} = this.graph;
            const {x, y} = items[this.graph.scene][this.graph.ch]
            let option = {};

            const findName = (key) => {
                let name = "";
                for (let opt of this.graph.chItems) {
                    if (opt.value === key) {
                        name = opt.name;
                    }
                }
                return name;
            };

            const name = findName(this.graph.ch);
            let comparedName = findName(this.graph.comparedCh);

            if (isCompared) {
                const {y: comparedY} = items[this.graph.scene][this.graph.comparedCh]

                option = {
                    color: ["#f56c6c", "#c0f1a5"],
                    tooltip: {
                        trigger: 'axis'
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: x
                        }
                    ],
                    yAxis: {
                        type: 'value',
                        name: name,
                        axisLine: {
                            show: true,
                        },
                    },
                    series: [
                        {
                            data: y,
                            name: name,
                            type: 'line',
                            smooth: true,
                            emphasis: {
                                focus: 'series'
                            },
                        }, {
                            data: comparedY,
                            name: comparedName,
                            type: 'line',
                            smooth: true,
                            emphasis: {
                                focus: 'series'
                            },
                        }
                    ]
                }
            } else {
                option = {
                    color: ["#f56c6c"],
                    tooltip: {
                        trigger: 'axis'
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: x
                        }
                    ],
                    yAxis: {
                        type: 'value',
                        name: name,
                        axisLine: {
                            show: true,
                        },
                    },
                    series: [
                        {
                            data: y,
                            type: 'line',
                            smooth: true,
                            emphasis: {
                                focus: 'series'
                            },
                        }
                    ]
                }
            }

            this.graphElement.setOption(option, {notMerge: true})
        },

        buildGraph: function (items) {
            const graphItems = {};
            for (let scene of this.graph.sceneItems) {
                this.graph.items[scene] = {}
                for (let ch of this.graph.chItems) {
                    let graph = {}
                    const addGraph = (data) => {
                        if (graph[data.date] === undefined) {
                            graph[data.date] = data.revenue / 100;
                        } else {
                            graph[data.date] += data.revenue / 100;
                        }
                    }

                    // placeholder date
                    for (let item of items) {
                        graph[item.date] = 0;
                    }

                    for (let item of items) {
                        if (ch.value === 0 || scene.value === 0) {
                            if (scene.value === 0 && item.channel === ch.value) {
                                addGraph(item);
                            } else if (ch.value === 0 && item.scene === scene.value) {
                                addGraph(item)
                            } else if (ch.value === 0 && scene.value === 0) {
                                addGraph(item)
                            }
                        } else if (item.channel === ch.value && item.scene === scene.value) {
                            addGraph(item)
                        }
                    }

                    let graphX = [];
                    let graphY = [];
                    const keys = Object.keys(graph).sort()
                    for (let key of keys) {
                        const tm = unixFormatter(parseInt(key), 'YYYY-MM-DD')
                        graphX.push(tm);
                        graphY.push(graph[key].toFixed(2)); // 保留两位小数
                    }
                    if (graphItems[scene.value] === undefined) {
                        graphItems[scene.value] = {}
                    }
                    graphItems[scene.value][ch.value] = {x: graphX, y: graphY};
                }
            }
            return graphItems;
        },

        handleChangeGraphDateRange: function () {
            this.updateGraph();
        },
    }
}
</script>

<style>
/* 在这里引入你的自定义样式 */
.custom-tooltip {
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  padding: 10px;
  font-size: 12px;
  width: 50%;
}
</style>
<style scoped>

.home {
    min-height: 100%;
}

.home .graph {
    margin-top: 20px;
}

.home .card_group:not(:first-child) {
    margin-top: 20px;
}


.home .card_group .options {
    display: flex;
    float: right;
    align-items: center;
}

.home .card_group .content {
    display: flex;
    justify-content: left;
}

.home .card_group .content .card {
    width: 220px;
}

.home .card_group .content .card .content {
    display: flex;
    flex-direction: column;
}

.home .card_group .content .card .content .income {
    font-size: 28px;
    text-align: left;
    color: #1f1f1f;
    line-height: 28px;
    font-weight: 700;
    margin-top: 12px;
    margin-bottom: 10px;
}

.home .card_group .content .card .content .income.hidden {
    color: #3333;
}

.home .card_group .content .card .content .trend {
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    color: #8c8c8c;
    line-height: 17px;
}

.home .card_group .content .card .content .trend.hidden {
    color: #3333;
}

.home .card_group .content .card .content .quantity {
    font-size: 28px;
    text-align: left;
    color: #1f1f1f;
    line-height: 28px;
    font-weight: 700;
    margin-top: 12px;
    margin-bottom: 10px;
}

.home .card_group .op {
    float: right;
    padding: 3px 0;
}

.home .card_group .content .card:not(:first-child) {
    margin-left: 20px;
}

.home .card_group.graph .filter {
    display: flex;
    justify-content: right;
}

.home .card_group.graph .footer {
    display: flex;
    justify-content: center;
}

.home .card_group.graph .filter .form-item {
  margin-left: -20px; /* 设置表单项之间的间距 */
}


</style>