<template>
    <div class="step2">
        <el-card class="card filter">
            <div class="content">
                <el-form inline>
                    <el-form-item label="账户名称">
                        <el-input v-model="filter.search" placeholder="请输入内容"/>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model="filter.status">
                            <el-option :value="0" label="全部"/>
                            <el-option :value="1" label="未结算"/>
                            <el-option :value="2" label="已结算"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="update">搜索</el-button>
                    </el-form-item>
                </el-form>
                <div class="placeholder"/>

                <span>未结算账户(&nbsp;<span style="color: #f56c6c">{{untreatedCount}}</span>&nbsp;)</span> &nbsp;&nbsp;
                <el-button type="primary" @click="handleClickComplete" :disabled="untreatedCount > 0 || unfinalCount >0">结算完成</el-button>
            </div>
        </el-card>

        <el-card class="card statistics">
            <div class="body">
                <statistics label="上游收益￥" :value="sumPlatformRevenue / 100"/>
                <statistics label="分成收益￥" :value="(sumRevenue - sumPureRevenue) / 100"/>
                <statistics label="结余￥" :value="(sumPlatformRevenue - sumRevenue) / 100"/>
            </div>
        </el-card>

        <el-card class="card table">
            <div class="content">
                <el-table :data="data"
                          show-summary
                          :summary-method="handleSummary">
                    <el-table-column label="结算时间" align="center">
                        <template v-slot:default="scope">
                            <span>{{unixFormatter(scope.row.date, 'YYYY-MM')}}</span>
                        </template>
                    </el-table-column>

                    <el-table-column prop="user_id" label="账户ID" align="center"/>

                    <el-table-column prop="username" label="账户名" align="center"/>

                    <el-table-column prop="est_revenue" label="预估收益" align="center"/>

                    <el-table-column label="分成比例" align="center">
                        <template v-slot:default="scope">
                            <span>{{scope.row.share_ratio}} %</span>
                        </template>
                    </el-table-column>

                    <el-table-column prop="pure_revenue" label="分成收益" align="center"/>

                    <el-table-column label="状态" align="center">
                        <template v-slot:default="scope">
                            <tag v-if="scope.row.status===1">未结算</tag>
                            <tag v-if="scope.row.status===2" color="#67C23A">已结算</tag>
                        </template>
                    </el-table-column>

                    <el-table-column label="操作" align="center">
                        <template v-slot:default="scope">
                            <template v-if="!scope.row.is_gen_final">
                                <el-button v-if="scope.row.status===1" type="text"
                                           @click="handleClickDeal(scope.row.id, scope.row.username)">结算</el-button>
                                <el-button v-if="scope.row.status===2" type="text"
                                           @click="handleClickDeal(scope.row.id, scope.row.username)">重结</el-button>
                            </template>
                            <template v-else>
                                <el-button type="text" @click="handleClickDeal(scope.row.id, scope.row.username)">明细</el-button>
                            </template>
                        </template>
                    </el-table-column>

                </el-table>
                <div class="footer">
                    <el-pagination
                        class="pagination"
                        background
                        layout="prev, pager, next"
                        @current-change="update"
                        v-model:current-page="currentPage"
                        :page-size="pageSize"
                        :total="pageCount">
                    </el-pagination>
                </div>
            </div>
        </el-card>
    </div>
</template>

<script>
import moment from "moment";
import * as api from "../../api/api";
import {CODE_OK} from "../../api/request";
import {unixFormatter} from "../../util/format";
import Tag from "../../component/Tag.vue";
import Statistics from "../../component/Statistics.vue";
import Routers from "../../router/"

export default {
    name: "CenterPart2",

    components: {Tag, Statistics},

    props: {
        date: {
            type: Number,
            required: true,
        }
    },

    data: function () {
        return {
            filter: {
                search: "",
                status: 0,
            },
            data: [],
            sumRevenue: 0,
            sumPureRevenue: 0,
            sumPlatformRevenue: 0,
            unfinalCount: 0,
            untreatedCount: 0,
            pageSize: 8,
            pageCount: 0,
            currentPage: 1,
        }
    },

    mounted() {
        this.initView();
    },

    methods: {
        unixFormatter,

        initView: function () {
           this.update()
        },

        update: function () {
            const {currentPage, pageSize, filter, date} = this;
            const {search, status} = filter;
            const dateStart = moment(date).startOf('month').unix()
            const dateEnd = moment(date).endOf('month').unix()
            api.getSettlementUserReport(currentPage, pageSize, dateStart, dateEnd, status, search).then(result => {
                if (parseInt(result.code) !== CODE_OK) {
                    this.$message.error("获取上游核算列表失败， Err: " + result.msg)
                    return
                }
                this.data = result.data.list.map((item)=> {
                    item.est_revenue = (item.est_revenue/100).toFixed(2);
                    item.pure_revenue = (item.pure_revenue/100).toFixed(2);
                    return item;
                });
                this.sumRevenue = result.data.sum_revenue;
                this.sumPureRevenue = result.data.sum_pure_revenue;
                this.sumPlatformRevenue = result.data.sum_platform_revenue;
                this.untreatedCount = result.data.untreated_count;
                this.unfinalCount = result.data.unfinal_count;
                this.pageCount = result.data.count;
            }, err => {
                this.$message.error("获取上游核算列表失败, Err: " + err)
            })
        },

        handleSummary: function () {
            const {sumRevenue, sumPureRevenue} = this;
            return ["总计", "-", "-", sumRevenue / 100, "-", sumPureRevenue / 100, "-", "-"]
        },

        handleClickDeal: function (id, name) {
            Routers.push({
                path: "/dashboard/settlement/current/user/app/form",
                query: {
                    id: id,
                    username: name,
                    date: this.date,
                },
            })
        },

        handleClickComplete: function () {
            this.$emit("complete", 2)
        },
    }
}
</script>

<style scoped>

.card {
    margin-bottom: 20px;
}

.card:last-child {
    margin-bottom: 0;
}

.card.filter .content {
    display: flex;
    align-items: center;
}

.card.filter .content .placeholder {
    flex-grow: 1;
}

.card.filter .content .el-form-item {
    margin-bottom: 0 !important;
}

.card.statistics .body {
    display: flex;
}

.card.table .content .footer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.card.table >>> .el-table .el-table__body-wrapper {
    order: 1 !important;
}

.card.table >>> .el-table__fixed-body-wrapper{
    top:96px !important;
}

.card.table >>> .el-table__fixed-footer-wrapper{
    z-index: 0;
    top:47px;
}

</style>