<template>
    <el-container class="framework">
        <el-container>
            <aside-menu @select="handleSelect"/>
            <el-main class="page" id="page">
                <breadcrumb id="breadcrumb" :routes="routes"/>
<!--              :style="{maxHeight: height, height: height}"-->
                <div class="view" ref="view" >
                    <router-view/>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
import AsideMenu from "../component/AsideMenu.vue";
import Breadcrumb from "../component/Breadcrumb.vue"

export default {
  // eslint-disable-next-line vue/multi-word-component-names
    name: "framework",
    components: {AsideMenu, Breadcrumb},

    data: function () {
        return {
            timer: false,
            height: "0px",
            routes: [],
        }
    },

    mounted() {
        this.height =  this.viewHeight()
        window.addEventListener("resize", this.resize);
    },

    beforeUnmount() {
        window.removeEventListener("resize", this.resize)
    },

    methods: {

        handleSelect: function (index, routes) {
            this.routes = routes
        },

        viewHeight: function () {
            const breadcrumb = document.querySelector(".breadcrumb")
            const body = window.document.body
            return body.clientHeight - breadcrumb.clientHeight - 32 + "px"
        },

        resize: function () {
            this.height = this.viewHeight();
        }
    },

    watch: {
        height: function (val) {
            if (!this.timer) {
                this.height = val;
                this.timer = true;
                let that = this;
                setTimeout(function () {
                    that.timer = false
                }, 400)
            }
        }
    }
}
</script>

<style scoped>

.framework {
    overflow: hidden;
    overflow-x: auto;
}

.framework >>> .page {
    background-color: #F5F7F9;

    padding: 0;
    //overflow: hidden;
    min-width: 1000px;
}

.framework >>> .page > .view {
    padding: 16px;
    overflow-y: auto;
    overflow-x: auto;
}

.framework >>> .page > .view::-webkit-scrollbar {
    display: none;
}

</style>