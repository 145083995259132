<template>
  <el-dialog class="dialog" title="编辑网络优先级" width="600px" v-model="dialogVisible" :close-on-click-modal="false"
             @close="close" center>
    <el-form label-width="80px" :model="form">
      <el-form-item label="时间排序">
        <el-time-picker v-model="form.timeRangeStart"
                        size="small"
                        @change="handleChangeStartTimePicker"
                        :editable="false"
                        :selectable-range="{selectableRange: startSelectableRange(selectableRange)}"
                        format="HH:mm"
                        placeholder="起始时间"/>
        &nbsp;
        <el-time-picker v-model="form.timeRangeEnd"
                        size="small"
                        :editable="false"
                        :selectable-range="{selectableRange: endSelectableRange(selectableRange)}"
                        format="HH:mm"
                        placeholder="结束时间"/>
      </el-form-item>
      <el-form-item label="网络排序">
        <ul ref="slider" class="networks slider">
          <li class="item" v-for="(item, i) in form.items" :key="i">
            <div class="name">{{ item.name }}</div>
            <el-icon>
              <DCaret/>
            </el-icon>
          </li>
        </ul>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleConfirm">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import Sortable from "sortablejs";
import * as api from "../../api/api"
import {CODE_OK} from "../../api/request";
import moment from "moment/moment";
import {reactive, ref} from 'vue'

export default {
  setup() {

    let dialogVisible = ref(false)
    let endRangeEmpty = ref(false)
    let selectableRange = reactive( ["00:00:00 - 23:59:59"])
    let form = reactive({
      type: "default",
      id: 0,
      appId: 0,
      timeRangeStart: null,
      timeRangeEnd: null,
      items: []
    })

    return {
      dialogVisible,
      selectableRange,
      endRangeEmpty,
      form,
    }
  },
  updated() {
    this.rowDrop();
  },

  methods: {

    show: function (id, appId, start, end, items, selectableRange, type) {
      this.dialogVisible = true;
      this.form.type = type;
      this.form.id = id;
      this.form.appId = appId;
      this.form.items = items;
      this.form.timeRangeStart = start;
      this.form.timeRangeEnd = end;
      this.selectableRange = selectableRange;
    },

    close: function () {
      this.dialogVisible = false;
      this.clear();
    },

    clear: function () {
      this.form.id = 0;
      this.form.appId = 0;
      this.form.items = [];
      this.form.timeRangeStart = null;
      this.form.timeRangeEnd = null;
      this.selectableRange = [];
    },

    startSelectableRange: function (selectableRange) {
      return selectableRange.map((a) => {
        return moment(a[0]).format("HH:mm:ss") + " - " + moment(a[1]).format("HH:mm:ss");
      });
    },

    endSelectableRange: function (selectableRange) {
      const timeRangeStart = moment(this.form.timeRangeStart);
      const range = [];
      selectableRange.forEach((a) => {
        const start = moment(a[0]);
        const end = moment(a[1]);
        if ((start.isBefore(timeRangeStart) || start.isSame(timeRangeStart)) && end.isAfter(timeRangeStart)) {
          range.push(timeRangeStart.format("HH:mm:ss") + " - " + end.format("HH:mm:ss"));
        }
      });
      this.endRangeEmpty = range.length <= 0;
      return range;
    },

    handleChangeStartTimePicker: function () {
      const start = moment(this.form.timeRangeStart);
      for (let i = 0; i < this.selectableRange.length; i++) {
        const pStart = moment(this.selectableRange[i][0]);
        const pEnd = moment(this.selectableRange[i][1]);
        if ((pStart.isBefore(start) || pStart.isSame(start)) && (pEnd.isAfter(start) || pEnd.isSame(start))) {
          this.form.timeRangeEnd = pEnd.toDate();
        }
      }
    },

    handleCancel: function () {
      this.close();
    },

    handleConfirm: function () {
      const {id, appId, timeRangeStart, timeRangeEnd, items, type} = this.form;

      if (timeRangeStart.getHours() === timeRangeEnd.getHours()
          && timeRangeStart.getMinutes() === timeRangeEnd.getMinutes()) {
        this.$message.warning("开始时间和结束时间不能是相同的");
        return
      }

      const start = timeRangeStart;
      const end = timeRangeEnd;

      const startHour = start.getHours();
      const startMinute = start.getMinutes();
      const endHour = end.getHours();
      const endMinute = end.getMinutes();

      const newItems = [];
      for (let i = 0; i < items.length; i++) {
        newItems.push(items[i].id);
      }

      api.updateNetworkPriority(id, parseInt(appId), startHour, startMinute, endHour, endMinute, newItems, type).then(result => {
        if (parseInt(result.code) !== CODE_OK) {
          this.$message.error("创建网络优先级失败， Err: " + result.msg)
          return;
        }
        this.close();
        this.$emit("update")
      }, err => {
        this.$message.error("创建网络优先级失败， Err: " + err)
      })
    },

    rowDrop() {
      if (this.slider) return;
      this.slider = this.$refs.slider
      const that = this
      Sortable.create(this.slider, {
        animation: 200,
        draggable: '.item',
        onEnd({newIndex, oldIndex}) {
          if (newIndex === oldIndex) return
          that.form.items.splice(
              newIndex,
              0,
              that.form.items.splice(oldIndex, 1)[0]
          )
          const newArray = that.form.items.slice(0)
          that.form.items = []
          that.$nextTick(function () {
            that.form.items = newArray;
          })
        }
      })
    },
  }
}
</script>

<style scoped>

.dialog .networks {
  list-style: none;
  padding: 0px;
}

.dialog .networks li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.dialog .networks li:not(:last-child) {
  margin-bottom: 12px;
}

.dialog .networks .name {
  width: 410px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  border: 1px solid #f56c6c;
  border-radius: 3px;
  height: 30px;
  line-height: 30px;
}

.dialog .networks i {
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 30px;
  border: 1px solid #f56c6c;
  border-radius: 3px;
  margin-left: 10px;
  color: #f56c6c;
  font-size: 16px;
  cursor: pointer;
}
.dialog .networks i:hover {
  background-color: rgba(245, 108, 108, 0.25);
}
</style>