import {
    URI_ADMIN_ACCOUNT_BLOCK,
    URI_ADMIN_ACCOUNT_LIST,
    URI_ADMIN_ACCOUNT_PASS,
    URI_ADMIN_ACCOUNT_USER,
    URI_ADMIN_ACCOUNT_WITHDRAW_RESULT,
    URI_ADMIN_SYSTEM_LOG_LIST,
    URI_ADMIN_MEDIA_APP_LIST,
    URI_ADMIN_NETWORK_CREATE,
    URI_ADMIN_NETWORK_DELETE,
    URI_ADMIN_NETWORK_LIST,
    URI_ADMIN_NETWORK_STATIC_RECORD_LIST,
    URI_ADMIN_USER_LIST,
    URI_ADMIN_USER_ROLES_UPDATE,
    URI_ADMIN_EARNING_PLATFORM,
    URI_ADMIN_ISSUE_JOINSDKDOCUMENT_UPDATE,
    URI_ADMIN_ISSUE_JOINDOCUMENT,
    URI_ADMIN_ISSUE_JOINSDKFILE_LIST,
    URI_ADMIN_ISSUE_JOINSDKFIL_UPDATE,
    URI_ADMIN_MESSAGE_GLOBAL_LIST,
    URI_ADMIN_MESSAGE_GLOBAL_DELETE,
    URI_ADMIN_MESSAGE_GLOBAL_CREATE,
    URI_ADMIN_SYSTEM_CONFIG,
    URI_ADMIN_NETWORK_STATIC_RECORD_CREATE,
    URI_ADMIN_NETWORK_STATIC_RECORD_DELETE,
    URI_ADMIN_NETWORK_STATIC_RECORD_UPDATE,
    URI_ADMIN_MEDIA_APP,
    URI_ADMIN_NETWORK,
    URI_ADMIN_NETWORK_UPDATE,
    URI_ADMIN_DASHBOARD,
    URI_ADMIN_DASHBOARD_CLIENT,
    URI_ADMIN_DASHBOARD_GRAPH,
    URI_ADMIN_EARNING_PLATFORM_REPORT,
    URI_ADMIN_EARNING_USER_REPORT,
    URI_ADMIN_EARNING_USER_PRI_REPORT,
    URI_ADMIN_SETTLEMENT_PLATFORM_REPORT,
    URI_ADMIN_SETTLEMENT_PLATFORM_CHECK,
    URI_ADMIN_SETTLEMENT_CONFIG,
    URI_ADMIN_SETTLEMENT_CONFIG_CHECK_NEXT,
    URI_ADMIN_SETTLEMENT_USER_REPORT,
    URI_ADMIN_SETTLEMENT_APP_REPORT,
    URI_ADMIN_SETTLEMENT_APPD_REPORT,
    URI_ADMIN_SETTLEMENT_APPD_UDT,
    URI_ADMIN_SETTLEMENT_APP_CHECK,
    URI_ADMIN_SETTLEMENT_FINAL_PREVIEW,
    URI_ADMIN_SETTLEMENT_FINAL_GEN,
    URI_ADMIN_SETTLEMENT_FINAL,
    URI_ADMIN_SETTLEMENT_QUERY,
    URI_ADMIN_SETTLEMENT_QUERY_FAPIAO_CHECK,
    URI_ADMIN_SETTLEMENT_QUERY_PAY,
    URI_ADMIN_SETTLEMENT_PLATFORM_RECHECK,
    URI_ADMIN_USER_FORBID,
    URI_ADMIN_USER_ENABLE,
    URI_ADMIN_USER_SIGNUP_LIST,
    URI_ADMIN_USER_CONTACT_LIST,
    URI_ADMIN_USER_CONTACT_DELETE,
    URI_ADMIN_USER_SIGNUP_DELETE,
    URI_ADMIN_USER_CREATE,
    URI_ADMIN_NETWORK_PRIORITY_CREATE,
    URI_ADMIN_NETWORK_PRIORITY_UPDATE,
    URI_ADMIN_NETWORK_PRIORITY_DELETE,
    URI_ADMIN_NETWORK_ORDERS_UPDATE,
    URI_ADMIN_MEDIA_APP_STATUS_UPDATE,
    URI_ADMIN_SYSTEM_CONFIG_UPDATE,
    URI_ADMIN_SYSTEM_TASK_EXECUTE,
    URI_ADMIN_DASHBOARD_CLIENT_REAL,
    URI_ADMIN_USER_EDIT,
    URI_ADMIN_USER
} from "./url";
import {authorizeGet, authorizePost} from "./request";

/**
 * 获取概览
 * @returns {Promise<*>}
 */
export function getDashboard() {
    return authorizeGet(URI_ADMIN_DASHBOARD, {});
}

/**
 * 获取客户端统计
 * @param timeScope 时间访问
 * @param scene 场景
 * @returns {Promise<*>}
 */
export function getDashboardClient(timeScope, scene) {
    return authorizeGet(URI_ADMIN_DASHBOARD_CLIENT, {"time_scope": timeScope, "scene": scene});
}


/**
 * 获取上游客户端统计
 * @param timeScope 时间访问
 * @param scene 场景
 * @returns {Promise<*>}
 */
export function getDashboardClientReal(timeScope, scene) {
    return authorizeGet(URI_ADMIN_DASHBOARD_CLIENT_REAL, {"time_scope": timeScope, "scene": scene});
}

/**
 * 获取收益概况曲线数据
 * @param start
 * @param end
 */
export function getDashboardGraph(start, end) {
    return authorizeGet(URI_ADMIN_DASHBOARD_GRAPH, {"start": start, "end": end});
}

/**
 * 获取平台收益报告
 * @param unit
 * @param start
 * @param end
 * @param comparedStart
 * @param comparedEnd
 */
export function getEarningsPlatformReport(unit, start, end, comparedStart, comparedEnd, channel, comparedChannel, scene) {
    let param = {
        "unit": unit,
        "start": start,
        "end": end,
        "compared_start": comparedStart,
        "compared_end": comparedEnd,
        "channel": channel,
        "compared_channel": comparedChannel,
        "scene": scene,
    }
    return authorizeGet(URI_ADMIN_EARNING_PLATFORM_REPORT, param);
}

/**
 * 获取用户收益报告
 * @param page
 * @param pageSize
 * @param search
 * @param unit
 * @param start
 * @param end
 */
export function getEarningsUserReport(page, pageSize, search, unit, start, end) {
    let offset = page < 0 ? 0 : (page - 1) * pageSize
    let param = {
        "offset": offset,
        "limit": pageSize,
        "search": search,
        "unit": unit,
        "start": start,
        "end": end,
    }
    return authorizeGet(URI_ADMIN_EARNING_USER_REPORT, param);
}

/**
 * 获取用户自有收益报告
 * @param userId
 * @param page
 * @param pageSize
 * @param unit
 * @param start
 * @param end
 * @param appId
 * @param channel
 * @param scene
 * @param isCompared
 * @param comparedStart
 * @param comparedEnd
 */
export function getEarningsUserPriReport(userId, page, pageSize, unit, start, end, appId, scene, channel, isCompared, comparedStart, comparedEnd) {
    let offset = page < 0 ? 0 : (page - 1) * pageSize
    let param = {
        "user_id": userId,
        "offset": offset,
        "limit": pageSize,
        "unit": unit,
        "start": start,
        "end": end,
        "app_id": appId,
        "channel": channel,
        "scene": scene,
        "is_compared": isCompared,
        "compared_start": comparedStart,
        "compared_end": comparedEnd,
    }
    return authorizeGet(URI_ADMIN_EARNING_USER_PRI_REPORT, param);
}

/**
 *  获取结算配置
 */
export function getSettlementConfig() {
    return authorizeGet(URI_ADMIN_SETTLEMENT_CONFIG, {});
}

/**
 * 更新结算进度
 * @param id
 * @param current
 * @returns {Promise<*>}
 */
export function goNextSettlementCheck(id, current) {
    return authorizePost(URI_ADMIN_SETTLEMENT_CONFIG_CHECK_NEXT, {"id": id, "progress": current})
}

/**
 * 获取上游平台核算列表
 * @param page
 * @param pageSize
 * @param dateStart
 * @param dateEnd
 * @param status
 * @returns {Promise<*>}
 */
export function getSettlementPlatformReport(page, pageSize, dateStart, dateEnd, status) {
    let offset = page < 0 ? 0 : (page - 1) * pageSize
    let param = {
        "offset": offset,
        "limit": pageSize,
        "date_start": dateStart,
        "date_end": dateEnd,
        "status": status,
    }
    return authorizeGet(URI_ADMIN_SETTLEMENT_PLATFORM_REPORT, param)
}

/**
 * 核算上游平台收益
 * @param id
 * @param revenue
 * @returns {Promise<*>}
 */
export function checkSettlementPlatform(id, revenue) {
    return authorizePost(URI_ADMIN_SETTLEMENT_PLATFORM_CHECK, {"id": id, "revenue": revenue})
}

/**
 * 撤销核算上游平台收益
 * @param id
 */
export function recheckSettlementPlatform(id) {
    return authorizePost(URI_ADMIN_SETTLEMENT_PLATFORM_RECHECK, {id: id})
}

/**
 * 获取下游用户收益报表
 * @param page
 * @param pageSize
 * @param dateStart
 * @param dateEnd
 * @param status
 * @param search
 * @returns {Promise<*>}
 */
export function getSettlementUserReport(page, pageSize, dateStart, dateEnd, status, search) {
    let offset = page < 0 ? 0 : (page - 1) * pageSize
    let param = {
        "offset": offset,
        "limit": pageSize,
        "date_start": dateStart,
        "date_end": dateEnd,
        "status": status,
        "search": search,
    }
    return authorizeGet(URI_ADMIN_SETTLEMENT_USER_REPORT, param)
}

/**
 * 获取应用结算报表
 * @param page
 * @param pageSize
 * @param userFromID
 * @param userID
 * @param dateStart
 * @param dateEnd
 * @param status
 * @returns {Promise<*>}
 */
export function getSettlementAppReport(page, pageSize, userFromID, userID, dateStart, dateEnd, status) {
    let offset = page < 0 ? 0 : (page - 1) * pageSize
    let param = {
        "offset": offset,
        "limit": pageSize,
        "date_start": dateStart,
        "date_end": dateEnd,
        "user_id": userID,
        "user_form_id": userFromID,
        "status": status,
    }
    return authorizeGet(URI_ADMIN_SETTLEMENT_APP_REPORT, param)
}

/**
 * 结算应用
 * @param id
 * @param status
 * @returns {Promise<*>}
 */
export function checkSettlementApp(id, status) {
    return authorizePost(URI_ADMIN_SETTLEMENT_APP_CHECK, {"id": id, "status": status})
}

/**
 * 获取应用每日结算报表
 * @param page
 * @param pageSize
 * @param appFormID
 * @param userID
 * @param dateStart
 * @param dateEnd
 * @param status
 * @returns {Promise<*>}
 */
export function getSettlementAppDReport(page, pageSize, appFormID, userID, dateStart, dateEnd, status) {
    let offset = page < 0 ? 0 : (page - 1) * pageSize
    let param = {
        "offset": offset,
        "limit": pageSize,
        "date_start": dateStart,
        "date_end": dateEnd,
        "user_id": userID,
        "app_form_id": appFormID,
        "status": status,
    }
    return authorizeGet(URI_ADMIN_SETTLEMENT_APPD_REPORT, param)
}

/**
 * 更新应用每日结算报表
 * @param id
 * @param bdEstRevenue
 * @param txEstRevenue
 * @param kdEstRevenue
 * @returns {Promise<*>}
 */
export function updateSettlementAppD(id, bdEstRevenue, txEstRevenue, kdEstRevenue, gmEstRevenue) {
    let param = {
        "id": id,
        "bd_est_revenue": bdEstRevenue,
        "tx_est_revenue": txEstRevenue,
        "kd_est_revenue": kdEstRevenue,
        "gm_est_revenue": gmEstRevenue,
    }
    return authorizePost(URI_ADMIN_SETTLEMENT_APPD_UDT, param)
}

/**
 * 获取用户结算单预览
 * @param id
 * @returns {Promise<*>}
 */
export function getSettlementFinalPreview(id) {
    return authorizeGet(URI_ADMIN_SETTLEMENT_FINAL_PREVIEW, {"id": id})
}

/**
 * 生成用户结算单
 * @param id
 * @returns {Promise<*>}
 */
export function genSettlementFinal(id) {
    return authorizeGet(URI_ADMIN_SETTLEMENT_FINAL_GEN, {"id": id})
}

/**
 * 获取用户结算单
 * @param id
 * @returns {Promise<*>}
 */
export function getSettlementFinal(id) {
    return authorizeGet(URI_ADMIN_SETTLEMENT_FINAL, {"id": id})
}

/**
 * 获取平台收益
 * @return {Promise<*>}
 */
export function getEarningPlatform() {
    return authorizeGet(URI_ADMIN_EARNING_PLATFORM, {})
}

/**
 * 获取系统配置
 * @return {Promise<*>}
 */
export function getSystemConfig() {
    return authorizeGet(URI_ADMIN_SYSTEM_CONFIG, {});
}

/**
 * 更新系统配置
 * @param group
 * @param config
 */
export function updateSystemConfig(group, config) {
    const param = Object.assign({"group": group}, config);
    return authorizePost(URI_ADMIN_SYSTEM_CONFIG_UPDATE, param);
}

/**
 *
 * @param program
 * @returns {Promise<*>}
 */
export function  executeTask(program) {
    const param = {"program": program};
    return authorizePost(URI_ADMIN_SYSTEM_TASK_EXECUTE, param)
}

/**
 * 获取系统运行日志
 * @param page
 * @param pageSize
 * @param level
 * @param tag
 * @returns {Promise<*>}
 */
export function getSystemLogList(page, pageSize, level, tag) {
    let offset = page < 0 ? 0 : (page - 1) * pageSize
    let param = {
        "offset": offset,
        "limit": pageSize,
        "level": level,
        "tag": tag,
    }
    return authorizeGet(URI_ADMIN_SYSTEM_LOG_LIST, param)
}

/**
 * 获取网络
 * @param id
 * @return {Promise<*>}
 */
export function getNetwork(id) {
    return authorizeGet(URI_ADMIN_NETWORK, {"id": id})
}

/**
 * 获取网络列表
 * @param page
 * @param pageSize
 * @returns {Promise<*>}
 */
export function getNetworkList(page, pageSize, appId) {
    let offset = page < 0 ? 0 : (page - 1) * pageSize
    let param = {
        "offset": offset,
        "limit": pageSize,
        "app_id": appId,
    }
    return authorizeGet(URI_ADMIN_NETWORK_LIST, param)
}


/**
 * 删除网络
 * @param id
 * @returns {Promise<*>}
 */
export function deleteNetwork(id) {
    let param = {"id": id}
    return authorizeGet(URI_ADMIN_NETWORK_DELETE, param)
}

/**
 * 创建网络
 * @param belongAppId
 * @param name
 * @param channel
 * @param appId
 * @param appName
 * @param appKey
 * @param packageName
 * @param sha1
 * @param dynamic
 * @param records
 * @returns {Promise<*>}
 */
export function createNetwork(belongAppId, name, channel, appId, appName, appKey, packageName, sha1, dynamic, records) {
    let param = {
        "belong_app_id": belongAppId,
        "name": name,
        "channel": channel,
        "app_id": appId,
        "app_name": appName,
        "app_key": appKey,
        "package_name": packageName,
        "sha1": sha1,
        "dynamic": dynamic,
        "records": records.map((item) => {
            return {
                "placement_id": item.placementId,
                "uint_id": "",
                "name": item.name,
                "scene": item.scene,
                "orientation": item.orientation,
                "ecpm": item.ecpm,
            }
        }),
    }
    return authorizePost(URI_ADMIN_NETWORK_CREATE, param)
}

/**
 * 更新网络
 * @param id
 * @param name
 * @param appId
 * @param appKey
 * @param packageName
 * @param sha1
 * @param records
 * @returns {Promise<*>}
 */
export function updateNetwork(id, name, appId, appName, appKey, packageName, sha1, records) {
    let param = {
        "id": id,
        "name": name,
        "app_id": appId,
        "app_name": appName,
        "app_key": appKey,
        "package_name": packageName,
        "sha1": sha1,
        "records": records.map((item) => {
            return {
                "placement_id": item.placementId,
                "uint_id": "",
                "name":  item.name,
                "scene": item.scene,
                "orientation": item.orientation,
                "ecpm": item.ecpm,
            }
        }),
    }
    return authorizePost(URI_ADMIN_NETWORK_UPDATE, param);
}

/**
 * 更新网络排序
 * @param appId 应用ID
 * @param orders 排序
 * @param type 排序类型 default, bottom
 * @returns {Promise<*>}
 */
export function updateNetworkOrders(appId, orders, type) {
    return authorizePost(URI_ADMIN_NETWORK_ORDERS_UPDATE, {"app_id": appId, "orders": orders, type: type})
}

/**
 * 创建网络优先级
 * @param appId
 * @param startHour
 * @param startMinute
 * @param endHour
 * @param endMinute
 * @param items
 * @param type
 * @returns {Promise<*>}
 */
export function createNetworkPriority(appId, startHour, startMinute, endHour, endMinute, items, type) {
    const param = {
        "app_id": appId,
        "start_hour": startHour,
        "start_minute": startMinute,
        "end_hour": endHour,
        "end_minute": endMinute,
        "items": items,
        "type": type,
    }
    return authorizePost(URI_ADMIN_NETWORK_PRIORITY_CREATE, param);
}

/**
 * 删除网络优先级
 * @param id
 * @returns {Promise<*>}
 */
export function deleteNetworkPriority(id) {
    return authorizeGet(URI_ADMIN_NETWORK_PRIORITY_DELETE, {id: id})
}

/**
 * 更新网络优先级
 * @param id
 * @param appId
 * @param startHour
 * @param startMinute
 * @param endHour
 * @param endMinute
 * @param items
 * @param type
 * @returns {Promise<*>}
 */
export function updateNetworkPriority(id, appId, startHour, startMinute, endHour, endMinute, items, type) {
    const param = {
        "type": type,
        "id": id,
        "app_id": appId,
        "start_hour": startHour,
        "start_minute": startMinute,
        "end_hour": endHour,
        "end_minute": endMinute,
        "items": items,
    }
    return authorizePost(URI_ADMIN_NETWORK_PRIORITY_UPDATE, param);
}

/**
 * 获取网络记录列表
 * @param page
 * @param pageSize
 * @param networkId
 * @param placementId
 * @param orientation
 * @returns {Promise<*>}
 */
export function getNetworkRecordList(page, pageSize, networkId, placementId, orientation) {
    let offset = page < 0 ? 0 : (page - 1) * pageSize
    let param = {
        "offset": offset,
        "limit": pageSize,
        "network_id": networkId,
        "placement_id": placementId,
        "orientation": orientation,
    }
    return authorizeGet(URI_ADMIN_NETWORK_STATIC_RECORD_LIST, param)
}

/**
 * 创建网络记录
 * @param networkId
 * @param placementId
 * @param channelPlacementId
 * @param orientation
 * @returns {Promise<*>}
 */
export function createNetworkRecord(networkId, placementId, channelPlacementId, orientation) {
    let param = {
        "network_id": networkId,
        "placement_id": placementId,
        "channel_placement_id": channelPlacementId,
        "orientation": orientation,
    }
    return authorizePost(URI_ADMIN_NETWORK_STATIC_RECORD_CREATE, param)
}

/**
 * 更新网络记录
 * @param id
 * @param networkId
 * @param placementId
 * @param channelPlacementId
 * @param orientation
 * @returns {Promise<*>}
 */
export function updateNetworkRecord(id, networkId, placementId, channelPlacementId, orientation) {
    let param = {
        "id": id,
        "network_id": networkId,
        "placement_id": placementId,
        "channel_placement_id": channelPlacementId,
        "orientation": orientation,
    }
    return authorizePost(URI_ADMIN_NETWORK_STATIC_RECORD_UPDATE, param)
}

/**
 * 删除网络记录
 * @param id
 * @returns {Promise<*>}
 */
export function deleteNetworkRecord(id) {
    return authorizePost(URI_ADMIN_NETWORK_STATIC_RECORD_DELETE, {"id": id})
}

/**
 * 获取全部应用（admin）
 * @param page
 * @param pageSize
 * @returns {Promise<*>}
 */
export function getMediaAppList(page, pageSize, appId, usernmae) {
    let offset = page < 0 ? 0 : (page - 1) * pageSize
    let param = {
        "offset": offset,
        "limit": pageSize,
        "id": appId,
        "username": usernmae
    }
    return authorizeGet(URI_ADMIN_MEDIA_APP_LIST, param)
}

/**
 * 获取应用信息
 * @param id
 * @returns {Promise<*>}
 */
export function getMediaApp(id) {
    return authorizeGet(URI_ADMIN_MEDIA_APP, {"id": id})
}


/**
 * 更新媒体APP状态
 * @param id
 * @param newStatus
 * @param opDescribe
 * @returns {Promise<*>}
 */
export function updateMediaAppStatus(id, newStatus, opDescribe) {
    return authorizePost(URI_ADMIN_MEDIA_APP_STATUS_UPDATE, {"id": id, "status": newStatus, op_describe: opDescribe})
}

/**
 * 获取账户申请列表
 * @param page
 * @param pageSize
 */
export function getAccountQueryList(page, pageSize) {
    let offset = page < 0 ? 0 : (page - 1) * pageSize
    let param = {
        "offset": offset,
        "limit": pageSize,
        "status": 0,
    }
    return authorizeGet(URI_ADMIN_ACCOUNT_LIST,  param)
}


/**
 * 获取所有账户列表
 * @param page
 * @param pageSize
 * @param name
 * @param amount
 * @param status
 */
export function getEarningAccounts(page, pageSize, name, amountGreater, status) {
    let offset = page < 0 ? 0 : (page - 1) * pageSize
    let param = {
        "offset": offset,
        "limit": pageSize,
        "name": name,
        "amount_greater": amountGreater,
        "status": status
    }
    return authorizeGet(URI_ADMIN_ACCOUNT_LIST,  param)
}

/**
 * 通过账户审核
 * @param id
 * @param pass
 * @returns {Promise<*>}
 */
export function getAccountPass(id, pass) {
    let param = {
        "id": id,
        "disable": pass ? "true" : "false",
    }
    return authorizeGet(URI_ADMIN_ACCOUNT_PASS, param)
}

/**
 * 封禁账户
 * @param id
 * @param block
 * @param msg
 * @returns {Promise<*>}
 */
export function blockAccount(id, block, msg) {
    let param = {
        "id": id,
        "enable": block ? "true" : "false",
        "msg": msg,
    }
    return authorizeGet(URI_ADMIN_ACCOUNT_BLOCK, param)
}

/**
 * 获取账户提现列表
 * @param page
 * @param pageSize
 * @param date
 * @param status
 * @param search
 */
export function getSettlementQuery(page, pageSize, date, status, search) {
    let offset = page < 0 ? 0 : (page - 1) * pageSize
    let param = {
        "offset": offset,
        "limit": pageSize,
        "date": date,
        "status": status,
        "search": search,
    }
    return authorizeGet(URI_ADMIN_SETTLEMENT_QUERY,  param)
}

/**
 * 审核结算请求发票
 * @param id
 * @param allow
 * @param reason
 * @returns {Promise<*>}
 */
export function checkSettlementQueryFapiao(id, allow, reason) {
    return authorizePost(URI_ADMIN_SETTLEMENT_QUERY_FAPIAO_CHECK, {"id": id, "allow": allow, "reason": reason})
}

/**
 * 支付结算请求
 * @param id
 * @returns {Promise<*>}
 */
export function checkSettlementQueryPay(id) {
    return authorizePost(URI_ADMIN_SETTLEMENT_QUERY_PAY, {"id": id})
}

/**
 * 获取用户账户信息
 * @param userId
 * @returns {Promise<*>}
 */
export function getAccountUser(userId) {
    let param = {"user_id": userId}
    return authorizeGet(URI_ADMIN_ACCOUNT_USER, param)
}

/**
 * 处理提现请求
 * @param id
 * @param status
 * @param result
 * @returns {Promise<*>}
 */
export function postWithdrawResult(id, status, result) {
    let param = {
        "id": id,
        "status": status,
        "result": result
    }
    return authorizePost(URI_ADMIN_ACCOUNT_WITHDRAW_RESULT, param)
}

/**
 * 获取用户列表
 * @param page
 * @param pageSize
 * @param search
 * @param status
 */
export function getUserList(page, pageSize, search, status) {
    let offset = page < 0 ? 0 : (page - 1) * pageSize
    let param = {
        "offset": offset,
        "limit": pageSize,
        "search": search,
        "status": status,
    }

    return authorizeGet(URI_ADMIN_USER_LIST, param)
}

/**
 * 获取用户信息
 * @param id
 */
export function getUser(id) {
    return authorizeGet(URI_ADMIN_USER, {id: id});
}


/**
 * 创建用户
 * @param username
 * @param email
 * @param contact
 * @param mobile
 * @param address
 * @param payeeName
 * @param bankCardNumber
 * @param bankAddress
 * @param shareRatio
 * @returns {Promise<*>}
 */
export function createUser(username, email, contact, mobile, address, payeeName, bankCardNumber, bankAddress, shareRatio) {
    const param = {username: username, email: email, contact: contact, mobile: mobile, address: address,
        "payee_name": payeeName, "bank_card_number": bankCardNumber, "bank_address": bankAddress, "share_ratio": shareRatio};
    return authorizePost(URI_ADMIN_USER_CREATE, param);
}


/**
 * 编辑用户
 * @param username
 * @param contact
 * @param mobile
 * @param address
 * @param payeeName
 * @param bankCardNumber
 * @param bankAddress
 * @return {Promise<*>}
 */
export function editUser(id, username, contact, mobile, address, payeeName, bankCardNumber, bankAddress, shareRatio) {
    const param = {id: id, username: username, contact: contact, mobile: mobile, address: address,
        "payee_name": payeeName, "bank_card_number": bankCardNumber, "bank_address": bankAddress, "share_ratio": shareRatio};
    return authorizePost(URI_ADMIN_USER_EDIT, param);
}


/**
 * 禁用用户
 * @param id
 */
export function forbidUser(id) {
    return authorizePost(URI_ADMIN_USER_FORBID, {'id': id})
}

/**
 * 启用用户
 * @param id
 * @returns {Promise<*>}
 */
export function enableUser(id) {
    return authorizePost(URI_ADMIN_USER_ENABLE, {"id": id})
}

/**
 * 获取注册申请列表
 * @param page
 * @param pageSize
 * @param search
 * @returns {Promise<*>}
 */
export function getUserSignupList(page, pageSize, search) {
    let offset = page < 0 ? 0 : (page - 1) * pageSize
    let param = {
        "offset": offset,
        "limit": pageSize,
        "search": search,
    }
    return authorizeGet(URI_ADMIN_USER_SIGNUP_LIST, param)
}

/**
 * 删除注册申请
 * @param id
 * @returns {Promise<*>}
 */
export function deleteUserSignup(id) {
    return authorizePost(URI_ADMIN_USER_SIGNUP_DELETE, {id: id})
}

/**
 * 获取联系申请列表
 * @param page
 * @param pageSize
 * @param search
 * @returns {Promise<*>}
 */
 export function getUserContactList(page, pageSize, search) {
     let offset = page < 0 ? 0 : (page - 1) * pageSize
     let param = {
         "offset": offset,
         "limit": pageSize,
         "search": search,
     }
     return authorizeGet(URI_ADMIN_USER_CONTACT_LIST, param)
 }

/**
 * 删除联系申请
 * @param id
 * @returns {Promise<*>}
 */
 export function deleteUserContact(id) {
     return authorizePost(URI_ADMIN_USER_CONTACT_DELETE, {id: id})
 }

/**
 * 提交Sdk文档
 * @param title
 * @param content
 * @param sdkSystem
 * @return {Promise<*>}
 */
export function postIssueJoinSdkDocument(title, content, sdkSystem) {
    let param = {
        "title": title,
        "content": content,
        "sdk_system": sdkSystem,
    }
    return authorizePost(URI_ADMIN_ISSUE_JOINSDKDOCUMENT_UPDATE, param)
}

/**
 * 获取SDK文档
 * @param sdkSystem
 */
export function getIssueJoinSdkDocument(sdkSystem) {
    let param = {
        "sdk_system": sdkSystem
    }
    return authorizeGet(URI_ADMIN_ISSUE_JOINDOCUMENT, param);
}

/**
 * 获取Sdk文件列表
 * @return {Promise<*>}
 */
export function getIssueJoinSdkFileList() {
    return authorizeGet(URI_ADMIN_ISSUE_JOINSDKFILE_LIST, {})
}

/**
 * 提交sdk文件更新
 * @param id
 * @param versionName
 * @param versionCode
 * @param sizeKb
 * @param downloadUrl
 */
export function postIssueJoinSdkFile(id, versionName, versionCode, sizeKb, downloadUrl) {
    let param = {
        "id": id,
        "version_name": versionName,
        "version_code": versionCode,
        "size_kb": sizeKb,
        "download_url": downloadUrl,
    }
    return authorizePost(URI_ADMIN_ISSUE_JOINSDKFIL_UPDATE, param)
}

/**
 * 更新用户角色
 * @param userId
 * @param roleIds
 */
export function postUserRolesUpdate(userId, roleIds) {
    let param = {
        "user_id": userId,
        "role_ids": roleIds,
    }
    return authorizePost(URI_ADMIN_USER_ROLES_UPDATE, param)
}

/**
 * 获取全局消息列表
 * @param page
 * @param pageSize
 * @return {Promise<*>}
 */
export function getMessageGlobalList(page, pageSize) {
    let offset = page < 0 ? 0 : (page - 1) * pageSize
    return authorizeGet(URI_ADMIN_MESSAGE_GLOBAL_LIST, {"offset": offset, "limit": pageSize})
}

/**
 * 删除一条全局消息
 * @param id
 * @return {Promise<*>}
 */
export function deleteMessageGlobal(id) {
    return authorizeGet(URI_ADMIN_MESSAGE_GLOBAL_DELETE, {"id": id})
}

/**
 * 添加一条全局消息
 * @param type
 * @param title
 * @param content
 */
export function postMessageGlobal(type, title, content) {
    let param = {
        "type": type,
        "title": title,
        "content": content,
    }
    return authorizePost(URI_ADMIN_MESSAGE_GLOBAL_CREATE, param)
}