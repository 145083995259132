<template>
    <div>
        <el-card class="card base">
          <template #header>基础</template>
            <div class="form">
                <div class="form-group">
                    <div class="label">分成比例</div>
                    <div class="value"><el-input-number v-model="sharedRatio" controls-position="right"/></div> &nbsp;%
                </div>
            </div>
            <el-button type="primary" @click="handleConfirmBase">应用</el-button>
        </el-card>

        <el-card class="card client" >
            <template #header>客户端</template>
            <el-form inline label-width="100">
                <el-form-item label="加载重试">
                    <el-input-number v-model="clientRetryCount" controls-position="right" min="0"  max="10" step="1"/>
                </el-form-item>

                <el-divider direction="vertical"></el-divider>

                <el-form-item label="加载延迟">
                    <el-input-number v-model="clientRetryDelay" controls-position="right" min="0"  max="100000" step="100" /> &nbsp;ms
                </el-form-item>

                <el-divider direction="vertical"></el-divider>

                <el-form-item label="预加载延迟">
                    <el-input-number v-model="clientPrepareDelay" controls-position="right" min="0"  max="100000" step="100" /> &nbsp;ms
                </el-form-item>
            </el-form>

            <el-button type="primary" @click="handleConfirmClient">应用</el-button>
        </el-card>
        <el-card class="card cron">
            <template #header>定时任务</template>
            <div class="form">
                 <div class="form-group">
                   <div class="label">发布收益信息</div>
                   <div class="value"><el-time-select v-model="earningPublishClock" value-format="hh:mm" style="width: 150px"/></div>
                   <div class="addition"><el-button  type="primary" @click="handleExecuteTask('sync')">立即执行</el-button></div>
                 </div>
            </div>
            <el-button type="primary" @click="handleConfirmCron">应用</el-button>
        </el-card>

        <el-card class="card safe">
          <template #header>安全</template>
            <div class="form">
                <div class="form-group">
                    <div class="label">可访问后台IP</div>
                    <div class="value">
                        <el-input v-model="whiteIps" class="white-ips" type="textarea"/>
                    </div>
                </div>
            </div>
            <el-button type="primary" @click="handleConfirmSafe">应用</el-button>
        </el-card>
    </div>
</template>

<script>
import * as api from "../../api/api";
import {CODE_OK} from "../../api/request";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
    name: "config",

    data: function () {
        return {
            clientPrepareDelay: 0,
            clientRetryCount: 0,
            clientRetryDelay:0,
            earningPublishClock: "00:00",
            sharedRatio: 90,
            whiteIps: "",
        }
    },

    mounted() {
        this.initView();
    },

    methods: {

        initView: function () {
            this.update();
        },

        update: function () {
            api.getSystemConfig().then(result => {
                if (parseInt(result.code) !== CODE_OK) {
                    this.$message.error("获取系统配置失败， Err: " + result.msg)
                    return
                }

                const {client_prepare_delay, client_retry_count, client_retry_delay, earning_publish_hour,
                    earning_publish_minute, shared_ratio, white_ips} = result.data;
                this.clientPrepareDelay = client_prepare_delay;
                this.clientRetryCount = client_retry_count;
                this.clientRetryDelay = client_retry_delay;
                this.earningPublishClock =  earning_publish_hour + ":" + earning_publish_minute ;
                this.sharedRatio = shared_ratio;
                this.whiteIps = white_ips;

            }, err => {
                this.$message.error("获取系统配置失败, Err: " + err)
            })
        },


        handleConfirmBase: function () {
            api.updateSystemConfig("base", {
                "shared_ratio": this.sharedRatio,
            }).then(result => {
                if (parseInt(result.code) !== CODE_OK) {
                    this.$message.error("更新系统配置失败， Err: " + result.msg)
                    return
                }
                this.update();
            }, err => {
                this.$message.error("更新系统配置失败, Err: " + err)
            });
        },

        handleConfirmClient: function () {
            api.updateSystemConfig("client", {
                "client_retry_count": this.clientRetryCount,
                "client_retry_delay": this.clientRetryDelay,
                "client_prepare_delay": this.clientPrepareDelay,
            }).then(result => {
                if (parseInt(result.code) !== CODE_OK) {
                    this.$message.error("更新系统配置失败， Err: " + result.msg)
                    return
                }
                this.update();
            }, err => {
                this.$message.error("更新系统配置失败, Err: " + err)
            });
        },

        handleConfirmCron: function () {
            const clockArr = this.earningPublishClock.split(":");
            api.updateSystemConfig("cron", {
                "earning_publish_hour": parseInt(clockArr[0]),
                "earning_publish_minute": parseInt(clockArr[1]),
            }).then(result => {
                if (parseInt(result.code) !== CODE_OK) {
                    this.$message.error("更新系统配置失败， Err: " + result.msg)
                    return
                }
                this.update();
            }, err => {
                this.$message.error("更新系统配置失败, Err: " + err)
            })
        },

        handleConfirmSafe: function () {
            api.updateSystemConfig("safe", {
                "white_ips": this.whiteIps
            }).then(result => {
                if (parseInt(result.code) !== CODE_OK) {
                    this.$message.error("更新系统配置失败， Err: " + result.msg)
                    return
                }
                this.update();
            }, err => {
                this.$message.error("更新系统配置失败, Err: " + err)
            })
        },

        handleExecuteTask: function (program) {
            api.executeTask(program).then(result => {
                if (parseInt(result.code) !== CODE_OK) {
                    this.$message.error("执行任务失败， Err: " + result.msg)
                    return
                }
            }, err => {
                this.$message.error("执行任务失败， Err: " + err)
            })
        }

    }
}
</script>

<style scoped>

.card:not(:last-child) {
    margin-bottom: 20px;
}

.card.base .form {
    margin-bottom: 20px;
}

.card.base .form .form-group {
    display: flex;
    align-items: center;
    justify-content: left;
}

.card.base .form .form-group .label {
    width: 60px;
    text-align: left;
    font-size: 14px;
    color: #606266;
    line-height: 40px;
    padding: 0 12px 0 0;
}

.card.client .el-form.el-form--inline {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.card.client .el-form .el-form-item {
    margin-bottom: 0px;
}

.card.cron .form {
    margin-bottom: 20px;
}

.card.cron .form .form-group {
    display: flex;
    align-items: center;
    justify-content: left;
}

.card.cron .form .form-group .label {
    width: 100px;
    text-align: left;
    font-size: 14px;
    color: #606266;
    line-height: 40px;
    padding: 0 12px 0 0;
}

.card.safe .form {
    margin-bottom: 20px;
}

.card.cron .form .form-group .addition {
    margin-left: 20px;
}

.card.safe .form .form-group {
    display: flex;
    flex-direction: column;
}

.card.safe .form .form-group .label {
    width: 100px;
    text-align: left;
    font-size: 14px;
    color: #606266;
    line-height: 40px;
    padding: 0 12px 0 0;
}

.card.safe .form .form-group .value .white-ips {
    width: 300px;
}


</style>