<template>
    <el-container class="login">
      <el-header  height="200px" ></el-header>
      <el-main>
        <el-card body-style="{padding: '60px 100px'}">
            <div><slot name="header" class="login-header">
                <img src="../../assets/logo.png" width="300"/>
            </slot>
            </div>
            <el-form class="login-form" ref="form" label-width="80px" label-position="left">

                <!-- 账号输入 -->
                <el-form-item label="账号">
                    <el-input v-model="username" placeholder="请输入账号" @keyup.enter="handleLogin"></el-input>
                </el-form-item>

                <!-- 密码输入 -->
                <el-form-item label="密码">
                    <el-input v-model="password" placeholder="请输入密码" type="password" @keyup.enter="handleLogin"></el-input>
                </el-form-item>

            </el-form>
            <div class="login-buttons">

                <!-- 登陆按钮 -->
                <el-button  @click="handleLogin">登 陆</el-button>

                <!-- 注册按钮 -->
                <el-button plain disabled>注 册</el-button>
            </div>
        </el-card>
      </el-main>
      <el-footer></el-footer>
    </el-container>
</template>

<script>

import * as api from "../../api/request"
import router from '../../router/'



export default {

  setup() {


  },

  // eslint-disable-next-line vue/multi-word-component-names
    name: "Login",

    methods: {
        handleLogin: function () {
            if (this.username.length <= 0) {
                this.$message.warning("请输入账号！");
                return;
            }

            if (this.password.length <= 0) {
                this.$message.warning("请输入密码！");
                return;
            }

            api.postLogin(this.username, this.password).then(result => {
                if (parseInt(result.code) !== api.CODE_OK) {
                    this.$message.error("登陆异常:" + result.msg);
                    this.password = "";
                    return
                }

                const {token ,username, user_id} = result.data;
                api.authorizeInit(token, username, user_id);
              router.push('/dashboard/home')

                this.$message({
                    message: '登陆成功',
                    type: 'success'
                });
            }, err => {
                this.$message.error("登陆请求失败！Err: " + err);
            })
        }
    },
    data() {
        return {
            username: "",
            password: "",
        }
    }
}
</script>

<style scoped>

.login {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-header {
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-form {
    width: 360px;
}

.login-buttons {
    padding-top: 40px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.login-buttons > button {
    width: 120px;
    height: 40px;
}

</style>