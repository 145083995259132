
export function email (value) {
    return /^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/.test(value);
}

export function phone (value) {
    return /^1[3|4|5|7|8][0-9]{9}$/.test(value);
}


export function password(value) {
    return /^[a-zA-Z0-9]{8,20}/.test(value);
}