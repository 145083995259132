import moment from 'moment'

export function dateFormatter(date, format) {
    return moment(date).format(format);
}

export function unixFormatter(unix, format) {
    return moment(unix * 1000).format(format);
}

export function hourMinuteFormatter(hour, minute, format) {
    return moment({ hour:hour, minute:minute }).format(format);
}