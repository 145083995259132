<template>
    <div>
        <el-card class="card filter">
            <el-form inline>
                <el-form-item>
                    <el-date-picker
                        v-model="filter.date"
                        type="month"
                        placeholder="选择月">
                    </el-date-picker>
                </el-form-item>

                <el-form-item label="用户名">
                    <el-input v-model="filter.serach"/>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="update">搜索</el-button>
                </el-form-item>

                <el-form-item>
                    <el-button class="filter-clear" type="text" @click="handleClickClear">清空搜索条件</el-button>
                </el-form-item>
            </el-form>
        </el-card>

        <el-card class="card content">
            <el-table :data="data">
                <el-table-column label="结算时间" align="center">
                    <template v-slot:default="scope">
                        <span>{{unixFormatter(scope.row.date, 'YYYY-MM')}}</span>
                    </template>
                </el-table-column>

                <el-table-column prop="user_id" label="账户ID" align="center"/>

                <el-table-column prop="username" label="账户名" align="center"/>

                <el-table-column prop="est_revenue" label="预估收益" align="center"/>

                <el-table-column label="分成比例" align="center">
                    <template v-slot:default="scope">
                        <span>{{scope.row.share_ratio}} %</span>
                    </template>
                </el-table-column>

                <el-table-column prop="pure_revenue" label="分成收益" align="center"/>

                <el-table-column label="状态" align="center">
                    <template v-slot:default="scope">
                        <tag v-if="scope.row.status===1">未结算</tag>
                        <tag v-if="scope.row.status===2" color="#67C23A">已结算</tag>
                    </template>
                </el-table-column>

                <el-table-column label="操作" align="center">
                    <template v-slot:default="scope">
                        <el-button type="text" @click="handleClickDetail(scope.row.id,scope.row.username)">明细</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="footer">
                <el-pagination
                    class="pagination"
                    background
                    layout="prev, pager, next"
                    @current-change="update"
                    v-model:current-page="currentPage"
                    :page-size="pageSize"
                    :total="pageCount">
                </el-pagination>
            </div>
        </el-card>
    </div>
</template>

<script>
import moment from "moment";
import * as api from "../../api/api";
import {CODE_OK} from "../../api/request";
import {unixFormatter} from "../../util/format";
import Tag from "../../component/Tag.vue";
import Routers from "../../router/"

export default {
    name: "UserHistory",

    components: {Tag},

    data: function () {
        return {
            filter: {
                date: '',
                serach: ''
            },
            data: [],
            pageSize: 8,
            pageCount: 0,
            currentPage: 1,
        }
    },

    mounted() {
        this.filter.date = moment().subtract(1, "months").startOf('month').toDate();
        this.initView();
    },

    methods: {
        unixFormatter,

        initView: function () {
            this.update();
        },

        update: function () {
            const {currentPage, pageSize, filter} = this;
            const {search, date} = filter;
            const month = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
            const dateStart = moment(month).startOf('month').unix();
            const dateEnd = moment(month).endOf('month').unix()
            api.getSettlementUserReport(currentPage, pageSize, dateStart, dateEnd, 2, search).then(result => {
                if (parseInt(result.code) !== CODE_OK) {
                    this.$message.error("获取上游核算列表失败， Err: " + result.msg)
                    return
                }
                this.data = result.data.list.map((item)=> {
                    item.est_revenue = (item.est_revenue/100).toFixed(2);
                    item.pure_revenue = (item.pure_revenue/100).toFixed(2);
                    return item;
                });
                this.pageCount = result.data.count;
            }, err => {
                this.$message.error("获取上游核算列表失败, Err: " + err)
            })
        },

        handleClickDetail: function (id, username) {
            Routers.push({
                path: "/dashboard/settlement/user/app/history",
                query: {
                    id: id,
                    username: username,
                }
            })
        },

        handleClickClear: function () {
            this.filter.date = moment().subtract(1, "months").startOf('month').toDate();
            this.filter.serach = "";
        }
    }
}
</script>

<style scoped>

.card.filter .el-form-item {
    margin-bottom: 0 !important;
}

.card:not(:first-child) {
    margin-top: 20px;
}

.card .footer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

</style>