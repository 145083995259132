<template>
    <div>
        <el-card class="header">
            <el-page-header title="返回" @back="handleGoBack" :content="username">
            </el-page-header>
        </el-card>
        <el-tabs class="tabs" type="border-card" v-model="tabIndex" @tab-remove="handleClosePane">
            <el-tab-pane :name="'0'">
              <span><slot name="label"><i class="el-icon-menu"></i> 应用清单</slot></span>
                <div class="header">
                    <span>结算进度(<span style="color: #f56c6c">&nbsp;{{pageCount - untreatedCount}}/{{pageCount}}&nbsp;</span>)</span> &nbsp; &nbsp;

                    <el-button v-if="this.isGenFinal"  type="primary" :disabled="untreatedCount!==0" @click="handleClickFinal">结算单</el-button>
                    <el-button v-else type="primary" :disabled="untreatedCount!==0" @click="handleClickGenFinal">预览结算单</el-button>
                </div>
                <div class="table">
                    <el-table :data="data">
                        <el-table-column label="结算时间" align="center">
                            <template v-slot:default="scope">
                                <span>{{unixFormatter(scope.row.date, 'YYYY-MM')}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="app_id" label="应用ID" align="center"></el-table-column>
                        <el-table-column prop="app_name" label="应用名" align="center"></el-table-column>
                        <el-table-column prop="est_revenue" label="预估收益" align="center"></el-table-column>
                        <el-table-column prop="kd_est_revenue"  label="快手" align="center"></el-table-column>
                        <el-table-column prop="tx_est_revenue"  label="优量汇" align="center"></el-table-column>
                        <el-table-column prop="gm_est_revenue"  label="Gromore" align="center"></el-table-column>
                        <el-table-column label="状态" align="center" v-if="!isGenFinal">
                            <template v-slot:default="scope">
                                <tag v-if="scope.row.status===1">未结算</tag>
                                <tag v-if="scope.row.status===2" color="#67C23A">已结算</tag>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" align="center">
                            <template v-slot:default="scope">
                                <template v-if="!isGenFinal">
                                    <el-button v-if="scope.row.status === 1" type="text" @click="handleClickSettle(scope.row.id, 2)">确认</el-button>
                                    <el-button v-else type="text" @click="handleClickSettle(scope.row.id, 1)">撤销</el-button>
                                </template>
                                <el-button type="text" @click="handleClickDetail(scope.row.id, scope.row.app_name, scope.row.status !== 2)">明细</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="footer">
                    <el-pagination
                        class="pagination"
                        background
                        layout="prev, pager, next"
                        @current-change="update"
                        v-bind:current-page="currentPage"
                        :page-size="pageSize"
                        :total="pageCount">
                    </el-pagination>
                </div>
            </el-tab-pane>
            <template :key="i" v-for="(item, i) in panes">
                 <el-tab-pane :name="i+1" :label="item.name" :closable="true">
                    <user-appd-plane :id="item.id" :editable="item.editable" :date="item.date" edit @update="update"/>
                 </el-tab-pane>
            </template>
        </el-tabs>
        <final-form-preview-dialog ref="finalFormPreviewDialog" :id="id" @update="update"/>
        <final-form-dialog ref="finalFormDialog" :id="id"/>
    </div>
</template>

<script>
import * as api from "../../api/api";
import {CODE_OK} from "../../api/request";
import {unixFormatter} from "../../util/format";
import Tag from "../../component/Tag.vue"
import UserAppdPlane from "./AppFormEveryday.vue";
import FinalFormPreviewDialog from "./PreFinalFormDialog.vue";
import FinalFormDialog from "./FinalFormDialog.vue";
import moment from "moment/moment";
import Routers from "../../router/"

export default {
    name: "AppForm",

    components: {FinalFormPreviewDialog, FinalFormDialog, UserAppdPlane, Tag},

    data: function () {
        return {
            id: 0,
            username: "",
            date: 0,
            isGenFinal: false,
            untreatedCount: 0,
            tabIndex: 0,
            data: [],
            panes: [],
            pageSize: 8,
            pageCount: 0,
            currentPage: 1,
        }
    },

    mounted() {
        this.initView();
    },

    methods: {
        unixFormatter,

        initView: function () {
            const {id, username, date} = this.$route.query
            if (id === undefined || id === "" || date === undefined) {
                this.$message.error("缺少参数 userFormId");
                Routers.back();
                return
            }

            this.username = username;
            this.id = Number.parseInt(id);
            this.date = Number.parseInt(date)
            this.update();
        },

        update:function () {
            const {currentPage, pageSize, id, date} = this;
            const dateStart = moment(date).startOf('month').unix()
            const dateEnd = moment(date).endOf('month').unix()
            api.getSettlementAppReport(currentPage, pageSize, id, 0, dateStart, dateEnd, 0).then(result => {
                if (parseInt(result.code) !== CODE_OK) {
                    this.$message.error("获取上游核算列表失败， Err: " + result.msg)
                    return
                }
                this.data = result.data.list.map((item)=> {
                    item.est_revenue = (item.est_revenue/100).toFixed(2);
                    item.bd_est_revenue = (item.bd_est_revenue/100).toFixed(2);
                    item.tx_est_revenue = (item.tx_est_revenue/100).toFixed(2);
                    item.kd_est_revenue = (item.kd_est_revenue/100).toFixed(2);
                    item.gm_est_revenue = (item.gm_est_revenue/100).toFixed(2);
                    return item;
                })
                this.isGenFinal = result.data.is_gen_final;
                this.untreatedCount = result.data.untreated_count;
                this.pageCount = result.data.count;
            }, err => {
                this.$message.error("获取应用结算列表失败, Err: " + err)
            })
        },

        closePane: function (id) {
            let i = 0;
            for (let item of this.panes) {
                if (item.id === id) {
                    this.panes.splice(i, 1)
                }
            }
        },

        handleGoBack: function () {
            Routers.back();
        },

        handleClickSettle: function (id, status) {
            api.checkSettlementApp(id, status).then(result => {
                if (parseInt(result.code) !== CODE_OK) {
                    this.$message.error("结算应用失败， Err: " + result.msg)
                    return
                }
                this.update();

                // 关闭已经打开的 pane 页面
                this.$nextTick(() => {
                   this.closePane(id);
                })
            }, err => {
                this.$message.error("结算应用失败， Err: " + err)
            })
        },

        handleClickDetail: function (id, name, editable) {
            const {date} = this;
            let i = 0;
            let isOpen = false;
            for (let item of this.panes) {
                if (item.id === parseInt(id)) {
                    isOpen = true;
                }
                i ++;
            }

            if (isOpen) {
                this.tabIndex = i;
            } else {
                this.panes.push({id, name, editable, date});
                this.$nextTick(() => {
                    this.tabIndex = this.panes.length;
                })
            }
        },

        handleClosePane: function (i) {
            this.panes.splice(i-1, 1);
            this.$nextTick(() => {
                if (this.panes.length === 0) {
                    this.tabIndex = '0'
                } else {
                    this.tabIndex = this.panes.length;
                }
            })
        },

        handleClickGenFinal: function () {
            this.$refs.finalFormPreviewDialog.show();
        },

        handleClickFinal: function () {
            this.$refs.finalFormDialog.show();
        }
    }
}
</script>

<style scoped>
.header {

}

.tabs {
    margin-top: 20px;
}

.tabs .header {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.tabs .table {
    margin-top: 20px;
}

.tabs .footer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

</style>