<template>
    <div class="net-edit">
        <el-card class="card">
            <template #header>
                <el-page-header title="返回"  @back="handleBack" />
            </template>
            <div class="content">
                <div class="sub-title">广告位列表</div>
                <el-form :inline="true" label-width="68px" label-position="left">
                    <el-form-item label="渠道">
                        <el-select v-model="channel" style="width: 178px" size="small" disabled>
                            <el-option :value="1" label="优量汇"/>
                            <el-option :value="4" label="快手"/>
                            <el-option :value="5" label="Gromore"/>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="名称">
                        <el-input v-model="name" placeholder="请输入名称" size="small"/>
                    </el-form-item>

                    <el-form-item label="应用ID">
                        <el-input v-model="appId" placeholder="请输入应用ID" size="small"/>
                    </el-form-item>

                    <el-form-item label="应用名称">
                        <el-input v-model="appName" placeholder="请输入应用ID" size="small"/>
                    </el-form-item>

                    <el-form-item label="密钥">
                        <el-input v-model="appKey" placeholder="请输入密钥" size="small"/>
                    </el-form-item>

                    <el-form-item label="包名">
                        <el-input v-model="packageName" placeholder="请输入包名" size="small"/>
                    </el-form-item>

                    <el-form-item label="SHA1">
                        <el-input v-model="sha1" placeholder="请输入SHA1" size="small"/>
                    </el-form-item>
                </el-form>
            </div>
        </el-card>

        <el-card class="card" v-if="channel===2 || channel===5">

            <div class="content">
                <div class="sub-title">广告位</div>
                <el-table :data="records" border size="small">
                    <el-table-column label="ID" prop="id" width="80px" align="center"/>

                    <el-table-column label="名称" prop="name" align="center">
                         <template #default="scope">
                            <span v-if="scope.row.name.length <= 0"> - </span>
                            <span v-else>{{scope.row.name}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="广告位" prop="placementId" align="center" width="200px"/>
                    <el-table-column label="场景" prop="scene" align="center" width="160px">
                        <template v-slot="scope" >
                            <tag v-if="scope.row.scene === 1">开屏</tag>
                            <tag v-if="scope.row.scene === 2">信息流</tag>
                            <tag v-if="scope.row.scene === 3">激励视频</tag>
                            <tag v-if="scope.row.scene === 4">横幅</tag>
                            <tag v-if="scope.row.scene === 5">插屏</tag>
                        </template>
                    </el-table-column>

                    <el-table-column label="屏幕" align="center" width="100px">
                         <template #default="scope">
                            <div v-if="scope.row.orientation===0">未指明</div>
                            <div v-if="scope.row.orientation===1">竖屏</div>
                            <div v-if="scope.row.orientation===2">横屏</div>
                        </template>
                    </el-table-column>

                    <el-table-column label="ECPM" prop="ecpm" width="100px" align="center" sortable>
                         <template #default="scope">
                            <span>{{scope.row.ecpm / 100}}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="操作" width="240px" align="center">
                         <template #default="scope">
                           <el-button class="btn-edit" link type="primary" @click="handleEditRecord(scope.$index)" size="small">编辑</el-button>
                           <el-button class="btn-delete" link type="primary" @click="centerDialogVisible = true;currentRow =scope.$index" size="small">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-button class="btn-add" type="primary" size="small"  :icon="Plus" @click="handleShowRecordCreateDialog">添加</el-button>
            </div>
        </el-card>

        <el-card class="card">
            <el-button class="btn-delete" @click="handleCancel">取 消</el-button>
            <el-button type="primary" @click="handleSave">确 认</el-button>
        </el-card>

      <net-pos-create-dialog ref="netPosCreateDialog" v-on:ok="handleCreatePos"/>
      <net-pos-edit-dialog ref="netPosEditDialog"  v-on:ok = "handleEditPos" />
    </div>
  <el-dialog
      v-model="centerDialogVisible"
      title="警告"
      width="500"
      align-center
  >
    <span>请确认是否要删除广告位配置。</span>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="handleDeleteRecord(this.currentRow)">确认</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>

import * as api from "../../api/api";
import {CODE_OK} from "@/api/request";

import NetPosCreateDialog from "./NetPosCreateDialog.vue";
import NetPosEditDialog from "./NetPosEditDialog.vue";
import Tag from "../../component/Tag.vue";
import Routers from "../../router/"
import {ArrowLeft, Plus} from "@element-plus/icons";

export default {
    name: "NetEdit",
  computed: {
    ArrowLeft() {
      return ArrowLeft
    },
    Plus() {
      return Plus
    }
  },

    components: {Tag, NetPosCreateDialog,NetPosEditDialog},

    data: function () {
        return {
            id: "",
            centerDialogVisible:false,
            currentRow: -1,
            name: "",
            channel: "",
            appId: "",
            appName: "",
            appKey: "",
            uintId: "",
            packageName: "",
            sha1: "",
            records: []
        }
    },

    mounted() {
        this.initView()
    },

    methods: {

        initView: function () {
            const {id} = this.$route.query
            this.id = Number.parseInt(id);
            this.update();
        },


        update: function () {
            const {id} = this;
            api.getNetwork(id).then(result => {
                if (parseInt(result.code) !== CODE_OK) {
                    this.$message.error("获取失败, Err: " + result.msg);
                    return;
                }
                this.channel = result.data.channel;
                this.name = result.data.name;
                this.appId = result.data.app_id;
                this.appName = result.data.app_name;
                this.appKey = result.data.app_key;
                this.packageName = result.data.package_name;
                this.sha1 = result.data.sha1;
                this.records = result.data.records.map(item => {
                    return {
                        id: item.id,
                        placementId: item.placement_id,
                        name: item.name,
                        uintId: item.uint_id,
                        scene: item.scene,
                        orientation: item.orientation,
                        ecpm: item.ecpm
                    }
                });
            }, err => {
                this.$message.error("获取失败, Err: " + err);
            })
        },

        handleBack: function () {
            Routers.back();
        },

        handleDeleteRecord: function (index) {
          this.centerDialogVisible = false
          this.records.splice(index, 1);
        },

       handleEditRecord:function (index) {

         this.$refs.netPosEditDialog.show(index,this.records[index]);

       },

        handleShowRecordCreateDialog: function () {
            this.$refs.netPosCreateDialog.show();
        },

        handleCreatePos: function (record) {
            this.records.push(record);
        },

      handleEditPos: function (index,record) {
        this.records[index] = record;
      },

        handleCancel: function () {
            this.handleBack();
        },

        handleSave: function () {
            const {id, name, appId, appName, appKey, packageName, sha1, records} = this;
            api.updateNetwork(id, name, appId, appName, appKey, packageName, sha1, records).then(result => {
                if (parseInt(result.code) !== CODE_OK) {
                    this.$message.error("更新失败, Err: " + result.msg);
                    return
                }
                Routers.back();
            }, err => {
                this.$message.error("更新失败, Err: " + err);
            })
        }
    }
}
</script>

<style scoped>
.net-edit .card {
    margin-bottom: 16px;
}

.net-edit .content .sub-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 700;
    color: #303133;
}

.net-edit .content .btn-add {
    margin-top: 20px;
}

.net-edit .content .btn-delete {
    color: #f56c6c;
}

</style>