<template>
    <el-card class="file">
        <el-alert
            title="不可手动添加SDK条目"
            type="warning">
        </el-alert>
        &nbsp;
        <el-table :data="data">
            <el-table-column
                prop="id"
                label="ID">
            </el-table-column>

            <el-table-column
                prop="updated_at"
                label="更新日期">
            </el-table-column>

            <el-table-column
                prop="name"
                label="名称">
            </el-table-column>

            <el-table-column
                prop="version_name"
                label="版本">
            </el-table-column>

            <el-table-column
                prop="version_code"
                label="版本-code">
            </el-table-column>

            <el-table-column
                prop="size_kb"
                label="文件大小"
                :formatter="(row) => (row.size_kb / 1024).toFixed(2)  + 'M'">

            </el-table-column>

            <el-table-column
                prop="download_url"
                label="下载地址">
            </el-table-column>

            <el-table-column
                width="60"
                label="操作">
                <template v-slot:default="scope">
                    <el-button type="text" @click="() => $refs.joinSdkFileCreateDialog.open(scope.row)">编辑</el-button>
                </template>
            </el-table-column>
        </el-table>
        <join-sdk-file-create-dialog ref="joinSdkFileCreateDialog" v-on:ok="update"/>
    </el-card>
</template>

<script>
import * as api from "../../api/api"
import {CODE_OK} from "../../api/request";
import JoinSdkFileCreateDialog from "./JoinFileEditDialog.vue";

export default {
    name: "JoinFile",
    components: {JoinSdkFileCreateDialog},
    data: function () {
        return {
            data: [],
        }
    },

    mounted() {
        this.update();
    },

    methods: {
        update: function () {
            api.getIssueJoinSdkFileList().then(result => {
                if (result.code !== CODE_OK) {
                    this.$message.error("获取SDK文件列表失败, Err: " + result.msg)
                    return
                }
                this.data = result.data.list;
            }, err => {
                this.$message.error("获取SDK文件列表失败, Err: " + err)
            })
        },
    }
}
</script>

<style scoped>

.file {
    min-height: 100%;
}

</style>