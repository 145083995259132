<template>
    <div>
        <el-card class="card filter">
            <el-form inline>
                <el-form-item label="账户名称">
                    <el-input v-model="filter.search" size="small"/>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="update" size="small">筛 选</el-button>
                </el-form-item>
            </el-form>
        </el-card>

        <el-card class="card table">
            <el-table :data="data">
                <el-table-column label="创建日期" align="center" width="100px" :formatter="(row) => unixFormatter(row.created_at, 'YYYY-MM-DD')"/>
                <el-table-column label="联系人" align="center" prop="name"/>
                <el-table-column label="电话" align="center" prop="tel"/>
                <el-table-column label="公司名称" align="center" prop="company"/>
                <el-table-column label="行业" align="center" prop="industry"/>
                <el-table-column label="备注" align="center" prop="message" width="240px" show-overflow-tooltip/>
                <el-table-column label="操作" align="center">
                    <template v-slot:default="scope">
                        <el-button type="text" @click="handleDelete(scope.row.id)" size="mini">已联系</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="footer">
                <el-pagination
                    class="pagination"
                    background
                    layout="prev, pager, next"
                    @current-change="update"
                    v-model:current-page="currentPage"
                    :page-size="pageSize"
                    :total="pageCount">
                </el-pagination>
            </div>
        </el-card>
    </div>
</template>

<script>
import * as api from "../../api/api";
import {CODE_OK} from "../../api/request";
import {unixFormatter} from "../../util/format";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
    name: "Contact",

    data: function () {
        return {
            filter: {
                search: "",
            },

            data:[],
            currentPage: 1,
            pageSize: 5,
            pageCount: 0,
        };
    },

    mounted() {
        this.initView();
    },

    methods: {
        unixFormatter,

        initView: function () {
            this.update();
        },

        update: function () {
            const {filter, currentPage, pageSize} = this;
            const {search} = filter;
            api.getUserContactList(currentPage, pageSize, search).then(result => {
                if (parseInt(result.code) !== CODE_OK) {
                    this.$message.error("获取申请联系列表失败， Err: " + result.msg)
                    return
                }

                this.data = result.data.list;
                this.pageCount = result.data.count
            }, err => {
                this.$message.error("获取申请联系列表失败， Err: " + err)
            })
        },

        handleDelete: function (id) {
            this.$confirm('标记之后，无法恢复!', "警告", {center: true}).then(() => {
                api.deleteUserContact(id).then(result=>{
                    if (parseInt(result.code) !== CODE_OK) {
                        this.$message.error("标记失败， Err: " + result.msg)
                        return
                    }
                    this.update();
                }, err => {
                    this.$message.error("标记失败, Err: " + err)
                })
            }).catch(() => {});
        },
    }
}
</script>

<style scoped>

.card:not(:first-child) {
    margin-top: 20px;
}

.card.filter .el-form-item {
    margin-bottom: 0px;
}

.card.table .footer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

</style>