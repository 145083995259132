<template>
  <el-dialog class="input-dialog" v-model="dialogVisible" :title="title" width="600px"
             @close="handleCancel" :close-on-click-modal="false">
    <el-input type="textarea" v-model="value" :autosize="{ minRows: minRows, maxRows: maxRows}" :readonly="readonly"/>
    <template #footer>
      <el-button type="primary" @click="handleCancel">取消</el-button>
      <el-button @click="handleOk">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import {ref} from "vue";

export default {

  setup() {
    let dialogVisible = ref(false)
    let value = ref("")

    return {
      dialogVisible,
      value,
    }
  },
  props: {
    title: {
      type: String,
      required: false,
      default: "",
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    minRows: {
      type: Number,
      required: false,
      default: 1,
    },
    maxRows: {
      type: Number,
      required: false,
      default: 1,
    }
  },


  methods: {
    show: function (value) {
      this.dialogVisible = true;
      if (value !== undefined && value.length > 0) {
        this.value = value;
      }
      return new Promise((resolve, reject) => {
        this.action = {resolve, reject};
      });
    },

    handleOk: function () {
      this.action && this.action.resolve && this.action.resolve(this.value);
      this.dialogVisible = false;
      this.value = "";
    },

    handleCancel: function () {
      this.dialogVisible = false;
      this.value = "";
      this.action && this.action.reject && this.action.reject();
    }
  }
}
</script>

<style scoped>

.input-dialog {
}

</style>