<template>
    <div class="media">
        <el-card class="card filter">
            <el-form :inline="true">
                <el-form-item label="应用ID">
                    <el-input v-model="filter.id" placeholder="请输入应用ID" size="small"/>
                </el-form-item>

                <el-form-item label="用户名">
                    <el-input v-model="filter.username" placeholder="请输入用户名称" size="small"/>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="update" size="small">查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>

        <el-card class="card content">
            <el-empty v-if="this.data.length <= 0" description="无应用信息"></el-empty>
            <div v-else class="table">
                <el-table
                    highlight-current-row
                    :data="data"
                    style="width: 100%">

                    <el-table-column prop="app_id" label="ID" width="60" align="center"/>

                    <el-table-column
                        prop="updated_at"
                        label="创建日期"
                        :formatter="(row) => unixFormatter(row.updated_at, 'YYYY-MM-DD')"
                        align="center"/>

                    <el-table-column prop="username" label="用户" align="center" show-overflow-tooltip/>

                    <el-table-column prop="app_name" label="名称" align="center" show-overflow-tooltip/>

                    <el-table-column prop="app_package_name" label="包名" width="300" align="center" show-overflow-tooltip/>

                    <el-table-column prop="placement_count" label="广告位" align="center"/>

                    <el-table-column prop="network_count" label="网络" align="center"/>

                    <el-table-column
                        prop="app_system"
                        label="系统"
                        align="center">
                         <template #default="scope">
                            <tag>{{scope.row.app_system}}</tag>
                        </template>
                    </el-table-column>

                    <el-table-column
                        prop="app_status"
                        label="状态"
                        align="center">
                         <template #default="scope">
                            <tag v-if="scope.row.status===0">待审核</tag>
                            <tag v-else-if="scope.row.status===1">运行中</tag>
                            <tag v-else-if="scope.row.status===2">停止中</tag>
                            <tag v-else-if="scope.row.status===3">审核失败</tag>
                            <tag v-else-if="scope.row.status===4">测试</tag>
                            <tag v-else-if="scope.row.status===5">异常停止</tag>
                        </template>
                    </el-table-column>

                    <el-table-column
                        width="80"
                        label="操作"
                        align="center">
                        <template v-slot:default="scope">
                            <el-button type="text" @click="handleEdit(scope.row.app_id)" size="mini">编辑</el-button>
                        </template>
                    </el-table-column>

                </el-table>
                <div class="footer">
                    <el-pagination
                        class="pagination"
                        background
                        layout="prev, pager, next"
                        @current-change="handleChangeCurrentPage"
                        :page-size="this.pageSize"
                        :total="this.pageCount">
                    </el-pagination>
                </div>
            </div>
        </el-card>
    </div>
</template>

<script>
import * as api from "../../api/api";
import {CODE_OK} from "../../api/request";
import {unixFormatter} from "../../util/format";
import Tag from "../../component/Tag.vue";
import Routers from "../../router/"

export default {
  // eslint-disable-next-line vue/multi-word-component-names
    name: "Media",
    components: {Tag},
    data: function () {
        return {
            currentPage: 1,
            pageSize: 10,
            pageCount: 0,
            filter: {
                id: "",
                username: "",
            },
            data: [],

            createNetworkVisible: false,
            createNetwork: {
                appId: "",
                channel: 1,
                channelId: "",
                channelPackageName: "",
            }
        }
    },

    created() {
        this.update();
    },

    methods: {
        unixFormatter,

        update: function () {
            const {currentPage, pageSize, filter} = this;
            const {id, username} = filter;
            api.getMediaAppList(currentPage, pageSize, id, username).then(result => {
                if (parseInt(result.code) !== CODE_OK) {
                    this.$message.error("获取应用列表失败， Err: " + result.msg)
                    return
                }

                this.data = result.data.list;
                this.pageCount = result.data.count
            }, err => {
                this.$message.error("获取应用列表失败, Err: " + err)
            })
        },

        handleChangeCurrentPage: function (page) {
            this.currentPage = page
            this.update()
        },

        handleEdit: function (appId) {
            Routers.push({
                path: "/dashboard/media/app/edit",
                query: {
                    appId: appId,
                }
            });
        }
    }
}
</script>

<style scoped>

.media {
    min-height: 100%;
}

.media .card.filter .el-form-item {
    margin-bottom: 0 !important;
}

.media .card:not(:first-child) {
    margin-top: 20px;
}

.media .content .footer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.media .content .el-tag {
    width: 70px;
    text-align: center;
}

</style>