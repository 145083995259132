<template>
    <div class="page">
        <el-card class="card summary">
            <div class="form">
                <div class="form-item">
                    <div class="name">昨日收益（元）</div>
                    <div class="revenue">{{yesterRevenue}}</div>
                </div>
                <el-divider class="divider" direction="vertical"/>
                <div class="form-item">
                    <div class="name">近七日收益（元）</div>
                    <div class="revenue">{{weekRevenue}}</div>
                </div>
                <el-divider class="divider" direction="vertical"/>
                <div class="form-item">
                    <div class="name">本月收益（元）</div>
                    <div class="revenue">{{monthRevenue}}</div>
                </div>
                <el-divider class="divider" direction="vertical"/>
                <div class="form-item">
                    <div class="name">上月收益（元）</div>
                    <div class="revenue">{{preMonthRevenue}}</div>
                </div>
            </div>
        </el-card>

        <el-card class="card filter">
            <div class="forms">
                <el-form inline>
                    <el-form-item class="form-item">
                        <el-select v-model="filter.unit" style="width: 150px" size="small">
                            <el-option :value="1" label="按天" />
                            <el-option :value="7" label="按周"/>
                            <el-option :value="30" label="按月"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item class="form-item">
                        <el-date-picker
                            v-model="filter.dateRange"
                            size="small"
                            @change="handleChangeDateRange"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item class="form-item">
                        <el-checkbox v-model="filter.isComparedTime" @change="handleClickCompared"
                                     :disabled="graph.isComparedKpi || filter.isComparedChannel" size="small">对比</el-checkbox>
                    </el-form-item>
                    <el-form-item class="form-item" v-if="filter.isComparedTime">
                        <el-date-picker
                            v-model="filter.comparedDateRange"
                            size="small"
                            @change="handleChangeComparedDateRange"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>

                </el-form>

                <el-form inline>
                    <el-form-item>
                        <el-select v-model="filter.scene" placeholder="请选择场景" style="width: 150px" default-first-option size="small">
                            <template :key="i" v-for="(item, i) of filter.sceneOptions">
                                <el-option  :value="item.value" :label="item.name"/>
                            </template>
                        </el-select>
                    </el-form-item>

                    <el-form-item>
                        <el-select v-model="filter.channel" placeholder="请选择渠道" style="width: 150px" default-first-option size="small">
                            <template :key="i" v-for="(item, i) of filter.channelOptions">
                                <el-option  :value="item.value" :label="item.name"/>
                            </template>
                        </el-select>
                    </el-form-item>

                    <el-form-item>
                        <el-checkbox v-model="filter.isComparedChannel" @change="drawGraph"
                                     :disabled="graph.isComparedKpi || filter.isComparedTime" size="small">对比</el-checkbox>
                    </el-form-item>

                    <el-form-item v-if="filter.isComparedChannel">
                        <el-select v-model="filter.comparedChannel" placeholder="请选择渠道" default-first-option size="small">
                            <template :key="i" v-for="(item, i) of filter.channelOptions">
                                <el-option  :value="item.value" :label="item.name"/>
                            </template>
                        </el-select>
                    </el-form-item>

                    <el-form-item class="form-item">
                        <el-button type="primary" @click="update" size="small">查找</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-card>

        <el-card class="card graph">
          <div>收益趋势</div>
          <el-divider />
            <div class="content">
                <div class="filter">
                    <el-form inline>

                        <el-form-item>
                            <el-select v-model="graph.kpi" @change="drawGraph" size="small" style="width: 150px">
                                <template :key="item.id" v-for="item in graph.kpiOptions">
                                    <el-option  :value="item.value" :label="item.name"/>
                                </template>
                            </el-select>
                        </el-form-item>

                        <el-form-item disabled>
                            <el-checkbox v-model="graph.isComparedKpi" @change="drawGraph"
                                         :disabled="filter.isComparedChannel || filter.isComparedTime">对比</el-checkbox>
                        </el-form-item>

                        <el-form-item v-if="graph.isComparedKpi">
                            <el-select v-model="graph.comparedKpi" @change="drawGraph" size="small" style="width: 150px">
                                <template :key="item.id" v-for="item in graph.kpiOptions">
                                    <el-option  :value="item.value" :label="item.name"/>
                                </template>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </div>
                <div id="graph-container" :style="{width: 'auto', height: '500px'}"/>
            </div>
        </el-card>

        <el-card class="card table">
          <div><slot name="header">收益明细</slot></div>
            <div class="filter">
                <span>数据纬度: &nbsp;</span>
                <el-checkbox-group class="checkbox-group" v-model="table.filter.dataFields">
                    <el-checkbox v-for="field in table.filter.dataFieldOptions" :key="field.value" :label="field.value">{{field.name}}</el-checkbox>
                </el-checkbox-group>
            </div>

            <div class="filter">
                <span>指标纬度: &nbsp;</span>
                <el-checkbox-group v-model="table.filter.kpiFields" @change="handleChangeKpiField">
                    <el-checkbox v-for="field in table.filter.kpiFieldOptions" :key="field.value" :label="field.value">{{field.name}}</el-checkbox>
                </el-checkbox-group>
            </div>

            <div class="content">
                <el-table :data="table.items.slice((currentPage - 1) * pageSize, (currentPage - 1) * pageSize + pageSize)"
                          show-summary
                          :summary-method="getTableSummary">
                    <el-table-column fixed label="日期" align="center" width="120px"  v-if="inKpiField('date')">
                         <template #default="scope">
                            <template v-if="scope.row.start_date === scope.row.end_date">
                                <span>{{unixFormatter(scope.row.start_date, 'YYYY-MM-DD')}}</span>
                            </template>
                            <template v-else>
                                <span>{{unixFormatter(scope.row.start_date, 'YYYY-MM-DD')}}</span>
                                <br/>
                                <span>{{unixFormatter(scope.row.end_date, 'YYYY-MM-DD')}}</span>
                            </template>
                        </template>
                    </el-table-column>

                    <el-table-column fixed label="广告位类型" align="center" width="160px" v-if="inKpiField('scene')">
                         <template #default="scope">
                            <tag v-if="scope.row.scene === 1" effect="plain">开屏</tag>
                            <tag v-if="scope.row.scene === 2" effect="plain">信息流</tag>
                            <tag v-if="scope.row.scene === 3" effect="plain">激励视频</tag>
                            <tag v-if="scope.row.scene === 4" effect="plain">横幅</tag>
                            <tag v-if="scope.row.scene === 5" effect="plain">插屏</tag>
                        </template>
                    </el-table-column>

                    <el-table-column fixed prop="channel" label="渠道" width="160px" align="center" v-if="inKpiField('channel')">
                         <template #default="scope">
                            <tag v-if="scope.row.channel === 0">全部</tag>
                            <tag v-else-if="scope.row.channel === 1">优量汇</tag>
                            <tag v-else-if="scope.row.channel === 2" >穿山甲</tag>
                            <tag v-else-if="scope.row.channel === 4">快手</tag>
                            <tag v-else-if="scope.row.channel === 5">Gromore</tag>
                            <tag v-else effect="plain">未知</tag>
                        </template>
                    </el-table-column>

                    <el-table-column fixed prop="revenue" label="预估收益(元)" width="140px" align="center"  v-if="inDataField('revenue')">
                         <template #default="scope">
                            <span>{{(scope.row.revenue / 100).toFixed(2)}}</span>
                        </template>
                    </el-table-column>

                    <el-table-column fixed label="ecpm(元/千次)" align="center" width="140px" prop="ecpm" v-if="inDataField('ecpm')">
                         <template #default="scope">
                            <template v-if="scope.row.pv_count > 0">
                                {{((scope.row.revenue/(scope.row.pv_count/1000))/100).toFixed(2)}}
                            </template>
                            <template v-else>
                                -
                            </template>
                        </template>
                    </el-table-column>

                    <el-table-column prop="request_count" width="136px" label="请求量" align="center" v-if="inDataField('request_count')"/>

                    <el-table-column prop="return_count" width="136px" label="返回量" align="center" v-if="inDataField('return_count')"/>

                    <el-table-column prop="return_rate" width="136px" label="填充率" align="center" v-if="inDataField('return_rate')">
                         <template #default="scope">
                            <template v-if="scope.row.request_count > 0">
                                {{(scope.row.return_count/scope.row.request_count*100).toFixed(2)}}%
                            </template>
                            <template v-else>
                                -
                            </template>
                        </template>
                    </el-table-column>

                    <el-table-column prop="pv_count" width="136px" label="曝光量" align="center" v-if="inDataField('pv_count')"/>

                    <el-table-column prop="pv_rate" width="136px" label="曝光率" align="center" v-if="inDataField('pv_rate')">
                         <template #default="scope">
                            <template v-if="scope.row.return_count > 0">
                                {{(scope.row.pv_count/scope.row.return_count*100).toFixed(2)}}%
                            </template>
                            <template v-else>-</template>
                        </template>
                    </el-table-column>

                    <el-table-column prop="click_count" width="136px" label="点击量" align="center" v-if="inDataField('click_count')"/>

                    <el-table-column prop="click_rate" width="136px" label="点击率" align="center" v-if="inDataField('click_rate')">
                         <template #default="scope">
                            <template v-if="scope.row.pv_count > 0">
                                {{(scope.row.click_count/scope.row.pv_count*100).toFixed(2)}}%
                            </template>
                            <template v-else>
                                -
                            </template>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="footer">
                <el-pagination
                    class="pagination"
                    background
                    layout="prev, pager, next"
                    v-model:current-page="currentPage"
                    :page-size="pageSize"
                    :total="pageCount">
                </el-pagination>
            </div>
        </el-card>
    </div>
</template>

<script>

import * as api from "../../api/api";
import {CODE_OK} from "../../api/request";
import {unixFormatter, dateFormatter} from "../../util/format";
import * as echarts from "echarts"
import moment from 'moment'
import Tag from "../../component/Tag.vue";

export default {
    name: "PlatformEarnings",
    components: {Tag},
    data: function () {
        return {
            filter: {
                unit: 1,
                dateRange: [new Date(), new Date()],
                comparedDateRange: [],
                channel: 0,
                comparedChannel: 0,
                scene: 0,
                channelOptions: [
                    {name: "全部渠道", value: 0},
                    {name: "优量汇", value: 1},
                    {name: "快手", value: 4},
                    {name: "Gromore", value: 5},
                ],

                sceneOptions: [
                    {name: "全部类型", value: 0},
                    {name: "信息流", value: 2},
                    {name: "开屏", value: 1},
                    {name: "激励视频", value: 3},
                    {name: "横幅", value: 4},
                    {name: "插屏", value: 5},
                ],
                isComparedTime: false,
                isComparedChannel: false,
            },
            preMonthRevenue: 0,
            monthRevenue: 0,
            weekRevenue: 0,
            yesterRevenue: 0,
            graph:{
                kpi: "revenue",
                isComparedKpi: false,
                comparedKpi: "revenue",
                kpiOptions: [
                    {value: "revenue", name: "预估收益"},
                    {value: "ecpm", name: "ECPM"},
                    {value: "request_count", name: "请求量"},
                    {value: "return_count", name: "返回量"},
                    {value: "return_rate", name: "填充率"},
                    {value: "pv_count", name: "曝光量"},
                    {value: "pv_rate", name: "曝光率"},
                    {value: "click_count", name: "点击量"},
                    {value: "click_rate", name: "点击率"},
                ],
                items: {},
                comparedItems:[]
            },
            table:{
                origin: [],
                items: [],
                filter: {
                    dataFields:["revenue", "ecpm", "revenue","request_count", "return_count", "return_rate", "pv_count", "pv_rate", "click_count", "click_rate"],
                    dataFieldOptions:[
                        {value: "revenue", name:"预估收益"},
                        {value: "ecpm", name:"ECPM"},
                        {value: "request_count", name: "请求量"},
                        {value: "return_count", name: "返回量"},
                        {value: "return_rate", name: "填充率"},
                        {value: "pv_count", name: "曝光量"},
                        {value: "pv_rate", name: "曝光率"},
                        {value: "click_count", name: "点击量"},
                        {value: "click_rate", name: "点击率"}],

                    kpiFields:["date", "scene", "channel"],
                    kpiFieldOptions: [
                        {value: "date", name: "日期"},
                        {value: "scene", name:"代码位类型"},
                        {value: "channel", name:"渠道"},
                    ],
                },
                pvCount: 0,
                requestCount: 0,
                returnCount: 0,
                clickCount: 0,
                revenue: 0,
            },
            pageSize: 10,
            pageCount: 0,
            currentPage: 1,
        }
    },

    mounted() {
        this.initView();
    },

    methods: {
        unixFormatter,

        initView: function () {
            const {date_start: dateStart, date_end: dateEnd, channel} = this.$route.query
            if (dateStart !== undefined &&  dateEnd !== undefined) {
                this.filter.dateRange = [new Date(parseInt(dateStart) * 1000), new Date(parseInt(dateEnd) * 1000)]
            } else {
                const now = new Date();
                const nowUint = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
                const end = moment(nowUint).clone().subtract(1, "day").toDate()
                const start = moment(nowUint).clone().subtract(8, "day").toDate()
                this.filter.dateRange = [start, end]
            }

            const [start, end] = this.filter.dateRange;

            const diffDays = moment(end).diff(moment(start), "days");

            const comparedEnd = moment(start).clone().subtract(1, "day").toDate();
            const comparedStart = moment(start).clone().subtract(diffDays + 1, "day").toDate();

            this.filter.comparedDateRange = [comparedEnd, comparedStart]

            if (channel !== undefined) {
                this.filter.channel = parseInt(channel);
            }

            this.graphElement = echarts.init(document.getElementById("graph-container"))

            this.updateSummary();
            this.update();
        },

        updateSummary: function () {
            api.getEarningPlatform().then(result => {
                if (parseInt(result.code) !== CODE_OK) {
                    this.$message.error("请求失败， Err: " + result.msg)
                    return
                }
                this.preMonthRevenue = result.data.pre_month_revenue / 100;
                this.monthRevenue = result.data.month_revenue / 100;
                this.weekRevenue = result.data.week_revenue / 100;
                this.yesterRevenue = result.data.yester_revenue / 100;
            }, err => {
                this.$message.error("请求失败， Err: " + err);
            })
        },

        update: function () {
            let {dateRange, comparedDateRange, unit, channel, comparedChannel, scene, isComparedTime} = this.filter;
            if (isComparedTime) {
                comparedChannel = channel;
            }

            const comparedStart =  isComparedTime ? parseInt(comparedDateRange[0].getTime() / 1000) : 0;
            const comparedEnd = isComparedTime ? parseInt(comparedDateRange[1].getTime() / 1000) : 0;

            api.getEarningsPlatformReport(unit,
                parseInt(dateRange[0].getTime() / 1000),
                parseInt(dateRange[1].getTime() / 1000),
                comparedStart, comparedEnd, channel, comparedChannel, scene).then(result => {
                if (parseInt(result.code) !== CODE_OK) {
                    this.$message.error("请求失败， Err: " + result.msg);
                    return
                }
                this.currentPage = 1;

                const {report, graph} = result.data;
                const {list, pv_count, request_count, return_count, click_count, revenue} = report;
                this.table.origin = list;
                this.table.items = this.mergeTableItems(this.table.origin);
                this.table.pvCount = pv_count;
                this.table.requestCount = request_count;
                this.table.returnCount = return_count;
                this.table.clickCount = click_count;
                this.table.revenue = revenue;
                this.pageCount = list.length;

                this.graph.items = this.buildGraph(graph.items);
                this.graph.comparedItems = this.buildGraph(graph.compared_items);
                this.drawGraph();
            }, err => {
                this.$message.error("请求失败， Err: " + err);
            })
        },

        handleChangeDateRange: function () {
            const start = this.filter.dateRange[0];
            const end = this.filter.dateRange[1];
            const diffDays = moment(end).diff(moment(start), "days");

            const comparedEnd = moment(start).clone().subtract(1, "day").toDate();
            const comparedStart = moment(start).clone().subtract(diffDays + 1, "day").toDate();

            this.filter.comparedDateRange = [comparedStart, comparedEnd];
        },

        handleChangeComparedDateRange: function () {
            const comparedStart = this.filter.comparedDateRange[0];
            const comparedEnd = this.filter.comparedDateRange[1];
            const diffDays = moment(comparedEnd).diff(moment(comparedStart), "days");

            const start = moment(comparedEnd).clone().add(1, "day").toDate();
            const end = moment(comparedEnd).clone().add(diffDays + 1, "day").toDate();

            this.filter.dateRange = [start, end];
        },

        handleClickCompared: function (value) {
            if (value) {
                const start = this.filter.dateRange[0];
                const end = this.filter.dateRange[1];
                const diffDays = moment(end).diff(moment(start), "days");

                const comparedEnd = moment(start).clone().subtract(1, "day").toDate();
                const comparedStart = moment(start).clone().subtract(diffDays + 1, "day").toDate();

                this.filter.comparedDateRange = [comparedStart, comparedEnd];

            }

            this.drawGraph();
        },

        buildGraph: function (items) {
            const graphItems = {};
            for (let kpi of this.graph.kpiOptions) {
                let graph = {}

                // placeholder date
                for (let item of items) {
                    let value = item[kpi.value];
                    if (kpi.value === "revenue") {
                        value = (item[kpi.value] / 100).toFixed(2); // 保留两位小数
                    } else if(kpi.value === "ecpm") {
                        value = item["pv_count"] === 0 ? 0 : (item["revenue"] / (item["pv_count"] / 1000) / 100).toFixed(2);
                    } else if (kpi.value === "return_rate") {
                        value = item["request_count"] === 0 ? 0 : (item["return_count"] / item["request_count"]).toFixed(2);
                    } else if (kpi.value === "pv_rate") {
                        value = item["return_count"] === 0 ? 0 : (item["pv_count"] / item["return_count"]).toFixed(2);
                    } else if (kpi.value === "click_rate") {
                        value = item["pv_count"] === 0 ? 0 : (item["click_count"] / item["pv_count"]).toFixed(2);
                    }

                    graph[item.start_date + "%" + item.end_date] = value;
                }

                const keys = Object.keys(graph).sort((a, b) => {
                    const o1 = a.split("%");
                    const o2 = b.split("%");
                    return (parseInt(o1[0]) + parseInt(o1[1])) - (parseInt(o2[0]) + parseInt(o2[1])) ;
                });

                let graphX = [];
                let graphY = [];
                for (let key of keys) {
                    const tms = key.split("%");
                    let tm = "";
                    if (tms.length === 2 && tms[0] !== tms[1]) {
                        tm = unixFormatter(parseInt(tms[0]), 'YYYY-MM-DD') + "/"
                            + unixFormatter(parseInt(tms[1]), 'YYYY-MM-DD');
                    } else {
                        tm = unixFormatter(parseInt(tms[0]), 'YYYY-MM-DD')
                    }
                    graphX.push(tm);
                    graphY.push(graph[key]); // 保留两位小数

                }

                graphItems[kpi.value] = {x: graphX, y: graphY};
            }
            return graphItems;
        },

        drawGraph: function () {
            const {isComparedTime, isComparedChannel} = this.filter;
            const {items, comparedItems, kpi, comparedKpi: comparedKpi, isComparedKpi} = this.graph;
            const {x, y} = items[kpi]
            let option = {};

            const tooltip = {
                trigger: 'axis',
                    formatter: function (params) {
                    let html=params[0].name+"<br>";
                    for(let i=0; i<params.length; i++){
                        let value = ""
                        const valueType = option.series[params[i].seriesIndex].valueType;
                        if(valueType.endsWith("rate")){
                            value = (params[i].value * 100).toFixed(2) + "%";
                        } else {
                            value = params[i].value;
                        }
                        if (i !== (params.length-1)) {
                            value += "<br>"
                        }
                        html+= params[i].marker+ params[i].seriesName+":&nbsp;"+ value;
                    }
                    return html+"<br>";
                }
            }

            const kpiName = this.findKpiName(kpi);
            const comparedKpiName = this.findKpiName(comparedKpi)
            if (isComparedTime) {
                const {dateRange, comparedDateRange} = this.filter;
                const {y: comparedY, x: comparedX} = comparedItems[kpi];

                const legend = dateFormatter(dateRange[0], "YYYY_MM_DD") + " - "
                    + dateFormatter(dateRange[1], "YYYY_MM_DD");

                const legend2 = dateFormatter(comparedDateRange[0], "YYYY_MM_DD") + " - "
                    + dateFormatter(comparedDateRange[1], "YYYY_MM_DD");

                const date = [x,comparedX];

                option = {
                    color: ["#f56c6c", "#c0f1a5"],
                    tooltip: {
                        trigger: 'axis',
                        formatter: function (params) {
                            let html=kpiName+"<br>";
                            for(let i=0; i<params.length; i++){
                                let value = ""
                                const valueType = option.series[params[i].seriesIndex].valueType;
                                if(valueType.endsWith("rate")){
                                    value = (params[i].value * 100).toFixed(2) + "%";
                                } else {
                                    value = params[i].value;
                                }
                                if (i !== (params.length-1)) {
                                    value += "<br>"
                                }
                                html+= params[i].marker+ date[i][params[i].dataIndex]+":&nbsp;"+ value;
                            }
                            return html+"<br>";
                        }
                    },
                    legend: {
                        data: [legend, legend2],
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: x
                        },
                        {
                            type: 'category',
                            position: 'bottom',
                            axisTick: {
                                show: false
                            },
                            axisLine: {
                                show: false
                            },
                            offset: 25,
                            data: comparedX
                        }
                    ],
                    yAxis: {
                        type: 'value',
                        name: kpiName,
                        axisLine: {
                            show: true,
                        },
                        axisLabel: {
                            formatter: function (value) {
                                if (kpi.endsWith("rate")) {
                                    return (value * 100).toFixed(2) + '%';
                                }
                                return value;
                            }
                        }
                    },
                    series: [
                        {
                            name: legend,
                            data: y,
                            type: 'line',
                            smooth: true,
                            emphasis: {
                                focus: 'series'
                            },
                            valueType: kpi,
                        },
                        {
                            name: legend2,
                            color: "#c0f1a5",
                            data: comparedY,
                            type: 'line',
                            smooth: true,
                            emphasis: {
                                focus: 'series'
                            },
                            valueType: kpi,
                        }
                    ]
                }
            } else if (isComparedKpi) {
                const {y: comparedY} = items[comparedKpi]
                option = {
                    color: ["#f56c6c", "#c0f1a5"],
                    legend: {
                        data: [kpiName, comparedKpiName],
                        bottom: 10,
                    },
                    tooltip: tooltip,
                    xAxis: [
                        {
                            type: 'category',
                            data: x
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            axisLine: {
                                show: true,
                            },
                            axisLabel: {
                                formatter: function (value) {
                                    if (kpi.endsWith("rate")) {
                                        return (value * 100).toFixed(2) + '%';
                                    }
                                    return value;
                                }
                            }
                        }, {
                            type: 'value',
                            name: comparedKpiName,
                            position: 'right',
                            axisLine: {
                                show: true,
                            },
                            axisLabel: {
                                formatter: function (value) {
                                    if (comparedKpi.endsWith("rate")) {
                                        return (value * 100).toFixed(2) + '%';
                                    }
                                    return value;
                                }
                            }
                        }
                    ],
                    series: [
                        {
                            data: y,
                            name: kpiName,
                            type: 'line',
                            smooth: true,
                            emphasis: {
                                focus: 'series'
                            },
                            valueType: kpi,
                        }, {
                            data: comparedY,
                            name: comparedKpiName,
                            yAxisIndex: 1,
                            type: 'line',
                            color: "#c0f1a5",
                            smooth: true,
                            emphasis: {
                                focus: 'series'
                            },
                            valueType: comparedKpi,
                        }
                    ]
                }
            } else if (isComparedChannel) {
                const {y: comparedY, x: comparedX} = comparedItems[kpi];
                const {channel, comparedChannel} = this.filter;
                const channelName = this.findChannelName(channel);
                const comparedChannelName = this.findChannelName(comparedChannel);

                option = {
                    color: ["#f56c6c", "#c0f1a5"],
                    tooltip: tooltip,
                    xAxis: [
                        {
                            type: 'category',
                            data: x
                        },
                        {
                            type: 'category',
                            position: 'bottom',
                            axisTick: {
                                show: false
                            },
                            axisLine: {
                                show: false
                            },
                            offset: 25,
                            data: comparedX
                        }
                    ],
                    yAxis: {
                        type: 'value',
                        name: kpiName,
                        axisLine: {
                            show: true,
                        },
                        axisLabel: {
                            formatter: function (value) {
                                if (kpi.endsWith("rate")) {
                                    return (value * 100).toFixed(2) + '%';
                                }
                                return value;
                            }
                        }
                    },
                    series: [
                        {
                            data: y,
                            name: channelName,
                            type: 'line',
                            smooth: true,
                            emphasis: {
                                focus: 'series'
                            },
                            valueType: kpi,
                        },
                        {
                            data: comparedY,
                            name: comparedChannelName,
                            type: 'line',
                            smooth: true,
                            emphasis: {
                                focus: 'series'
                            },
                            valueType: kpi,
                        }
                    ]
                }
            } else {
                option = {
                    color: ["#f56c6c"],
                    tooltip: tooltip,
                    xAxis: [
                        {
                            type: 'category',
                            data: x
                        }
                    ],
                    yAxis: {
                        type: 'value',
                        name: kpiName,
                        axisLine: {
                            show: true,
                        },
                        axisLabel: {
                            formatter: function (value) {
                                if (kpi.endsWith("rate")) {
                                    return (value * 100).toFixed(2) + '%';
                                }
                                return value;
                            }
                        }
                    },
                    series: [
                        {
                            name: kpiName,
                            data: y,
                            type: 'line',
                            smooth: true,
                            emphasis: {
                                focus: 'series'
                            },
                            valueType: kpi,
                        }
                    ]
                }
            }

            this.graphElement.setOption(option, {notMerge: true})
        },

        findKpiName: function (value) {
            let name = "";
            for (let opt of this.graph.kpiOptions) {
                if (opt.value === value) {
                    name = opt.name;
                }
            }
            return name;
        },

        findChannelName: function (value) {
            let name = "";
            for (let opt of this.filter.channelOptions) {
                if (opt.value === value) {
                    name = opt.name;
                }
            }
            return name;
        },

        handleChangeKpiField: function () {
            this.table.items = this.mergeTableItems(this.table.origin);
            this.pageCount = this.table.items.length;
            this.currentPage = 1;
        },

        mergeTableItems: function (origin) {
            const {kpiFields} = this.table.filter;
            const merge = {};

            const items = origin.sort((a, b) => ((b.start_date + b.end_date) - (a.start_date + a.end_date)))
            for (let i=0; i < items.length; i++) {
                const item = items[i];

                let key = ""
                if (kpiFields.includes("date")) {
                    key += "_" + item.start_date + "_" + item.end_date;
                }
                if (kpiFields.includes("scene")) {
                    key += "_" + item.scene
                }
                if (kpiFields.includes("channel")) {
                    key += "_" + item.channel
                }
                if (merge[key] !== undefined) {
                    const oldItem = merge[key];
                    const newItem = {
                        revenue: oldItem.revenue + item.revenue,
                        request_count: oldItem.request_count + item.request_count,
                        return_count: oldItem.return_count + item.return_count,
                        pv_count: oldItem.pv_count + item.pv_count,
                        click_count: oldItem.click_count + item.click_count,
                    }

                    if (kpiFields.includes("date")) {
                        newItem.start_date = oldItem.start_date;
                        newItem.end_date = oldItem.end_date;
                    }
                    if (kpiFields.includes("scene")) {
                        newItem.scene = oldItem.scene;
                    }
                    if (kpiFields.includes("channel")) {
                        newItem.channel = oldItem.channel;
                    }
                    merge[key] = newItem;
                } else {
                    merge[key] = {
                        revenue: item.revenue,
                        request_count: item.request_count,
                        return_count: item.return_count,
                        pv_count: item.pv_count,
                        click_count: item.click_count,
                    }

                    if (kpiFields.includes("date")) {
                        merge[key].start_date = item.start_date;
                        merge[key].end_date = item.end_date;
                    }
                    if (kpiFields.includes("scene")) {
                        merge[key].scene = item.scene;
                    }
                    if (kpiFields.includes("channel")) {
                        merge[key].channel = item.channel;
                    }
                }
            }

            const newItems = [];
            for (let key in merge) {
                newItems.push(merge[key])
            }

            return newItems;
        },

        inDataField: function(value) {
            for (let field of this.table.filter.dataFields) {
                if (value === field) {
                    return true
                }
            }
            return false;
        },

        inKpiField: function (value) {
            for (let field of this.table.filter.kpiFields) {
                if (value === field) {
                    return true
                }
            }
            return false;
        },

        getTableSummary: function () {

            const summary = [];
            const {revenue, requestCount, returnCount, pvCount, clickCount} = this.table;

            if (this.inKpiField("date")) {
                summary.push("总计")
            }

            if (this.inKpiField("scene")) {
                summary.push("");
            }

            if (this.inKpiField("channel")) {
                summary.push("");
            }

            if (this.inDataField("revenue")) {
                summary.push((revenue/100).toFixed(2));
            }

            if (this.inDataField("ecpm")) {
                const ecpm = (revenue * 1000 / pvCount / 100).toFixed(2)
                summary.push(ecpm);
            }

            if (this.inDataField("request_count")) {
                summary.push(requestCount);
            }

            if (this.inDataField("return_count")) {
                summary.push(returnCount);
            }

            if (this.inDataField("return_rate")) {
                const returnRate = (returnCount / requestCount * 100).toFixed(2) + "%";
                summary.push(returnRate);
            }

            if (this.inDataField("pv_count")) {
                summary.push(pvCount);
            }

            if (this.inDataField("pv_rate")) {
                const pvRate = (pvCount / returnCount * 100).toFixed(2) + "%";
                summary.push(pvRate);
            }

            if (this.inDataField("click_count")) {
                summary.push(clickCount);
            }

            if (this.inDataField("click_rate")) {
                const clickRate = (clickCount / pvCount * 100).toFixed(2) + "%";
                summary.push(clickRate);
            }

            return summary;
        }
    }
}
</script>

<style scoped>

.page {
    min-height: 100%;
}

.page .card:not(:first-child) {
    margin-top: 20px;
}

.page .card.summary .form {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.page .card.summary .form .form-item {
    height: 120px;
    flex-grow: 1;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.page .card.summary .form .form-item .revenue {
    font-size: 28px;
    color: #1f1f1f;
    line-height: 28px;
    font-weight: 700;
    margin-top: 12px;
    margin-bottom: 10px;
    text-align: center;
}

.page .card.summary .form .divider {
    height: 100px;
}

.page .card.summary .form .form-item .name {
    font-size: 14px;
    color: #434343;
    font-weight: 400;
}

.page .card.filter .forms > .el-form:last-child .el-form-item {
    margin-bottom: 0;
}

.page .card.graph .filter {
    display: flex;
    justify-content: right;
}

.page .card.table .filter {
    display: flex;
    margin-bottom: 16px;
    align-items: center;
}

.page .card.table .footer .pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.page >>> .el-table .el-table__body-wrapper {
    order: 1 !important;
}
.page >>> .el-table__fixed-body-wrapper{
    top:96px !important;
}

.page >>> .el-table__fixed-footer-wrapper{
    z-index: 0;
    top:47px;
}

</style>