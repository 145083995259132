<template>
    <div>
        <el-card class="card filter">
            <el-form inline>
                <el-form-item>
                    <el-date-picker
                        v-model="filter.date"
                        type="month"
                        placeholder="选择月">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleClickSearch">查找</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button class="filter-clear" type="text" @click="handleClickClear">清空搜索条件</el-button>
                </el-form-item>
            </el-form>
        </el-card>

        <el-card class="card content">
            <el-table :data="data">
                <el-table-column prop="date" label="核算时间" align="center">
                    <template v-slot:default="scope">
                        <span>{{unixFormatter(scope.row.date, 'YYYY-MM')}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="渠道" align="center">
                    <template v-slot:default="scope">
                        <tag v-if="scope.row.channel === 1">优量汇</tag>
                        <tag v-else-if="scope.row.channel === 2" >穿山甲</tag>
                        <tag v-else-if="scope.row.channel === 4">快手</tag>
                        <tag v-else-if="scope.row.channel === 5">Gromore</tag>
                        <tag v-else effect="plain">未知</tag>
                    </template>
                </el-table-column>
                <el-table-column prop="est_revenue" label="预估金额" align="center">
                    <template v-slot:default="scope">
                        <span>{{(scope.row.est_revenue/100).toFixed(2)}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="实际金额" align="center">
                    <template v-slot:default="scope">
                        <span>{{(scope.row.final_revenue/100).toFixed(2)}}</span>
                    </template>
                </el-table-column>

                <el-table-column label="操作" align="center">
                    <template v-slot:default="scope">
                        <el-button type="text" @click="handleClickDetail(scope.row.date, scope.row.channel)">明细</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <div class="footer">
                <el-pagination
                    class="pagination"
                    background
                    layout="prev, pager, next"
                    @current-change="update"
                    v-model:current-page="currentPage"
                    :page-size="pageSize"
                    :total="pageCount">
                </el-pagination>
            </div>
        </el-card>
    </div>
</template>

<script>
import * as api from "../../api/api";
import {CODE_OK} from "../../api/request";
import {unixFormatter} from "../../util/format";
import Tag from "../../component/Tag.vue";
import moment from "moment";
import Routers from "../../router/"
import router from "../../router/";

export default {
    name: "PlatformHistory",

    components: {Tag},

    data: function () {
        return {
            filter: {
                date: 1,
            },
            data: [],
            pageSize: 8,
            pageCount: 0,
            currentPage: 1,
        }
    },

    mounted() {
        this.initView();
    },

    methods: {
        unixFormatter,

        initView: function () {
            this.filter.date = moment().subtract(1, "months").startOf('month').toDate();
            this.update();
        },

        update: function () {
            const {currentPage, pageSize, filter} = this;
            const {date} = filter;

            const selectedData = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
            const dateStart = Math.floor(moment(selectedData).startOf('month').unix());
            const dateEnd = Math.floor( moment(selectedData).endOf('month').unix())

            api.getSettlementPlatformReport(currentPage, pageSize, dateStart, dateEnd, 2).then(result => {
                if (parseInt(result.code) !== CODE_OK) {
                    this.$message.error("获取上游核算列表失败， Err: " + result.msg)
                    return
                }
                this.data = result.data.list;
                this.pageCount = result.data.count;
            }, err => {
                this.$message.error("获取上游核算列表失败, Err: " + err)
            })
        },

        handleClickSearch: function () {
            this.update();
        },

        handleClickClear: function () {
            this.filter.date = moment().subtract(1, "months").startOf('month').toDate();
        },

        handleClickDetail: function (ts, channel) {
            const date = new Date(ts * 1000);
            const dateStart = moment(date).startOf('month').unix();
            const dateEnd = moment(date).endOf('month').unix()
            Routers.push({
                path: "/dashboard/financial/platform",
                query: {
                    date_start: dateStart,
                    date_end: dateEnd,
                    channel: channel
                }
            });
        }
    },
}
</script>

<style scoped>

.card:not(:first-child) {
    margin-top: 20px;
}

.filter .el-form .el-form-item {
    margin-bottom: 0 !important;
}

.card .footer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

</style>