<template>
    <div>
        <el-card class="filter">
            <el-form inline>
                <el-form-item>
                    <el-date-picker
                        v-model="filter.date"
                        type="month"
                        placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>

                <el-form-item label="状态">
                    <el-select v-model="filter.status" style="width: 150px">
                        <el-option :value="0" label="全部"/>
                        <el-option :value="1" label="待上传发票"/>
                        <el-option :value="2" label="重新上传发票"/>
                        <el-option :value="3" label="发票审核中"/>
                        <el-option :value="4" label="支付中"/>
                        <el-option :value="5" label="已支付"/>
                    </el-select>
                </el-form-item>

                <el-form-item label="结算单ID">
                    <el-input v-model="filter.search" placeholder="结算单" @change="update"/>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="update">查找</el-button>
                </el-form-item>

                <el-form-item>
                    <el-button class="filter-clear" type="text" @click="handleClickClear">清空搜索条件</el-button>
                </el-form-item>
            </el-form>
        </el-card>

        <el-card class="card content">
            <el-table :data="data">
                <el-table-column label="结算时间" align="center">
                    <template v-slot:default="scope">
                        <span>{{unixFormatter(scope.row.date, 'YYYY-MM')}}</span>
                    </template>
                </el-table-column>

                <el-table-column prop="user_form_id"  label="结算单ID" align="center"/>

                <el-table-column prop="username" label="账户名" align="center"/>

                <el-table-column label="总收入" align="center">
                    <template v-slot:default="scope">
                        <span>{{(scope.row.revenue/100).toFixed(2)}}</span>
                    </template>
                </el-table-column>

                <el-table-column label="佣金比" prop="commission_ratio" align="center"/>

                <el-table-column label="结算收入" align="center">
                    <template v-slot:default="scope">
                        <span>{{(scope.row.pure_revenue/100).toFixed(2)}}</span>
                    </template>
                </el-table-column>

                <el-table-column label="状态" align="center">
                    <template v-slot:default="scope">
                        <tag v-if="scope.row.status===1" color="#7e7e7e">等待发票</tag>
                        <tag v-if="scope.row.status===2" color="#7e7e7e" size="100px">重新等待发票</tag>
                        <tag v-else-if="scope.row.status===3" color="#7e7e7e">审核发票</tag>
                        <tag v-else-if="scope.row.status===4" color="#7e7e7e">支付中</tag>
                        <tag v-else-if="scope.row.status===5" color="#67c23a">已支付</tag>
                    </template>
                </el-table-column>

                <el-table-column label="操作" align="center">
                    <template v-slot:default="scope">
                        <template v-if="scope.row.status===1">
                            <el-button type="text" @click="handleClickDetail(scope.row.user_form_id, scope.row.username)">明细</el-button>
                        </template>
                        <template v-else-if="scope.row.status===2">
                            <el-button type="text" @click="handleClickDetail(scope.row.user_form_id, scope.row.username)">明细</el-button>
                            <el-button v-if="scope.row.refuse_reason.length > 0" type="text"
                                       @click="handleClickReason(scope.row.refuse_reason)">
                                拒绝原因
                            </el-button>
                        </template>
                        <template v-else-if="scope.row.status===3">
                            <el-button type="text" @click="handleClickDetail(scope.row.user_form_id, scope.row.username)">明细</el-button>
                            <el-button type="text" @click="handleClickFapiao(scope.row.fapiao_url)">发票</el-button>
                            <el-button type="text" @click="handleClickAccept(scope.row.id)">通过</el-button>
                            <el-button type="text" @click="handleClickRefuse(scope.row.id, scope.row.refuse_reason)">拒绝</el-button>
                        </template>
                        <template v-else-if="scope.row.status===4">
                            <el-button type="text" @click="handleClickDetail(scope.row.user_form_id, scope.row.username)">明细</el-button>
                            <el-button type="text" @click="handleClickFapiao(scope.row.fapiao_url)">发票</el-button>
                            <el-button type="text" @click="handleClickPay(scope.row.id)">支付</el-button>
                        </template>

                        <template v-else-if="scope.row.status===5">
                            <el-button type="text" @click="handleClickDetail(scope.row.user_form_id, scope.row.username)">明细</el-button>
                            <el-button type="text" @click="handleClickFapiao(scope.row.fapiao_url)">发票</el-button>
                        </template>
                    </template>
                </el-table-column>

            </el-table>
            <div class="footer">
                <el-pagination
                    background
                    layout="prev, pager, next"
                    @current-change="handleChangeCurrentPage"
                    :page-size="this.pageSize"
                    :total="this.pageCount">
                </el-pagination>
            </div>
        </el-card>
        <confirm ref="reasonDialog" title="拒绝原因" :minRows="6" :readonly="true"/>
        <confirm ref="refuseDialog" title="拒绝" :minRows="6"/>
    </div>
</template>

<script>
import * as api from "../../api/api"
import Tag from "../../component/Tag.vue";
import Confirm from "../../component/Confirm.vue";
import {unixFormatter} from "../../util/format";
import Routers from "../../router/"

export default {
    name: "PayQuery",

    components: {Confirm, Tag},

    data: function () {
        return {
            filter: {
                date: '',
                status: 0,
                search: '',
            },
            data:[],
            currentPage: 1,
            pageSize: 10,
            pageCount: 0,
        }
    },

    mounted() {
        this.initView();
    },

    methods: {
        unixFormatter,

        initView: function () {
            this.update();
        },

        update: function () {
            const {currentPage, pageSize, filter} = this;
            const {date, status, search} = filter;
            const ts = date !== '' ? date.getTime()/1000 : date;
            api.getSettlementQuery(currentPage, pageSize, ts, status, search).then(result => {
                if (parseInt(result.code) !== 200) {
                    this.$message.error("获取提现列表失败， Err: " + result.msg)
                    return
                }
                this.data = result.data.list;
                this.pageCount = result.data.count
            }, err => {
                this.$message.error("获取提现列表失败, Err: " + err)
            })
        },

        handleClickDetail: function (id, username) {
            Routers.push({
                path: "/dashboard/settlement/user/app/history",
                query: {
                    id: id,
                    username: username,
                }
            })
        },

        handleClickAccept: function (id) {
            api.checkSettlementQueryFapiao(id, true, "").then(result => {
                if (parseInt(result.code) !== 200) {
                    this.$message.error("审核结算请求发票失败， Err: " + result.msg)
                    return
                }
                this.update();
            }, err => {
                this.$message.error("审核结算请求发票失败, Err: " + err)
            })
        },

        handleClickFapiao: function (url) {
            const a = document.createElement('a');
            a.setAttribute("href", url);
            a.setAttribute("download", "");
            a.click();
        },

        handleClickReason: function (reason) {
            this.$refs.reasonDialog.show(reason);
        },

        handleClickRefuse: function (id) {
            this.$refs.refuseDialog.show().then((reason) => {
                api.checkSettlementQueryFapiao(id, false, reason).then(result => {
                    if (parseInt(result.code) !== 200) {
                        this.$message.error("审核结算请求发票失败， Err: " + result.msg)
                        return
                    }
                    this.update();
                }, err => {
                    this.$message.error("审核结算请求发票失败, Err: " + err)
                })
            }, () => {})
        },

        handleClickPay: function (id) {
            api.checkSettlementQueryPay(id).then(result => {
                if (parseInt(result.code) !== 200) {
                    this.$message.error("支付结算请求失败， Err: " + result.msg)
                    return
                }
                this.update();
            }, err => {
                this.$message.error("支付结算请求失败, Err: " + err)
            })
        },

        handleClickClear: function () {
            this.filter.date = '';
            this.filter.settleId = '';
            this.filter.status = 0;
        },

        handleChangeCurrentPage: function (page) {
            this.currentPage = page
            this.update()
        }
    }
}
</script>

<style scoped>

.card:not(:first-child) {
    margin-top: 20px;
}

.filter .el-form-item {
    margin-bottom: 0 !important;
}

.card.content .footer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

</style>