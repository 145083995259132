<template>
    <div>
        <div class="filter">
            <el-form inline>
                <el-form-item>
                    <el-date-picker
                        v-model="filter.dateRange"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="update">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-divider/>
        <el-table v-model:data="data">
            <el-table-column prop="date" label="结算时间" align="center">
                <template v-slot:default="scope">
                    <span>{{unixFormatter(scope.row.date, 'YYYY-MM-DD')}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="app_id" label="应用ID" align="center"></el-table-column>
            <el-table-column prop="app_name" label="应用名" align="center"></el-table-column>
            <el-table-column prop="est_revenue" label="预估收益" align="center">
                <template v-slot:default="scope">
                    <span>
                        {{(parseFloat(scope.row.bd_est_revenue) + parseFloat(scope.row.kd_est_revenue)
                        + parseFloat(scope.row.tx_est_revenue) + parseFloat(scope.row.gm_est_revenue)).toFixed(2)}}
                    </span>
                </template>
            </el-table-column>
            <el-table-column prop="kd_est_revenue"  label="快手" align="center">
                <template v-slot:default="scope">
                    <el-input-number v-if="scope.row.id === selectedId" size="mini" v-model="scope.row.kd_est_revenue" :precision="2" :step="0.01" :min="0"/>
                    <span v-else>{{scope.row.kd_est_revenue}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="tx_est_revenue"  label="优量汇" align="center">
                <template v-slot:default="scope">
                    <el-input-number v-if="scope.row.id === selectedId" size="mini" v-model="scope.row.tx_est_revenue" :precision="2" :step="0.01" :min="0"/>
                    <span v-else>{{scope.row.tx_est_revenue}}</span>
                </template>
            </el-table-column>

            <el-table-column prop="gm_est_revenue"  label="Gromore" align="center">
                <template v-slot:default="scope">
                    <el-input-number v-if="scope.row.id === selectedId" size="mini" v-model="scope.row.gm_est_revenue" :precision="2" :step="0.01" :min="0"/>
                    <span v-else>{{scope.row.gm_est_revenue}}</span>
                </template>
            </el-table-column>

            <el-table-column label="操作" align="center" v-if="editable === true">
                <template v-slot:default="scope">
                    <el-button v-if="selectedId !== scope.row.id" type="text" @click="handleClickSelect(scope.row.id)">修正</el-button>
                    <el-button v-else type="text" @click="handleClickSure(scope.row.id)">确认</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="footer">
            <el-pagination
                class="pagination"
                background
                layout="prev, pager, next"
                @current-change="update"
                v-model:current-page="currentPage"
                :page-size="pageSize"
                :total="pageCount">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import * as api from "../../api/api";
import {CODE_OK} from "../../api/request";
import {unixFormatter} from "../../util/format";
import moment from "moment";

export default {
    name: "AppFormEveryday",

    data: function () {
        return {
            filter: {
                dateRange: [],
            },
            selectedId: 0,
            data: [],
            pageSize: 8,
            pageCount: 0,
            currentPage: 1,
        }
    },

    props: {
        id: {
            type: Number,
            required: true,
        },

        editable:  {
            type: Boolean,
            required: false,
        },

        date: {
            type: Number,
            required: true,
        }
    },

    mounted() {
        this.initView();
    },

    methods: {
        unixFormatter,

        initView: function () {
            const {date} = this;
            const dateStart = moment(date).startOf('month').toDate();
            const dateEnd = moment(date).endOf('month').toDate();

            this.filter.dateRange = [dateStart, dateEnd];

            this.update();
        },

        update: function () {
            const {currentPage, pageSize, id, filter} = this;
            const {dateRange} = filter;
            const dateStart = Math.floor(dateRange[0].getTime() / 1000);
            const dateEnd = Math.floor(dateRange[1].getTime() / 1000);
            api.getSettlementAppDReport(currentPage, pageSize, id, 0, dateStart, dateEnd, 0).then(result => {
                if (parseInt(result.code) !== CODE_OK) {
                    this.$message.error("获取上游核算列表失败， Err: " + result.msg)
                    return
                }
                this.data = result.data.list.map((item)=> {
                    item.est_revenue = (item.est_revenue/100).toFixed(2);
                    item.bd_est_revenue = (item.bd_est_revenue/100).toFixed(2);
                    item.tx_est_revenue = (item.tx_est_revenue/100).toFixed(2);
                    item.kd_est_revenue = (item.kd_est_revenue/100).toFixed(2);
                    item.gm_est_revenue = (item.gm_est_revenue/100).toFixed(2);
                    return item;
                });
                this.pageCount = result.data.count;
            }, err => {
                this.$message.error("获取应用结算列表失败, Err: " + err)
            })
        },

        handleClickSelect: function (id) {
            this.selectedId = id;
        },

        handleClickSure: function () {
            for (let item of this.data) {
                if (item.id !== this.selectedId) {
                   continue;
                }
                const bdEstRevenue = Math.floor(parseFloat(item.bd_est_revenue) *  100);
                const txEstRevenue = Math.floor(parseFloat(item.tx_est_revenue) * 100);
                const kdEstRevenue = Math.floor(parseFloat(item.kd_est_revenue) * 100);
                const gmEstRevenue = Math.floor(parseFloat(item.gm_est_revenue) * 100);
                api.updateSettlementAppD(item.id, bdEstRevenue, txEstRevenue, kdEstRevenue, gmEstRevenue).then(result => {
                    if (parseInt(result.code) !== CODE_OK) {
                        this.$message.error("修改失败， Err: " + result.msg)
                        return
                    }
                    this.update();

                    this.$emit("update");
                }, err => {
                    this.$message.error("修改失败, Err: " + err)
                })
            }
            this.selectedId = 0;
        },
    }
}
</script>

<style scoped>

.filter .el-form-item {
    margin-bottom: 0;
}

.footer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

</style>