<template>
    <div>
        <el-card class="card header">
            <el-page-header title="返回" @back="handleGoBack" :content="name"/>
        </el-card>
        <el-card class="card filter">
            <el-form inline>
                <el-form-item>
                    <el-date-picker
                        v-model="filter.dateRange"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="update">搜索</el-button>
                </el-form-item>
            </el-form>
        </el-card>

        <el-card class="card content">
            <el-table :data="data">
                <el-table-column label="结算时间" align="center">
                    <template v-slot:default="scope">
                        <span>{{unixFormatter(scope.row.date, 'YYYY-MM-DD')}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="app_id" label="应用ID" align="center"></el-table-column>
                <el-table-column prop="app_name" label="应用名" align="center"></el-table-column>
                <el-table-column prop="est_revenue" label="预估收益" align="center">
                    <template v-slot:default="scope">
                    <span>
                        {{(parseFloat(scope.row.bd_est_revenue) + parseFloat(scope.row.kd_est_revenue)
                        + parseFloat(scope.row.tx_est_revenue) + parseFloat(scope.row.gm_est_revenue)).toFixed(2)}}
                    </span>
                    </template>
                </el-table-column>
                <el-table-column prop="kd_est_revenue"  label="快手" align="center">
                    <template v-slot:default="scope">
                        <span >{{scope.row.kd_est_revenue}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="tx_est_revenue"  label="优量汇" align="center">
                    <template v-slot:default="scope">
                        <span>{{scope.row.tx_est_revenue}}</span>
                    </template>
                </el-table-column>

                <el-table-column prop="gm_est_revenue"  label="Gromore" align="center">
                    <template v-slot:default="scope">
                        <span>{{scope.row.gm_est_revenue}}</span>
                    </template>
                </el-table-column>
            </el-table>
            <div class="footer">
                <el-pagination
                    class="pagination"
                    background
                    layout="prev, pager, next"
                    @current-change="update"
                    v-model:current-page="currentPage"
                    :page-size="pageSize"
                    :total="pageCount">
                </el-pagination>
            </div>
        </el-card>
    </div>
</template>

<script>
import moment from "moment";
import * as api from "../../api/api";
import {CODE_OK} from "../../api/request";
import {unixFormatter} from "../../util/format";
import Routers from "../../router/"

export default {
    name: "AppFormEverydayHistory",

    data: function () {
        return {
            date: 0,
            name: "",
            filter: {
                dateRange: [],
            },
            data: [],
            pageSize: 8,
            pageCount: 0,
            currentPage: 1,
        }
    },

    mounted() {
        this.initView();
    },

    methods: {
        unixFormatter,

        initView: function () {
            const {id, date, name} = this.$route.query
            if (date === undefined || date === "") {
                this.$message.error("缺少参数 date");
                Routers.back();
                return
            }

            if (id === undefined || id === "") {
                this.$message.error("缺少参数 userAppFormId");
                Routers.back();
                return
            }

            this.id = parseInt(id);
            this.date = parseInt(date) * 1000;
            this.name=name;

            const dateStart = moment(this.date).startOf('month').toDate();
            const dateEnd = moment(this.date).endOf('month').toDate();

            this.filter.dateRange = [dateStart, dateEnd];

            this.update();
        },

        update: function () {
            const {currentPage, pageSize, id, filter} = this;
            const {dateRange} = filter;
            const dateStart = Math.floor(dateRange[0].getTime() / 1000);
            const dateEnd = Math.floor(dateRange[1].getTime() / 1000);
            api.getSettlementAppDReport(currentPage, pageSize, id, 0, dateStart, dateEnd, 0).then(result => {
                if (parseInt(result.code) !== CODE_OK) {
                    this.$message.error("获取上游核算列表失败， Err: " + result.msg)
                    return
                }
                this.data = result.data.list.map((item)=> {
                    item.est_revenue = (item.est_revenue/100).toFixed(2);
                    item.bd_est_revenue = (item.bd_est_revenue/100).toFixed(2);
                    item.tx_est_revenue = (item.tx_est_revenue/100).toFixed(2);
                    item.kd_est_revenue = (item.kd_est_revenue/100).toFixed(2);
                    item.gm_est_revenue = (item.gm_est_revenue/100).toFixed(2);
                    return item;
                })
                this.pageCount = result.data.count;
            }, err => {
                this.$message.error("获取应用结算列表失败, Err: " + err)
            })
        },

        handleGoBack: function () {
            Routers.back();
        },
    }
}
</script>

<style scoped>

.card:not(:first-child) {
    margin-top: 20px;
}

.card .footer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

</style>