<template>
    <div class="e404">
        <div class="e-code">4 0 4</div>
        <div class="e-tip">抱歉! 您所访问的网页不存在，请重新加载!</div>
        <div class="e-op">
            <div @click="handleReload()"><el-icon class="el-icon-refresh-right"/> 重新加载</div>
            <div @click="handleGoHome()"><el-icon class="el-icon-top-right"/> 返回首页</div>
        </div>
    </div>
</template>

<script>

export default {
  // eslint-disable-next-line vue/multi-word-component-names
    name: "e404",

    methods: {
        handleReload: function () {
            window.location.reload();
        },

        handleGoHome: function () {
            window.vue.$router.push("/")
        }
    }
}
</script>

<style scoped>

.e404 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.e404 > .e-code {
    font-weight: bold;
    font-size: 160px;
    color: #909399;
    margin-bottom: 10px;
}

.e404 > .e-tip {
    font-size: 20px;
    color: #909399;
    margin-bottom: 20px;
}

.e404 > .e-op {
    width:  280px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    color: #909399;
    cursor: pointer;
}

</style>