<template>
    <div class="card" :style="{backgroundColor: color}">
        <div class="header" v-if="$slots.header || header">
             {{ header }}
        </div>
        <div class="content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
    name: "card",
    props: {
        header: {},
        color: {
            type: String,
            required: false,
            default: "#fff6f4"
        }
    }
}
</script>

<style scoped>
.card {
    padding: 24px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
}

.card .header {
    width: 100%;
    font-size: 14px;
    color: #434343;
    font-weight: 400;
    text-align: left;
    line-height: 20px;
}

.card .table {
    width: 100%;
}

</style>