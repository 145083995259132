export function deepCopy(o) {
    return JSON.parse(JSON.stringify(o));
}


export function copyTextToClipboard(text) {

    return new Promise((resolve, reject) => {

        if (navigator.clipboard) {
            // 现代浏览器支持 Clipboard API
            navigator.clipboard.writeText(text).then(() => {
                resolve('操作成功');
            }).catch(err => {
                console.error('无法复制文本: ', err);
                reject('操作失败');
            });
        } else {
            // 对于不支持 Clipboard API 的浏览器，使用 document.execCommand
            const textarea = document.createElement('textarea');
            textarea.value = text;
            document.body.appendChild(textarea);
            textarea.select();
            try {
                document.execCommand('copy');
                resolve('操作成功');
            } catch (err) {
                console.error('无法复制文本: ', err);
                reject('操作失败');
            }
            document.body.removeChild(textarea);
        }
    });

}
