<template>
    <div>
        <el-card class="card filter">
            <el-form inline :model="filter">
                <el-form-item label="账户名称">
                    <el-input v-model="filter.name" size="small"/>
                </el-form-item>
                <el-form-item>
                    <el-select placeholder="请选择状态" v-model="filter.status" size="small" style="width: 150px">
                        <el-option :value="0" label="所有"/>
                        <el-option :value="1" label="正常"/>
                        <el-option :value="2" label="封禁"/>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="update" size="small">筛 选</el-button>
                </el-form-item>
            </el-form>
        </el-card>

        <el-card class="card table">
            <div class="content">
                <el-table :data="data">

                    <el-table-column
                        width="120px"
                        align="center"
                        :formatter="(row) => unixFormatter(row.created_at, 'YYYY-MM-DD')"
                        label="创建日期"/>

                    <el-table-column prop="id" label="用户ID" align="center" width="100px"/>

                    <el-table-column prop="username" label="用户名" align="center"/>

                    <el-table-column prop="contact" label="联系人" align="center"/>

                    <el-table-column prop="mobile" label="联系电话" align="center"/>

                    <el-table-column prop="email" label="联系邮箱" align="center" width="180px"  show-overflow-tooltip/>

                    <el-table-column prop="address" label="联系地址" align="center" width="180px" show-overflow-tooltip/>

                    <el-table-column prop="status" label="状态" align="center" width="80px">
                        <template v-slot:default="scope">
                            <el-tag size="small" v-if="scope.row.status===1">正常</el-tag>
                            <el-tag size="small" v-else-if="scope.row.status===2">封禁</el-tag>
                        </template>
                    </el-table-column>

                    <el-table-column label="操作" align="center">
                        <template v-slot:default="scope">
                            <el-button v-if="scope.row.status===1" type="text" size="mini" plain
                                       @click="handleForbid(scope.row.username, scope.row.id)">
                                禁用
                            </el-button>
                            <el-button v-else-if="scope.row.status===2" type="text" size="mini" plain
                                       @click="handleEnable(scope.row.username, scope.row.id)">
                                启用
                            </el-button>

                            <el-button size="mini" @click="handleEdit(scope.row.id)" type="text" plain>
                                编辑
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="footer">
                <el-pagination
                    class="pagination"
                    background
                    layout="prev, pager, next"
                    @current-change="update"
                    v-model:current-page="currentPage"
                    :page-size="pageSize"
                    :total="pageCount">
                </el-pagination>
            </div>
        </el-card>
        <user-edit-dialog ref="edit"/>
    </div>
</template>

<script>
import * as api from "../../api/api"
import {CODE_OK} from "../../api/request";
import {unixFormatter} from "../../util/format";
import UserEditDialog from "./UserEditDialog.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
    name: "Users",

    components: {UserEditDialog},

    data: function () {
        return {
            filter: {
                name: "",
                status: 0,
            },
            data: [],
            currentPage: 1,
            pageSize: 5,
            pageCount: 0,
        }
    },

    created() {
        this.update();
    },

    methods: {
        unixFormatter,

        update: function () {
            const {filter, currentPage, pageSize} = this;
            const {name: search, status} = filter;
            api.getUserList(currentPage, pageSize, search, status).then(result => {
                if (parseInt(result.code) !== CODE_OK) {
                    this.$message.error("获取用户列表失败， Err: " + result.msg)
                    return
                }

                this.data = result.data.list;
                this.pageCount = result.data.count
            }, err => {
                this.$message.error("获取用户列表失败, Err: " + err)
            })
        },

        handleForbid: function (name, id) {
            this.$confirm('确定禁用用户('+ name+ ') ？', "警告", {center: true}).then(() => {
                api.forbidUser(id).then(result=>{
                    if (parseInt(result.code) !== CODE_OK) {
                        this.$message.error("禁用用户失败， Err: " + result.msg)
                        return
                    }
                    this.update();
                }, err => {
                    this.$message.error("禁用用户失败, Err: " + err)
                })
            }).catch(() => {});
        },

        handleEnable: function (name, id) {
            this.$confirm('确定启用用户('+ name+ ') ？', "警告", {center: true}).then(() => {
                api.enableUser(id).then(result=>{
                    if (parseInt(result.code) !== CODE_OK) {
                        this.$message.error("启用用户失败， Err: " + result.msg)
                        return
                    }
                    this.update();
                }, err => {
                    this.$message.error("启用用户失败, Err: " + err)
                })
            }).catch(() => {});
        },

        handleEdit: function (id) {
            this.$refs.edit.show(id);
        }
    }
}
</script>

<style scoped>

.card:not(:first-child) {
    margin-top: 20px;
}

.card.filter .el-form-item {
    margin-bottom: 0px;
}

.card.table .footer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
</style>