<template>
  <div id="app">
    <section>
      <router-view></router-view>
    </section>
  </div>
</template>

<script>


export default {

}
</script>

<style>

html {
  --primary-color: #ff3030;
  --primary-color-transparent: #fef0f0;
  --primary-color-warn: #faad15;
  --primary-color-success: #6abf40;
  --primary-color-info: #999999;

  --text-color-dark: #333333;
  --text-color-light: #ffffff;
  --text-color-translucences:#999999;

  --aside-menuitem-color: #ffcece;
  --aside-menuitem-color-hover: #fef0f0;
  --aside-menuitem-text-color: #ff3030;

  --button-color: #ff3030;
  --button-border-color: #ff3030;
  --button-text-color-dark: #fd6262;
  --button-text-color-light: #ffffff;

  --button-color-focus: #fd6262;
  --button-border-color-focus: #fd6262;

  --input-border-focus: #fd6262;

  --border-color: #dadfe3;
  --border-shadow: rgb(222 228 245 / 32%);
  --border-shadow-dark: rgb(222 228 245 / 64%);

  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
}


#app {
  width: 100%;
  height: 100%;
  font-weight: 400;
}

::-webkit-scrollbar {
  background-clip: padding-box;
  background-color: transparent;
  height: 6px;
  width: 6px;
}

::-webkit-scrollbar-button {
  background-color: transparent;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background: #d6d6d6;
  border-radius: 10px;
  width: 6px;
}

::-webkit-scrollbar-track {
  padding-right: 10px;
  width: 10px;
}

/** 修改全局 element ui **/
.el-dialog {
  border-radius: 6px;
}

.el-button:focus, .el-button:hover {
  color: var(--primary-color) !important;
  border-color:  var(--button-border-color-focus) !important;
  background-color: #ffffff !important;
}

.el-button.is-disabled, .el-button.is-disabled:focus, .el-button.is-disabled:hover {
  background-color: #fff !important;
  border-color: #ebeef5 !important;
  color: #c0c4cc !important;
}

.el-button.el-button--primary {
  color: #fff !important;
  background-color: var(--button-color) !important;
  border-color: var(--button-border-color) !important;
}

.el-button.el-button--primary:active {
  background: var(--button-color) !important;
  border-color: var(--button-border-color) !important;
  color: var(--button-text-color-dark) !important;
  outline: none !important;
}

.el-button.el-button--primary:hover {
  background: var(--button-color-focus) !important;
  border-color: var(--button-color-focus) !important;
  color: var(--button-text-color-light) !important;
  outline: none !important;
}

.el-button--primary.is-disabled, .el-button--primary.is-disabled:active, .el-button--primary.is-disabled:focus, .el-button--primary.is-disabled:hover {
  background-color: #fff !important;
  border-color: #ebeef5 !important;
  color: #c0c4cc !important;
}

.el-button.el-button--text {
  color: var(--button-text-color-dark) !important;
  border-color: transparent !important;
  background-color: transparent !important;
}

.el-button.el-button--text:focus, .el-button.el-button--text:hover {
  color: var(--button-text-color-dark) !important;
  border-color: transparent !important;
  background-color: transparent !important;
  text-decoration: underline !important;
}

.el-button--text, .el-button--text.is-disabled, .el-button--text.is-disabled:focus, .el-button--text.is-disabled:hover, .el-button--text:active{
  border-color: transparent !important;
  background-color: transparent !important;
  color: #c0c4cc !important;

}

.el-dialog__headerbtn:focus .el-dialog__close, .el-dialog__headerbtn:hover .el-dialog__close {
  color: var(--primary-color) !important;
}

.el-input.is-active .el-input__inner, .el-input__inner:focus {
  border-color: var(--input-border-focus) !important;
}

.el-input.is-active .el-input__inner, .el-input__inner:focus {
  border-color: var(--input-border-focus) !important;
}

.el-select .el-input__inner:focus {
  border-color: var(--input-border-focus) !important;
}

.el-select .el-input.is-focus .el-input__inner {
  border-color: var(--input-border-focus) !important;
}

.el-select-dropdown__item.selected {
  color: var(--primary-color) !important;
}

.el-tag {
  background-color: #ffffff !important;
  color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
}

.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: var(--primary-color) !important;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: var(--primary-color) !important;
  color: var(--text-color-light) !important;
}

.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: var(--primary-color) !important;
}

.el-checkbox .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.el-checkbox .el-checkbox__input .el-checkbox__inner:hover {
  border-color: var(--primary-color) !important;
}

.el-checkbox .el-checkbox__input.is-disabled .el-checkbox__inner:hover {
  border-color: #dcdfe6 !important;
}

.el-checkbox__input.is-checked+.el-checkbox__label {
  color: var(--primary-color) !important;
}

.el-checkbox__input.is-disabled .el-checkbox__inner {
  background-color: #edf2fc !important;
  border-color: #dcdfe6 !important;
  cursor: not-allowed !important;
}

.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background-color: #f2f6fc !important;
  border-color: #dcdfe6 !important;
}


.el-dropdown-menu .el-dropdown-menu__item:hover {
  color: var(--primary-color) !important;
  background-color: var(--primary-color-transparent) !important;
}

.el-radio-button__inner:hover {
  color: var(--primary-color) !important;
}

.el-radio-button__orig-radio:disabled+.el-radio-button__inner {
  color: #c0c4cc !important;
}

.el-radio-button__orig-radio:checked+.el-radio-button__inner {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  box-shadow: -1px 0 0 0 var(--primary-color) !important;
  color: #FFFFFF !important;
}


.el-table {
  display: flex;
  flex-direction: column;
}

.el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
  background-color: #f8f8fa !important;
}

.el-table__body tr.current-row>td.el-table__cell {
  background-color: #f8f8fa !important;
}

.el-table .el-table__body-wrapper {
  order: 1;
}
.el-table__fixed-body-wrapper{
  top:96px !important;
}

.el-table__fixed-footer-wrapper{
  z-index: 0;
  top:47px;
}

.el-table__header-wrapper .has-gutter  tr th {
  background-color: #f5f7fa !important;
  color: #1f2f3d;
  font-weight: 400;
}

.el-table__header-wrapper .has-gutter {
  border: 1px solid #e4e5e7;
}

.el-table__fixed, .el-table__fixed-right {
  border-right: 1px solid #ebeef5 !important;
}

.el-table__fixed-header-wrapper  tr th {
  background-color: #ebeef5 !important;
  color: #1f2f3d;
  font-weight: 400;
}

.el-table__fixed-header-wrapper  .has-gutter {
  border: 1px solid #ebeef5;
}

.el-loading-spinner i {
  color: var(--primary-color) !important;
}

.el-loading-text {
  color: var(--primary-color) !important;
}

.el-month-table td.current:not(.disabled) .cell {
  color: var(--primary-color) !important;
}

.el-month-table td.today .cell {
  color: var(--primary-color) !important;
}

.el-picker-panel__icon-btn:hover {
  color: var(--primary-color) !important;
}

.el-date-table td.today span {
  color: var(--primary-color) !important;
}

.el-date-table td.available:hover {
  color: var(--primary-color) !important;
}

.el-range-editor.is-active, .el-range-editor.is-active:hover {
  border-color: var(--primary-color) !important;
}

.el-date-table td.end-date span, .el-date-table td.start-date span {
  background-color: var(--primary-color) !important;
  color: #ffffff !important;
}

.el-link.el-link--default:hover {
  color: var(--primary-color) !important;
}

.el-link.is-underline:hover:after {
  border-color: var(--primary-color) !important;
}

</style>
