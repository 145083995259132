<template>
  <el-dialog class="dialog" v-model="dialogVisible" title="预览结算明细" :close-on-click-modal="false" @closed="clear">

    <el-table :data="data" show-summary :summary-method="summary">
      <el-table-column label="时间" align="center">
        <template v-slot:default="scope">
          <span>{{ unixFormatter(scope.row.date, 'YYYY-MM') }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="app_name" label="应用" align="center"/>
      <el-table-column label="结算金额" align="center">
        <template v-slot:default="scope">
          <span>{{ (scope.row.final_revenue / 100).toFixed(2) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="分成比例" align="center">
        <template v-slot:default="scope">
          <span>{{ scope.row.share_ratio }}%</span>
        </template>
      </el-table-column>
      <el-table-column label="分成结算" align="center">
        <template v-slot:default="scope">
          <span>{{ (scope.row.pure_revenue / 100).toFixed(2) }}</span>
        </template>
      </el-table-column>
    </el-table>

    <div class="footer">
      <slot name="footer" v-if="isCanGen">
        <el-button type="primary" @click="handleClickGen">生成结算单</el-button>
      </slot>
    </div>
  </el-dialog>
</template>

<script>
import * as api from "../../api/api"
import {CODE_OK} from "../../api/request";
import {unixFormatter} from "../../util/format";
import {ref} from "vue";

export default {

  setup() {
    let dialogVisible = ref(false)

    return {
      dialogVisible
    }
  },
  data: function () {
    return {
      data: [],
      isCanGen: true,
    }
  },

  props: {
    id: {
      type: Number,
      required: true,
    }
  },

  methods: {
    unixFormatter,

    update: function () {
      api.getSettlementFinalPreview(this.id).then(result => {
        if (parseInt(result.code) !== CODE_OK) {
          this.$message.error("获取结算预览失败， Err: " + result.msg)
          return
        }
        this.data = result.data.list;
        this.finalRevenue = (result.data.final_revenue / 100).toFixed(2);
        this.pureRevenue = (result.data.pure_revenue / 100).toFixed(2);
        this.shareRatio = result.data.share_ratio;
        this.date = result.data.list[0].date;
      }, err => {
        this.$message.error("获取结算预览失败， Err: " + err)
      })
    },

    show: function () {
      this.dialogVisible = true;
      this.update();
    },

    close: function () {
      this.dialogVisible = false
    },

    clear: function () {
      this.data = [];
    },

    handleClickGen: function () {
      api.genSettlementFinal(this.id).then(result => {
        if (parseInt(result.code) !== CODE_OK) {
          this.$message.error("生成结算单失败， Err: " + result.msg)
          return
        }
        this.$message.success("生成结算单成功");
        this.isCanGen = false;
        this.$emit("update");
      }, err => {
        this.$message.error("获取结算预览失败， Err: " + err)
      });
    },

    summary: function () {
      const date = unixFormatter(this.date, 'YYYY-MM')
      return [date, "总计", this.finalRevenue, this.shareRatio + "%", this.pureRevenue]
    },
  }
}
</script>

<style scoped>

.dialog .footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>