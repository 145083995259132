// api 处理

import {URI_LOGIN} from "./url";
import Router from "@/router";

export const TOKEN_KEY = "0xa8762"
export const USERNAME_KEY = "0xa8763"
export const USER_ID_KEY = "0xa8764"

export const CODE_OK = 200;
export const CODE_TOKEN_ERR       = 4101
export const CODE_UNAUTHORIZE_ERR = 4102
export const CODE_ROLE_ERR        = 4103
export const CODE_PWD_ERR         = 4104
export const CODE_PRIVILEGE_ERR   = 4105


// eslint-disable-next-line no-unused-vars
var onExpiredFunction = function () {
    window.emitLogout();
}

export const decodeJwt = function (token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

/**
 * 验证请求， 请求方式GET
 * @param url
 * @param param
 * @returns {Promise<any>}
 */
export function authorizeGet(url, param) {
    url = parseUrl(url, param)
    let options = {method: "GET", headers: {'Content-Type': 'application/json'}};
    return authorize(url, options)
}

/**
 * 验证请求， 请求方式 POST
 * @param url
 * @param bodyJson
 * @returns {Promise<any>}
 */
export function authorizePost(url, bodyJson) {
    let options = {method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(bodyJson)};
    return authorize(url, options)
}

/**
 * 验证请求
 * @param url
 * @param options
 * @returns {Promise<any>}
 */
export function authorize(url, options) {
    return new Promise(function (resolve, reject) {
        let token = localStorage.getItem(TOKEN_KEY)
        if (token === null || token === undefined || token.length <= 0) {
            reject(new Error("身份验证失败～"))
            onExpiredFunction && onExpiredFunction()
            return
        }
        // 校验 token 是否过期
        let jwt = decodeJwt(token)
        if (jwt.exp * 1000 < Date.now().valueOf()) {
            reject(new Error("身份信息过期～"))
            onExpiredFunction && onExpiredFunction()
            return
        }

        let headers = {token: localStorage.getItem(TOKEN_KEY)};

        if (options === undefined) {
            options = {};
        }

        if (options.headers !== undefined) {
            headers = Object.assign(headers, options.headers);
        }
        const mergeOptions = Object.assign(options, {headers: headers});
        return fetch(url, mergeOptions).then(res => {
            return res.json();
        }, err => {
            return reject(err);
        }).then(result => {
            let code = parseInt(result.code);
            if (code <= CODE_PRIVILEGE_ERR && code >= CODE_TOKEN_ERR) {
                Router.push("/login")
                reject(new Error("身份验证失败～"));
            }
            resolve(result)
        }, err => {
            reject(new Error("发生错误:" + err));
        })
    })
}

/**
 * 是否登陆
 */
export function isLogin() {
    const key = localStorage.getItem(TOKEN_KEY);
    return key !== undefined && key !== null  && key.length > 0;
}

/**
 * 初始化用户信息
 * @param token
 * @param username
 * @param userId
 */
export function authorizeInit(token, username, userId) {
    localStorage.setItem(TOKEN_KEY, token);
    localStorage.setItem(USERNAME_KEY, username);
    localStorage.setItem(USER_ID_KEY, userId);
}

/**
 * 清空用户信息
 */
export function authorizeClear() {
    localStorage.clear()
}

/**
 * 获取用户信息
 * @param key
 * @constructor
 */
export function getAuthorize(key) {
    return localStorage.getItem(key);
}


/**
 * 用户登陆请求
 * @param username
 * @param password
 * @returns {Promise<*>}
 */
export function postLogin(username, password) {
    let bodyJson = {
        username: username,
        password: password,
    }
    return httpPost(URI_LOGIN, bodyJson)
}

/**
 * HTTP GET 请求
 * @param url
 * @param param
 * @returns {Promise<any>}
 */
export function httpGet(url, param) {
    url = parseUrl(url, param)
    let options = {method: "GET", headers: {'Content-Type': 'application/json'}};
    return fetch(url, options).then(res => {
        return res.json();
    }, err => {
        return Promise.reject(err);
    })
}

/**
 * HTTP POST 请求
 * @param url
 * @param bodyJson
 * @returns {Promise<any>}
 */
export function httpPost(url, bodyJson) {
    let options = {method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(bodyJson)};
    return fetch(url, options).then(res => {
        return res.json();
    }, err => {
        return Promise.reject(err);
    })
}

/**
 * 格式化 URL
 * <p> 返回格式 "http(s):hostname/xxx/yyy?key1=value1&key2=value2&key3=value3"</p>
 * @param url
 * @param param
 * @returns {string}
 */
export function parseUrl(url, param) {
    let paramStr = "";
    if (param !== undefined) {
        let i = 1;
        for (let key in param) {
            let value = param[key]
            if (value === undefined || value.length <= 0) {
                continue;
            }
            if (i !== 1) {
                paramStr += "&" + key + "=" + param[key]
            } else {
                paramStr = key + "=" + param[key]
            }
            i++;
        }
    }
    return paramStr.length > 0 ? url + "?" + paramStr : url;
}
