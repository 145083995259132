<template>
  <el-dialog v-model="dialogVisible" width="740px" title="开通账户信息登记" center>
    <div class="form">
      <el-form :model="form" ref="editForm" :rules="rules" label-width="80px" label-position="left">
        <el-form-item prop="company" label="账户名称">
          <el-input v-model="form.username" placeholder="公司/工作室/个人" size="small"/>
        </el-form-item>

        <el-form-item prop="contact" label="联系人">
          <el-input v-model="form.contact" placeholder="请输入联系人名称" size="small"/>
        </el-form-item>

        <el-form-item prop="tel" label="联系电话">
          <el-input v-model="form.tel" placeholder="请输入手机号" size="small"/>
        </el-form-item>

        <el-form-item prop="email" label="联系邮箱">
          <el-input v-model="form.email" placeholder="请输入邮箱" size="small"/>
        </el-form-item>

        <el-form-item prop="address" label="联系地址">
          <el-input v-model="form.address" placeholder="请输入省/市/区/具体地址" size="small"/>
        </el-form-item>

        <el-divider>银行信息</el-divider>

        <el-form-item prop="payeeName" label="开户名称">
          <el-input v-model="form.payeeName" placeholder="请填写与银行卡号配对的开户名称" size="small"/>
        </el-form-item>

        <el-form-item prop="bankAddress" label="开户行">
          <el-input v-model="form.bankAddress" placeholder="请填写银行开户行" size="small"/>
        </el-form-item>

        <el-form-item prop="bankCardNumber" label="银行卡号">
          <el-input v-model="form.bankCardNumber" placeholder="请填写银行卡卡号" size="small"/>
        </el-form-item>

        <el-form-item prop="shareRatio" label="分成比例">
          <el-input-number min="0" max="100" v-model="form.shareRatio" placeholder="请输入分成比例" size="small"/>
          &nbsp;%
        </el-form-item>

      </el-form>
    </div>
    <div class="footer">
      <el-button type="primary" @click="handleConfirm" round>开 通 账 户</el-button>
    </div>
  </el-dialog>
</template>

<script>
import * as validate from "../../util/validate"
import * as api from "../../api/api"
import {CODE_OK} from "../../api/request";

import {reactive, ref} from 'vue'

export default {

  setup() {

    let dialogVisible = ref(false)
    let form = reactive({
      id: 0,
      username: "",
      contact: "",
      tel: "",
      email: "",
      address: "",
      payeeName: "",
      bankAddress: "",
      bankCardNumber: "",
      shareRatio: 100,
    })

    const checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('联系电话不能为空'))
      }
      setTimeout(() => {
        if (!Number.isInteger(+value)) {
          callback(new Error('请输入数字值'))
        } else {
          if (validate.phone(value)) {
            callback()
          } else {
            callback(new Error('联系电话格式不正确'))
          }
        }
      }, 100)
    }

    const checkEmail = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('联系邮箱不能为空'))
      }
      setTimeout(() => {
        if (validate.email(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的联系邮箱格式'))
        }
      }, 100)

    }

    let rules = reactive({
      usernmae: [{required: true, message: "公司/工作室/个人"}],
      contact: [{required: true, message: "请输入联系人名称"}],
      tel: [{required: true, validator: checkPhone, trigger: 'blur'}],
      email: [{required: true, validator: checkEmail, trigger: 'blur'}],
      address: [{required: true, message: "请输入联系人地址"}],
    })
    return {
      dialogVisible,
      form,
      rules,
    }
  },


  methods: {
    show: function (initialize) {
      this.dialogVisible = true;
      this.form.id = initialize.id;
      this.form.username = initialize.company
      this.form.contact = initialize.name;
      this.form.tel = initialize.tel;
      this.form.email = initialize.email;
    },

    close: function () {
      this.dialogVisible = false;
      this.clear();
    },

    clear: function () {
      this.form.id = 0;
      this.form.username = "";
      this.form.contact = "";
      this.form.email = "";
      this.form.tel = "";
      this.form.address = "";
      this.form.shareRatio = 100;
      this.$refs.editForm.resetFields();
    },

    handleConfirm: function () {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          const {
            username,
            email,
            contact,
            tel,
            address,
            payeeName,
            bankCardNumber,
            bankAddress,
            shareRatio
          } = this.form;
          api.createUser(username, email, contact, tel, address, payeeName, bankCardNumber, bankAddress, shareRatio).then(result => {
            if (parseInt(result.code) !== CODE_OK) {
              this.$message.error("注册失败， Err: " + result.msg)
              return
            }

            this.$notify({
              title: '注册成功',
              message: '已发送通知邮件至用户邮箱',
              type: 'success'
            });

            this.$emit("update", this.form.id);
            this.close();
          }, err => {
            this.$message.error("注册失败， Err: " + err)
          })
        } else {
          this.$message.warning("请完善表格信息")
          return false;
        }
      });
    }
  }
}
</script>

<style scoped>

.form {
  width: 500px;
  padding: 0 100px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>