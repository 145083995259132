<template>
    <div class="indicator" :style="{backgroundColor: color}"/>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
    name: "indicator",

    props: {
        color: {
            type: String,
            default: "#67C23A",
            required: true,
        }
    }
}
</script>

<style scoped>

.indicator {
    margin: 5px;
    width: 16px;
    height: 16px;
    border-radius: 10px;
    box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 12%), 0 0 6px rgb(0 0 0 / 4%);
}

</style>