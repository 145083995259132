<template>
    <el-link class="refer" :href="href" :style="{fontSize: size}">
        <slot></slot>
    </el-link>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
    name: "refer",

    props: {
        href: {
            type: String,
            required: true,
            default: "",
        },
        size: {
            type: String,
            required: false,
            default: "16px",
        }
    }
}
</script>

<style scoped>

.refer {}

.refer:hover {
    color: #f56c6c!important;
}

.refer:hover:after {
    border-bottom: 1px solid #f56c6c !important;
}

</style>