<template>
    <div class="net-create">
        <el-card class="card">
            <div>
              <el-page-header title="返回" @back="handleBack" />
              <el-divider />
            </div>

            <div class="content">
                <div class="sub-title">网络配置</div>
                <el-form :inline="true" label-width="68px" label-position="left">
                    <el-form-item label="渠道">
                        <el-select v-model="channel" style="width: 178px" disabled>
                            <el-option :value="1" label="优量汇" />
                            <el-option :value="4" label="快手"/>
                            <el-option :value="5" label="Gromore"/>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="名称">
                        <el-input v-model="name"/>
                    </el-form-item>
                    <el-form-item label="应用ID">
                        <el-input v-model="appId"/>
                    </el-form-item>

                    <el-form-item label="应用名称">
                        <el-input v-model="appName"/>
                    </el-form-item>
                    <el-form-item label="密钥">
                        <el-input v-model="appKey"/>
                    </el-form-item>
                    <el-form-item label="包名">
                        <el-input v-model="packageName"/>
                    </el-form-item>
                    <el-form-item label="SHA1">
                        <el-input v-model="sha1"/>
                    </el-form-item>
                </el-form>
            </div>
        </el-card>

        <el-card class="card" v-if="channel===2 || channel===5">

            <div class="content">
                <div class="sub-title">网络记录</div>
                <el-table :data="records" border size="small">
                    <el-table-column label="名称" prop="name" align="center">
                         <template #default="scope">
                            <span v-if="scope.row.name.length <= 0"> - </span>
                            <span v-else>{{scope.row.name}}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="广告位" prop="placementId" align="center"/>

                    <el-table-column label="场景" prop="scene" align="center">
                        <template v-slot:default="scope">  <!-- align="center"-->
                            <tag v-if="scope.row.scene === 1" effect="plain">开屏</tag>
                            <tag v-if="scope.row.scene === 2" effect="plain">信息流</tag>
                            <tag v-if="scope.row.scene === 3" effect="plain">激励视频</tag>
                            <tag v-if="scope.row.scene === 4" effect="plain">横幅</tag>
                            <tag v-if="scope.row.scene === 5" effect="plain">插屏</tag>
                        </template>
                    </el-table-column>

                    <el-table-column label="屏幕" align="center">
                         <template #default="scope">
                            <div v-if="scope.row.orientation===0">未指明</div>
                            <div v-if="scope.row.orientation===1">竖屏</div>
                            <div v-if="scope.row.orientation===2">横屏</div>
                        </template>
                    </el-table-column>

                    <el-table-column label="ECPM" prop="ecpm" align="center">
                         <template #default="scope">
                            <span>{{scope.row.ecpm / 100}}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="操作" width="60px" align="center">
                        <template v-slot:default="scope">
                            <el-button class="btn-delete" type="text" size="small" @click="handleDeleteRecord(scope.$index)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-button class="btn-add" type="primary" size="small" :icon="Plus" @click="handleShowRecordCreateDialog">添加记录</el-button>
            </div>
        </el-card>

        <el-card class="card">
            <div>
                <el-button @click="this.handleCancel">取 消</el-button>
                <el-button type="primary" @click="handleSave">保 存</el-button>
            </div>
        </el-card>

        <network-record-create-dialog ref="createRecordDialog" v-on:ok="handleAddRecord"/>
    </div>
</template>

<script>

import NetworkRecordCreateDialog from "./NetPosCreateDialog.vue";
import * as api from "../../api/api";
import {CODE_OK} from "../../api/request";
import Tag from "../../component/Tag.vue";
import Routers from "../../router/"
import {Plus} from "@element-plus/icons";

export default {
    name: "NetCreate",
  computed: {
    Plus() {
      return Plus
    }
  },

    components: {Tag, NetworkRecordCreateDialog},

    data: function () {
        return {
            belongAppId: "",
            name: "",
            channel: "",
            appId: "",
            appName: "",
            appKey: "",
            packageName: "",
            sha1: "",
            records: []
        }
    },

    mounted() {
        this.init()
    },

    methods: {

        init: function () {
            const {channel, appId} = this.$route.query
            this.channel = Number.parseInt(channel);
            this.belongAppId = Number.parseInt(appId);
        },

        handleBack: function () {
            Routers.back();
        },

        handleDeleteRecord: function (index) {
            this.records.splice(index, 1);
        },

        handleShowRecordCreateDialog: function () {
            this.$refs.createRecordDialog.show();
        },

        handleAddRecord: function (record) {
            this.records.push(record);
        },

        handleCancel: function () {
            this.handleBack();
        },

        handleSave: function () {
            const {belongAppId, name, channel, appId, appName, appKey, packageName, sha1, records} = this;
            const isDynamic = channel !== 2 && channel !== 5;
            api.createNetwork(belongAppId, name, channel, appId, appName, appKey, packageName, sha1, isDynamic, records).then(result => {

                if (parseInt(result.code) !== CODE_OK) {
                    this.$message.error("创建失败, Err: " + result.msg);
                    return;
                }
                Routers.back();
            }, err => {
                this.$message.error("创建失败, Err: " + err);
            })
        }
    }
}
</script>

<style scoped>

.net-create .card {
    margin-bottom: 16px;
}

.net-create .content .sub-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 700;
    color: #303133;
}

.net-create .content .btn-add {
    margin-top: 20px;
}

.net-create .content .el-tag {
    width: 70px;
    text-align: center;
}

</style>