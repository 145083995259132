<template>
    <div class="statistics">
        <span class="header">{{label}}</span>
        <span class="value">{{new Intl.NumberFormat('en-US').format(value)}}</span>
    </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
    name: "Statistics",

    props:{
        label: {
            type: String,
            required: true,
        },
        value: {
            type: Number,
            required: true,
        }
    }
}
</script>

<style scoped>

.statistics {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin: 0 20px;
}

.statistics .header {
    font-weight: 400;
    font-size: 12px;
    color: #606266;
    line-height: 20px;
    margin-bottom: 4px;
}

.statistics .value {
    font-weight: 400;
    font-size: 20px;
    color: #303133;
}

</style>