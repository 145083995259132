<template>
  <el-dialog v-model="dialogVisible" title="编辑" @close="handleCancel" :close-on-click-modal="false" center>
    <el-form>
      <el-form-item label="版本名">
        <el-input v-model="versionName"></el-input>
      </el-form-item>
      <el-form-item label="版本 code">
        <el-input v-model="versionCode"></el-input>
      </el-form-item>

      <el-form-item label="文件大小(kb)">
        <el-input v-model="sizeKb"></el-input>
      </el-form-item>

      <el-form-item label="下载地址">
        <el-input v-model="downloadUrl"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button type="danger" @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleOk">更 新</el-button>
    </template>
  </el-dialog>
</template>

<script>
import * as api from "../../api/api";
import {CODE_OK} from "../../api/request";
import {ref} from "vue";

export default {

  setup() {
    let dialogVisible = ref(false)
    return {
      dialogVisible
    }
  },
  data: function () {
    return {
      id: 0,
      name: "",
      versionName: "",
      versionCode: "",
      sizeKb: 0,
      downloadUrl: ""
    }
  },

  methods: {

    open: function (data) {
      this.dialogVisible = true;
      this.id = data.id;
      this.name = data.name
      this.versionName = data.version_name;
      this.versionCode = data.version_code;
      this.sizeKb = data.size_kb;
      this.downloadUrl = data.download_url;
    },

    clear: function () {
      this.id = "";
      this.name = ""
      this.versionName = "";
      this.versionCode = "";
      this.sizeKb = 0;
      this.downloadUrl = "";
    },

    handleCancel: function () {
      this.dialogVisible = false;
      this.clear()
    },

    handleOk: function () {
      const {id, versionName, versionCode, sizeKb, downloadUrl} = this.$data;
      api.postIssueJoinSdkFile(id, versionName, versionCode, sizeKb, downloadUrl).then(result => {
        if (parseInt(result.code) !== CODE_OK) {
          this.$message.error("提交sdk文件信息失败, Err: " + result.msg)
          return
        }
        this.$message.success("提交sdk文件信息成功")
        this.dialogVisible = false;
        this.clear()
        this.$emit("ok");
      }, err => {
        this.$message.error("提交sdk文件信息失败, Err: " + err)
      })
    }
  }
}
</script>

<style scoped>

</style>