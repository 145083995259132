<template>
    <div class="app-edit">
        <el-card class="card info">
            <template #header>
               <div class="header">
                 <el-page-header title="返回"  @back="handleBack" />
                 <template v-if="status !== 4"  >
                   <el-button-group v-if="status===0">
                     <el-button type="primary" size="mini" icon="el-icon-check" @click="handleChangeStatus(1)">通过</el-button>
                     <el-button type="primary" size="mini" icon="el-icon-close" @click="handleChangeStatus(3)">拒绝</el-button>
                   </el-button-group>

                   <el-radio-group v-else-if="status===5 || status===1" v-model="status" size="mini" @change="handleChangeStatus">
                     <el-radio-button :label="1">运行中</el-radio-button>
                     <el-radio-button :label="5">异常停止</el-radio-button>
                   </el-radio-group>

                   <el-radio-group v-else-if="status===2" v-model="status" size="mini" @change="handleChangeStatus">
                     <el-radio-button :label="2">停止</el-radio-button>
                   </el-radio-group>
                 </template>
               </div>
            </template>
            <template #default>
              <el-descriptions column="2" size="medium" border>
                <el-descriptions-item label="应用名称">{{base.name}}</el-descriptions-item>
                <el-descriptions-item label="应用包名">{{base.packageName}}</el-descriptions-item>
                <el-descriptions-item label="应用下载地址">
                  <span v-if="base.downloadUrl.length < 40">{{base.downloadUrl}}</span>
                  <el-tooltip v-else effect="dark" :content="base.downloadUrl" placement="top-start">
                    <span>{{base.downloadUrl.slice(0, 40) + '...'}}</span>
                  </el-tooltip>
                </el-descriptions-item>
                <el-descriptions-item label="应用SHA1">{{base.sha1}}</el-descriptions-item>
                <el-descriptions-item label="应用屏幕方向">
                  <span v-if="base.orientation === 1">竖屏</span>
                  <span v-else-if="base.orientation === 2">横屏</span>
                  <span v-else>不确定</span>
                </el-descriptions-item>
                <el-descriptions-item label="应用行业">
                  <span v-if="base.industry === 'game'">游戏</span>
                  <span v-else-if="base.industry === 'tool'">工具</span>
                  <span v-else-if="base.industry === 'work'">办公</span>
                  <span v-else-if="base.industry === 'social'">社交</span>
                  <span v-else-if="base.industry === 'entertainment'">娱乐</span>
                  <span v-else-if="base.industry === 'shop'">购物</span>
                  <span v-else-if="base.industry === 'traffic'">出行</span>
                  <span v-else>其他</span>
                </el-descriptions-item>
                <el-descriptions-item label="系统"><el-tag size="small">{{base.system}}</el-tag></el-descriptions-item>
                <el-descriptions-item label="应用ID">{{id}}</el-descriptions-item>
                <el-descriptions-item label="所属用户">{{base.username}}</el-descriptions-item>
                <el-descriptions-item label="用户ID">{{base.userId}}</el-descriptions-item>
              </el-descriptions>
            </template>
        </el-card>
        <el-card class="card network">
            <div class="content">
                <div class="network">
                    <div class="sub-title">广告网络</div>

                    <div class="ope">
                        <div class="create-btn"  @click="handleAddNetwork(1)"><div class="icon gdt"/>优量汇</div>
                        <div class="create-btn" @click="handleAddNetwork(4)"><div class="icon ks" />快手</div>
                        <div class="create-btn" @click="handleAddNetwork(5)"><div class="icon gromore"/>Gromore</div>
                    </div>

                    <el-tabs v-model="networkOrderTabs">
                        <el-tab-pane label="默认排序" name="default">
                            <net-table :data="networks" :priority-data="networkDefaultPriorities" :id="id" v-on:update="update"/>
                        </el-tab-pane>
                        <el-tab-pane label="兜底排序" name="bottom">
                            <net-table :data="bottomNetworks" :priority-data="networkBottomPriorities" :id="id" type="bottom" v-on:update="update"/>
                        </el-tab-pane>
                    </el-tabs>

                </div>
            </div>
        </el-card>

        <el-card class="card placement">
            <div class="content">
                <div class="placement">
                    <div class="sub-title">广告位</div>

                    <el-table :data="placements">
                        <el-table-column label="ID" prop="id" width="80px"/>
                        <el-table-column label="名称" prop="name" align="center"/>
                        <el-table-column label="场景" align="center" width="240" show-overflow-tooltip>
                            <template v-slot:default="scope">
                                <el-tag v-if="scope.row.scene === 1" effect="plain">开屏</el-tag>
                                <el-tag v-if="scope.row.scene === 2" effect="plain">信息流</el-tag>
                                <el-tag v-if="scope.row.scene === 3" effect="plain">激励视频</el-tag>
                                <el-tag v-if="scope.row.scene === 4" effect="plain">横幅</el-tag>
                                <el-tag v-if="scope.row.scene === 5" effect="plain">插屏</el-tag>
                            </template>
                        </el-table-column>

                        <el-table-column label="服务端回调地址" prop="verify_cb_url" width="300px" align="center" show-overflow-tooltip/>

                        <el-table-column label="创建日期"
                                         :formatter="(row) => unixFormatter(row.created_at, 'YYYY-MM-DD')" align="center"/>

                        <el-table-column label="状态" width="200" align="center">
                            <template v-slot:default="scope">
                                <div class="ope">
                                    <indicator v-if="scope.row.status === 1"/>
                                    <indicator v-else color="#909399"/>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </el-card>

    </div>
</template>

<script>
import moment from "moment";
import * as api from "../../api/api";
import {CODE_OK} from "../../api/request";
import {unixFormatter} from "../../util/format";
import {deepCopy} from "../../util/object";
import Indicator from "../../component/Indicator.vue";
import NetTable from "./NetTable.vue";
import Routers from "../../router/"

export default {
    name: "AppEdit",
  computed: {
  },
    components: {NetTable, Indicator},
    data: function () {
        return {
            id:0,
            base: {
                name: "",
                packageName: "",
                system: "",
                downloadUrl: "",
                sha1: "",
                industry: "",
                orientation: 0,
                username: "",
                userId: 0,
            },
            networkOrderTabs: "default",
            networks: [],
            bottomNetworks: [],
            networkDefaultPriorities: [],
            networkBottomPriorities: [],
            placements:[],
            status: -1,
        }
    },

    mounted() {
        this.initView()
    },

    methods: {
        unixFormatter,

        initView: function () {
            const {appId} = this.$route.query
            if (appId === undefined) {
                this.$message.error("appId 不存在")
                Routers.back()
                return;
            }

            this.id = appId;

            this.update();
        },

        update: function () {
            const {id} = this;
            api.getMediaApp(id).then(result => {
                if (parseInt(result.code) !== CODE_OK) {
                    this.$message.error("获取失败， Err: " + result.msg)
                    return
                }
                this.base.name = result.data.base.name;
                this.base.packageName = result.data.base.package_name;
                this.base.system = result.data.base.system;
                this.base.downloadUrl = result.data.base.download_url;
                this.base.sha1 = result.data.base.sha1;
                this.base.industry = result.data.base.industry;
                this.base.orientation = result.data.base.orientation;
                this.base.username = result.data.base.username;
                this.base.userId = result.data.base.user_id;
                this.networks = deepCopy(result.data.networks).sort((a, b) => a.order_i - b.order_i);
                this.bottomNetworks = deepCopy(result.data.networks).sort((a, b) => a.bottom_order_i - b.bottom_order_i);
                this.status = result.data.status;

                const priorities = result.data.network_priorities.map((a) => {
                    a["timeStart"] = moment({hour: a.start_hour, minute: a.start_minute});
                    a["timeEnd"] = moment({hour: a.end_hour, minute: a.end_minute});
                    return a;
                }).sort((a, b) => a.timeStart.unix() - b.timeStart.unix());

                this.networkDefaultPriorities = priorities.filter((a) => a.type === "default");

                this.networkBottomPriorities = priorities.filter((a) => a.type === "bottom");

                this.placements = result.data.placements;
            }, err => {
                this.$message.error("获取失败, Err: " + err)
            })
        },

        handleChangeStatus: function (status) {
            if (status === 3) {
                this.$prompt("请输入拒绝原因", "原因", {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(({value}) => {
                    this.updateStatus(status, value);
                })
            } else {
                this.updateStatus(status, "");
            }
        },

        updateStatus: function(status, opDescribe) {
            api.updateMediaAppStatus(parseInt(this.id), status, opDescribe).then(result => {
                if (parseInt(result.code) !== CODE_OK) {
                    this.$message.error("应用状态更新失败, Err="+ result.msg);
                    this.update();
                    return;
                }
                this.update();
            }, err => {
                this.$message.error("应用状态更新失败, Err="+ err);
                this.update();
            })
        },

        handleBack: function() {
            Routers.back();
        },

        handleAddNetwork: function (channel) {
            Routers.push({
                path: "network/create",
                query: {
                    channel: channel,
                    appId: this.id,
                }
            })
        },
    }
}
</script>

<style scoped>

.app-edit {
    min-height: 100%;
}

.app-edit .card {
    margin-bottom: 16px;
}

.app-edit .content {}

.app-edit .content .network .el-tag {
    width: 70px;
    text-align: center;
}

.app-edit .content .ope {
    margin-bottom: 20px;
}

.app-edit .content .ope {
    margin-bottom: 20px;
}

.app-edit .content .placement .el-tag {
    width: 70px;
    text-align: center;
}

.app-edit .content .sub-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 700;
    color: #303133;
}

.app-edit .content .btn-delete {
    color: #f56c6c;
}

.header {
    display: flex;
    justify-content: space-between;
    align-content: center;
}
.app-edit .card.info .header .item{
  display: flex;
  justify-content: space-between;
}


.app-edit .card.network .ope {
    background: #fafafa;
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap;
    padding: 16px 16px 8px;
}

.app-edit .card.network .ope .create-btn {
    display: flex;
    color: #333;
    font-size: 12px;
    line-height: 20px;
    border-radius: 4px;
    margin-bottom: 8px;
    margin-right: 8px;
    align-items: center;
    background-color: #fff;
    padding: 5px 8px;
    cursor: pointer;
}

.app-edit .card.network .ope .create-btn .icon {
    background-size: cover;
    height: 23px;
    width: 48px;
}

.app-edit .card.network .ope .create-btn .icon.gdt {
    background: url("../../assets/ic_gdt.png") center no-repeat;
    background-size: 23px 23px;
}

.app-edit .card.network .ope .create-btn .icon.pangle {
    background: url("../../assets/ic_pangle.png") center no-repeat;
}

.app-edit .card.network .ope .create-btn .icon.ks {
    background: url("../../assets/ic_ks.png") center no-repeat;
    background-size: 23px 23px;
}

.app-edit .card.network .ope .create-btn .icon.gromore {
    background: url("../../assets/ic_gromore.png") center no-repeat;
    background-size: 20px 20px;
}

.app-edit .card.placement .ope {
    display: flex;
    align-items: center;
    justify-content: center;
}

</style>