<template>
    <el-card class="step1">
        <div class="header">
            <span>
                剩余未核算渠道
                (<span style="color: #f56c6c">&nbsp;{{untreated}}&nbsp;</span>)
            </span>
            &nbsp;&nbsp;
            <el-button type="primary" @click="handleClickComplete" :disabled="untreated !== 0">下一步</el-button>
        </div>

        <div class="table">
            <el-table :data="data">
                <el-table-column prop="date" label="核算时间" align="center">
                    <template v-slot:default="scope">
                        <span>{{unixFormatter(scope.row.date, 'YYYY-MM')}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="渠道" align="center">
                    <template v-slot:default="scope">
                        <tag v-if="scope.row.channel === 1">优量汇</tag>
                        <tag v-else-if="scope.row.channel === 2" >穿山甲</tag>
                        <tag v-else-if="scope.row.channel === 4">快手</tag>
                        <tag v-else-if="scope.row.channel === 5">Gromore</tag>
                        <tag v-else effect="plain">未知</tag>
                    </template>
                </el-table-column>
                <el-table-column prop="est_revenue" label="预估金额" align="center"/>
                <el-table-column label="实际金额" align="center" min-width="225px">
                    <template v-slot:default="scope">
                        <span v-if="scope.row.status === 2">{{scope.row.final_revenue}}</span>
                        <el-input-number v-else v-model="scope.row.final_revenue"
                                         style="width: 200px"
                                         :precision="2" :step="0.01" :min="0"
                                         size="small"></el-input-number>
                    </template>
                </el-table-column>
                <el-table-column label="状态" align="center">
                    <template v-slot:default="scope">
                        <tag v-if="scope.row.status === 1" color="#7e7e7e">未核算</tag>
                        <tag v-else-if="scope.row.status === 2" >已核算</tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template v-slot:default="scope">
                        <el-button type="text" @click="handelClickDetail(scope.row.date, scope.row.channel)">明细</el-button>
                        <template v-if="scope.row.status === 1">
                            <el-button type="text" @click="handleClickCheck(scope.row.id, scope.row.final_revenue)">完成</el-button>
                        </template>
                        <template v-else>
                            <el-button type="text" @click="handleClickRecheck(scope.row.id)">重核</el-button>
                        </template>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="footer">
            <el-pagination
                class="pagination"
                background
                layout="prev, pager, next"
                @current-change="update"
                v-model:current-page="currentPage"
                :page-size="pageSize"
                :total="pageCount">
            </el-pagination>
        </div>
    </el-card>
</template>

<script>
import moment from 'moment'
import * as api from "../../api/api"
import {CODE_OK} from "../../api/request";
import {unixFormatter} from "../../util/format";
import Tag from "../../component/Tag.vue";
import Routers from "../../router/"

export default {
    name: "CenterPart1",

    components: {Tag},

    props: {
        date: {
            required: true,
            type: Number,
        }
    },

    data: function () {
        return {
            untreated: 0,
            data: [],
            pageSize: 8,
            pageCount: 0,
            currentPage: 1,
        };
    },

    mounted() {
        this.initView();
    },

    methods: {
        unixFormatter,

        initView: function () {
            this.update();
        },

        update: function () {
            const {currentPage, pageSize, date} = this;
            const dateStart = moment(date).startOf('month').unix()
            const dateEnd = moment(date).endOf('month').unix()
            api.getSettlementPlatformReport(currentPage, pageSize, dateStart, dateEnd, 0).then(result => {
                if (parseInt(result.code) !== CODE_OK) {
                    this.$message.error("获取上游核算列表失败， Err: " + result.msg)
                    return
                }

                let untreated = 0;
                this.data = result.data.list.map((item)=> {
                    item.est_revenue = (item.est_revenue/100).toFixed(2);
                    item.final_revenue = (item.final_revenue/100).toFixed(2);

                    if (item.status === 1) {
                        untreated ++;
                    }
                    return item;
                })
                this.pageCount = result.data.count;
                this.untreated = untreated;
            }, err => {
                this.$message.error("获取上游核算列表失败, Err: " + err)
            })
        },

        handleClickComplete: function () {
            this.$emit("complete", 1);
        },

        handelClickDetail: function (date, channel) {
            Routers.push({
                path: "/dashboard/financial/platform",
                query: {
                    date_start: moment(date * 1000).clone().startOf("month").unix(),
                    date_end: moment(date * 1000).clone().endOf("month").unix(),
                    channel: channel,
                }
            })
        },

        handleClickCheck: function (id, revenue) {
            api.checkSettlementPlatform(id,Math.floor(revenue * 100)).then(result => {
                if (parseInt(result.code) !== CODE_OK) {
                    this.$message.error("核算失败， Err: " + result.msg)
                    return
                }
                this.update();
            }, err => {
                this.$message.error("核算失败, Err: " + err)
            })
        },

        handleClickRecheck: function (id) {
            api.recheckSettlementPlatform(id).then(result => {
                if (parseInt(result.code) !== CODE_OK) {
                    this.$message.error("重核失败， Err: " + result.msg)
                    return
                }
                this.update();
            }, err => {
                this.$message.error("重核失败, Err: " + err)
            })
        },
    }
}
</script>

<style scoped>

.step1 .header {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-end;
}

.step1 .table {
    margin-top: 20px;
}

.step1 .footer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

</style>